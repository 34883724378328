import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { IssuesDatatable, VehicleDatatable, WorkOrderDatatable } from "../../Data/DummyData"
import Datatable, { computeColumns, renderFilter } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import AssetLocationMapNew from "../Maps/AssetLocationMapNew"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import styles from "./DataTable.module.scss"
import { DashboardStatistics } from "../../Data/DummyData"
import { useInfoCtx } from "../../providers/InfoCardProvider"
import Modal from "../../components/Modal"
import { useModalCtx } from "../../providers/ModalProvider"
import { EndTripValidation, VehicleFormValidations } from "../../validations"
import { useFormik } from "formik"
import { FormHelperText, Grid, MenuItem, TextField } from "@mui/material"
import { addData, deleteData, editData } from "../../redux/actions"
import queryString from "query-string"
import {
    addPage,
    fetchData,
    setFilteredData,
    setModule,
    setModuleData,
    updateVehiclePosition,
} from "../../redux/templateData/actions"
import {
    selectData,
    selectDatatable,
    selectFilteredData,
    selectModuleSpecificData,
    selectModuleSpecificRawData,
} from "../../redux/templateData/selectors"
import { initialState } from "../../redux/templateData/init"
import { unlockDevice } from "../../redux/iot/actions"
import JMap from "../../components/Map/JMap"
import MapViewIcons from "../../assets/images/images"
import { selectMapViewStatus, selectWebsiteData } from "../../redux/general/selectors"
import { setMapFilters } from "../../redux/filters/reducers"
import MapWithMultipleRoute from "../Maps/MapWithMultipleRoute"
import moment from "moment"

let APIInterval

export const formatAmount = (amountToFillLiters) => {
    if (typeof amountToFillLiters === "number") {
        return amountToFillLiters.toLocaleString("en-US")
    } else {
        return 0
    }
}

function WavSmartRouting() {
    const mapReference = useRef()
    const datatableRef = useRef()
    const datatable = useSelector(selectDatatable)
    const [informationToDisplay, setInformationToView] = useState({})
    const [coordinatesOriginDestination, setCoordinatesOriginDestination] = useState([])
    const [rangeSliderValue, setValue] = useState([0, 100])
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const filteredData = useSelector(selectFilteredData)
    const dispatch = useDispatch()
    const module = "vehicles"
    const stations = useSelector(selectModuleSpecificData("stations"))
    const [stationGroupBySustance, setStationGroupBySustance] = useState([])
    const toggleMapTable = useSelector(selectMapViewStatus)
    const [historyModalStatus, setHistoryModalStatus] = useState(false)
    const [containerGroupBySustance, setContainerGroupBySustance] = useState([])
    //we are setting the filterdData like
    const [showSlider, setShowSlider] = useState(false)
    const [filteredResult, setFilteredResult] = useState([])
    const vehicleHisotory = useSelector(selectModuleSpecificData("containers_history")) || []
    const app = sessionStorage.getItem("app")
    const app_key = sessionStorage.getItem("app_key")

    const [containersData, setContainersData] = useState([])

    const data = useMemo(() => datatable.data, [datatable.data])
    const display = useSelector((state) => state.filterBar.display)

    const datatableId = "routing_datatable"

    const [lockDialogConfirmationStatus, setLockDialogConfirmationStatus] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState({})
    const [isEditMode, setEditMode] = useState(false)
    const modalContext = useModalCtx()
    const modalContext2 = useModalCtx()
    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext
    const deviceData = useSelector(selectModuleSpecificData("iot"))
    const [dialogConfirmationStatus, setDialogConfirmationStatus] = useState(false)

    useEffect(() => {
        const fitered_result_id = filteredData.map((item) => item.id)
        const result = datatable.data.map((item) => {
            return {
                ...item,
                is_ghost: !fitered_result_id.includes(item.id),
            }
        })

        setContainersData([...result])
    }, [filteredData, datatable.data])

    useEffect(() => {
        if (!initialState.hasOwnProperty("iot")) {
            dispatch(
                addPage({
                    key: "iot",
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(fetchData({ module: "iot" }))
    }, [])

    const containerHistoryRawData =
        useSelector(selectModuleSpecificRawData("containers_history")) || {}

    const [filters, setFilters] = useState({})

    const { values, errors, touched, resetForm, handleBlur, handleChange, setValues, submitForm } =
        useFormik({
            initialValues: {
                vehicle_id: "",
                battery_id: "",
                number_of_rides: "",
                performance: "",
                current_location: "",
                current_status: "",
                distance_travelled: "",
                vehicle_active_time: "",
                vehicle_down_time: "",
                number_of_different_riders: "",
                distance_since_last_inspection: "",
                controller_firmware_version: "",
                controller_id: null,
                imei: null,
            },
            onSubmit: () => {
                const obj = { ...values }
                Object.keys(obj).forEach((key) => {
                    if (obj[key] === null || obj[key] === "") {
                        obj[key] = null
                    }
                })

                if (isEditMode) {
                    dispatch(editData(obj))
                } else {
                    dispatch(addData(obj))
                }
            },
            validationSchema: VehicleFormValidations,
        })
    const columns2 = useMemo(
        () =>
            computeColumns({
                data: vehicleHisotory,
                meta: containerHistoryRawData?.datatable?.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [vehicleHisotory, containerHistoryRawData],
    )

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    useEffect(() => {
        setStationGroupBySustance({})
        setContainerGroupBySustance({})
        // setStationGroupBySustance(
        //     _.groupBy(stations, ({ storage_type }) => storage_type.toLowerCase()),
        // )
        // setContainerGroupBySustance(
        //     _.groupBy(filteredData, ({ substance }) => (substance ? substance.toLowerCase() : "")),
        // )
    }, [filteredData])

    useEffect(() => {
        const timeout = setTimeout(() => {
            const formatFilters = Object.entries(filters).map((item) => ({
                id: item[0],
                value: item[1],
            }))

            datatableRef.current.updateFilter(formatFilters)
            dispatch(fetchData({ module }))
        }, 500)

        return () => {
            clearTimeout(timeout)
        }
    }, [filters])

    const updateFilters = () => {
        const storedFilters =
            JSON.parse(
                localStorage.getItem(`${window.location.pathname + app + datatableId}_filter`),
            ) || []

        const updateFilter = storedFilters.reduce((acc, item) => {
            acc[item.id] = item.value
            return acc
        }, {})

        if (!updateFilter.hasOwnProperty("percent_full")) {
            setValue([0, 100])
        }

        setFilters({ ...updateFilter })
        dispatch(setMapFilters(filters))
    }

    const memonizedFilters = useMemo(() => {
        return Object.entries(filters).map((data) => ({ id: data[0], value: data[1] }))
    }, [filters])

    useEffect(() => {
        updateFilters()
    }, [toggleMapTable])

    const columns = useMemo(
        () =>
            computeColumns({
                data: datatable.data,
                meta: datatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [datatable],
    )

    const filterRoutingData = () => {
        setFilteredData(filteredData)
        setFilteredResult(filteredData)
    }

    const estimatedRunoutDays = _.sumBy(filteredData, (object) => object.estimated_runout_days)

    const amountToFillLiters = _.sumBy(filteredData, (object) => object.amount_to_fill_litres)

    const remainingFuelLitres = _.sumBy(filteredData, (object) => object.remaining_fuel_litres)

    const drawPaths = () => {
        const routes = []
        let nearestStations = null
        let nearestContainerToStation = null
        Object.keys(containerGroupBySustance).map((substance) => {
            const waypoints = []

            if (Object.keys(stationGroupBySustance).includes(substance)) {
                containerGroupBySustance[substance].map((container, index) => {
                    nearestStations = stationGroupBySustance[container.substance.toLowerCase()][0] //selecting first station as we don't have any other stations or nearest stasion

                    if (index == 0) {
                        nearestContainerToStation = container // default considering fisrt station should be closer by default
                    } else {
                        if (container.latitude && container.longitude)
                            waypoints.push({
                                latitude: container.latitude,
                                longitude: container.longitude,
                            })
                    }
                    //here first need to find nearest stations to container and associate each container with station
                    //group the container by stations
                    //find nearest container need to cover first and other would be in waypoints

                    // console.log('nearestStations:', nearestStations);

                    // console.log('nearestContainerToStation:', nearestContainerToStation);
                })

                if (nearestStations)
                    routes.push({
                        station: nearestStations,
                        waypoints,
                        origin: [nearestStations.latitude, nearestStations.longitude],
                        destination: [
                            nearestContainerToStation.latitude,
                            nearestContainerToStation.longitude,
                        ],
                    })
            }
        })

        mapReference.current.coordinates = routes

        console.log("routes: ", coordinatesOriginDestination)
        setCoordinatesOriginDestination(routes)
    }

    const changeFilterState = (event, column) => {
        filters[column.id] = event.target.value
        setFilters({ ...filters })
        dispatch(setMapFilters(filters))
    }

    const fetchFilterValue = useCallback(
        (column) => {
            return filters[column.id]
        },
        [filters],
    )

    const renderFilterMemonized = useCallback(renderFilter, [])

    return (
        <div style={{ backgroundColor: "white", height: "100%" }}>
            {/* {console.log("These are the map filers", MapFilters)} */}
            {console.log("this is an array", filters)}
            <div className={`map-view ${toggleMapTable == "map_view" ? "show" : "hide"}`}>
                <div
                    className="map-filters"
                    style={{
                        display: `${display}`,
                        height: `${window.innerHeight - 64}px`,
                    }}
                >
                    {datatableRef.current &&
                        datatableRef.current.headerGroups.map((headerGroup, index) => (
                            <div key={index}>
                                {headerGroup.headers.map((column, index2) => {
                                    return (
                                        <div
                                            style={{ minWidth: "120px", paddingBottom: "10px" }}
                                            key={index2}
                                            {...(column.id === "percent_full" && {
                                                onMouseEnter: () => setShowSlider(true),
                                                onMouseLeave: () => setShowSlider(false),
                                            })}
                                        >
                                            {/* {console.log("dsds", column)} */}
                                            <div
                                                style={{
                                                    textAlign: "start",
                                                    padding: "5px 0px",
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {_.capitalize(column.render("Header"))}
                                            </div>
                                            {column.canFilter ? (
                                                <div style={{ marginTop: "0px" }}>
                                                    {renderFilterMemonized({
                                                        showSlider,
                                                        setShowSlider,
                                                        filterType: column.filters.filterType,
                                                        data: column,
                                                        onChange: (e) =>
                                                            changeFilterState(e, column),
                                                        value: filters[column.id],
                                                        handleRangeChange: (e, newValue) => {
                                                            filters[column.id] = newValue
                                                            setFilters({ ...filters })
                                                            dispatch(setMapFilters(filters))
                                                            // column.setFilter(newValue)
                                                            // setValue(newValue)
                                                        },
                                                        handleRangeModal: () => {},
                                                        rangefilterValue: filters[column.id]
                                                            ? filters[column.id]
                                                            : rangeSliderValue,
                                                        // rangefilterValue: column.filterValue
                                                        //     ? column.filterValue
                                                        //     : rangeSliderValue,
                                                    })}
                                                </div>
                                            ) : null}
                                        </div>
                                    )
                                })}
                            </div>
                        ))}
                </div>

                <Button
                    onClick={() => {
                        drawPaths()
                        mapReference.current.createRoute()
                    }}
                    className="clear-map-filters"
                    variant="secondary"
                >
                    Routings
                </Button>
                {Object.keys(filters).length > 0 && (
                    <Button
                        style={{ marginRight: "95px" }}
                        onClick={() => {
                            datatableRef.current.clearFilter()

                            updateFilters()
                            dispatch(setMapFilters([]))
                        }}
                        className="clear-map-filters"
                        variant="secondary"
                    >
                        Clear Filter
                    </Button>
                )}

                <div id="map">
                    {stations && (
                        <MapWithMultipleRoute
                            id="filter-map1"
                            ref={mapReference}
                            coordinates={coordinatesOriginDestination}
                            data={[...containersData, ...stations]}
                            stations={[]}
                            styles={display == "block" ? { left: "15%", width: "85%" } : {}}
                        />
                    )}
                </div>
            </div>

            <div className={`${toggleMapTable == "map_view" ? "hide" : "show"}`}>
                <Datatable
                    appliedFilters={memonizedFilters}
                    id="routing_datatable"
                    ref={datatableRef}
                    // buttons={[
                    //     {
                    //         index: 1,
                    //         text: "Map View",
                    //         icon: <AddIcon />,
                    //         action: () => {
                    //             setToggleMapTable(!toggleMapTable)
                    //         },
                    //     },
                    // ]}
                    filteredResult={filteredData}
                    addActionName="Add Vehicle"
                    showextraActionbtn={coordinatesOriginDestination.length > 0}
                    extraActionbtnName="Save"
                    extraBtnAction={() => {
                        dispatch(
                            saveRoute({
                                entity_ids: filteredData.map((item) => item.device_id),
                                total_containers: filteredData.length,
                                total_estimated_runout_days: estimatedRunoutDays,
                                total_amount_to_fill_litres: amountToFillLiters,
                                total_remaining_fuel_litres: remainingFuelLitres,
                                total_distance: _.sumBy(
                                    mapReference.coordinates,
                                    (object) => object.distance,
                                ),
                                routes: mapReference.coordinates,
                                substance: [
                                    ...new Set(filteredData.map((item) => item.substance)),
                                ].join(","), // This key (substance) was added for sending comma separated string values (unique), for example "LPG,Kerosene,RedDiesel"
                            }),
                        )
                    }}
                    addAction={() => {
                        drawPaths()
                        handleFormModalStatus(true)
                        mapReference.current.createRoute()
                    }}
                    refreshAction={() => {
                        setCoordinatesOriginDestination([])
                        mapReference.current.routesReset()
                        dispatch(fetchData({ module }))
                    }}
                    datatableTitle="Routing"
                    sortable_key="vehicle_id"
                    modalStatus={status}
                    modalContent={ModalContentMemonized}
                    handleModalStatus={setModalStatus}
                    fetchFilterValue={fetchFilterValue}
                    changeFilterState={changeFilterState}
                    columns={columns}
                    data={data}
                    actions={[
                        {
                            name: "Send",
                            type: "lock",
                            buttonType: "icon",
                        },
                        // { name: "Delete", type: "end_ride", buttonType: "icon" },
                        { name: "history", type: "history", buttonType: "icon" },
                        { name: "Edit", type: "edit", buttonType: "icon" },
                        { name: "Delete", type: "delete", buttonType: "icon" },
                        { name: "location", type: "location", buttonType: "icon" },
                    ]}
                    onActionClick={(event, { type, data, trip_id }) => {
                        setInformationToView(data)
                        setSelectedDevice(data)
                        console.log("here is the data", data)
                        setValues(data.original)
                        console.log(data)
                        if (type == "edit") {
                            setSelectedDevice(data)
                            setEditMode(true)
                            handleFormModalStatus(true)
                        } else if (type == "location") {
                            mapReference.current.routesReset()
                            mapReference.current.setCenter({
                                lat: data.values.latitude,
                                lng: data.values.longitude,
                            })
                            mapReference.current.setZoom(15)
                            dispatch(selectMapViewStatus("map_view"))
                            document.getElementById("map").scrollIntoView()
                        } else if (type == "history") {
                            dispatch(
                                addPage({
                                    key: "vehicle_history",
                                    data: initialState.vehicles,
                                }),
                            )
                            dispatch(
                                fetchData({
                                    module: "vehicle_history",
                                    vehicle_id: data.values.vehicle_id,
                                }),
                            )
                            setHistoryModalStatus(true)
                        } else if (type == "lock") {
                            setLockDialogConfirmationStatus(true)
                        } else if (type == "delete") {
                            setDialogConfirmationStatus(true)
                        }
                    }}
                />
            </div>
            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={dialogConfirmationStatus}
                onSave={() => {
                    dispatch(deleteData(values))
                    setDialogConfirmationStatus(false)
                }}
            ></Modal>
            {selectedDevice && (
                <Modal
                    modalHeading={isEditMode ? "Edit" : "Add"}
                    fullWidth
                    maxWidth="md"
                    status={formModalStatus}
                    onClose={() => {
                        resetForm()
                        handleFormModalStatus(false)
                    }}
                    onSave={submitForm}
                >
                    <br />
                    {isEditMode ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Vehicle ID*"
                                    name="vehicle_id"
                                    disabled={!!isEditMode}
                                    error={!!(errors.vehicle_id && touched.vehicle_id)}
                                    helperText={
                                        errors.vehicle_id && touched.vehicle_id
                                            ? errors.vehicle_id
                                            : false
                                    }
                                    value={values.vehicle_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle ID"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Device Id*"
                                    select={true}
                                    // disabled={isEditMode ? true : false}
                                    name="iot_id"
                                    value={values.iot_id}
                                    error={!!(errors.iot_id && touched.iot_id)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Device Id"
                                    fullWidth
                                >
                                    <MenuItem selected disabled value="select">
                                        Select Device Id
                                    </MenuItem>
                                    {deviceData?.map((device, index) => (
                                        <MenuItem key={index} value={device.id}>
                                            {`${device.name} (${device.imei})`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {/* <Grid xs={6} item>
              <TextField
                label="Battery*"
                placeholder="Select battery"
                select={true}
                name="battery_id"
                value={values.battery_id}
                error={errors.battery_id && touched.battery_id ? true : false}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              >
                <MenuItem selected disabled value="select">
                  Select battery
                </MenuItem>
                {batteryData?.map((battery, index) => (
                  <MenuItem key={index} value={battery.battery_id}>
                    {battery.battery_id}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText
                error={errors.battery_id && touched.battery_id ? true : false}
              >
                {errors.battery_id && touched.battery_id
                  ? errors.battery_id
                  : ''}
              </FormHelperText>
            </Grid> 
            <Grid xs={6} item>
              <TextField
                label="IMEI"
                name="imei"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                error={errors.imei && touched.imei ? true : false}
                helperText={errors.imei && touched.imei ? errors.imei : false}
                value={values.imei}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="IMEI Number"
              />
            </Grid>
            {/* <Grid xs={6} item>
              <TextField
                label="Controller ID"
                type="number"
                name="controller_id"
                InputProps={{ inputProps: { min: 0 } }}
                error={
                  errors.controller_id && touched.controller_id ? true : false
                }
                helperText={
                  errors.controller_id && touched.controller_id
                    ? errors.controller_id
                    : false
                }
                value={values.controller_id}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Controller ID"
              />
            </Grid> */}
                            {/* <Grid xs={6} item>
              <TextField
                label="Controller Firmware Version"
                name="controller_firmware_version"
                error={
                  errors.controller_firmware_version &&
                  touched.controller_firmware_version
                    ? true
                    : false
                }
                helperText={
                  errors.controller_firmware_version &&
                  touched.controller_firmware_version
                    ? errors.controller_firmware_version
                    : false
                }
                value={values.controller_firmware_version}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Controller Firmware Version"
              />
            </Grid> */}
                            <Grid xs={6} item>
                                <TextField
                                    label="Number of Rides"
                                    type="number"
                                    name="number_of_rides"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    error={!!(errors.number_of_rides && touched.number_of_rides)}
                                    helperText={
                                        errors.number_of_rides && touched.number_of_rides
                                            ? errors.number_of_rides
                                            : false
                                    }
                                    value={values.number_of_rides}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Number of Rides"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    label="Performance"
                                    name="performance"
                                    type="string"
                                    error={!!(errors.performance && touched.performance)}
                                    helperText={
                                        errors.performance && touched.performance
                                            ? errors.performance
                                            : false
                                    }
                                    value={values.performance}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Performance"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    label="Current Location"
                                    name="current_location"
                                    error={!!(errors.current_location && touched.current_location)}
                                    helperText={
                                        errors.current_location && touched.current_location
                                            ? errors.current_location
                                            : false
                                    }
                                    value={values.current_location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Current location"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    label="Current Status"
                                    name="current_status"
                                    error={!!(errors.current_status && touched.current_status)}
                                    helperText={
                                        errors.current_status && touched.current_status
                                            ? errors.current_status
                                            : false
                                    }
                                    value={values.current_status}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Current status"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    label="Distance travelled"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    name="distance_travelled"
                                    error={
                                        !!(errors.distance_travelled && touched.distance_travelled)
                                    }
                                    helperText={
                                        errors.distance_travelled && touched.distance_travelled
                                            ? errors.distance_travelled
                                            : false
                                    }
                                    value={values.distance_travelled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Distance travelled"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Distance Since Last Inspection"
                                    name="distance_since_last_inspection"
                                    error={
                                        !!(
                                            errors.distance_since_last_inspection &&
                                            touched.distance_since_last_inspection
                                        )
                                    }
                                    helperText={
                                        errors.distance_since_last_inspection &&
                                        touched.distance_since_last_inspection
                                            ? errors.distance_since_last_inspection
                                            : false
                                    }
                                    value={values.distance_since_last_inspection}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="distance since travelled"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Vehicle active time"
                                    name="vehicle_active_time"
                                    error={
                                        !!(
                                            errors.vehicle_active_time &&
                                            touched.vehicle_active_time
                                        )
                                    }
                                    helperText={
                                        errors.vehicle_active_time && touched.vehicle_active_time
                                            ? errors.vehicle_active_time
                                            : false
                                    }
                                    value={values.vehicle_active_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle active time"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Vehicle down time"
                                    name="vehicle_down_time"
                                    error={
                                        !!(errors.vehicle_down_time && touched.vehicle_down_time)
                                    }
                                    helperText={
                                        errors.vehicle_down_time && touched.vehicle_down_time
                                            ? errors.vehicle_down_time
                                            : false
                                    }
                                    value={values.vehicle_down_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle down time"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Number of different rides"
                                    name="number_of_different_riders"
                                    error={
                                        !!(
                                            errors.number_of_different_riders &&
                                            touched.number_of_different_riders
                                        )
                                    }
                                    helperText={
                                        errors.number_of_different_riders &&
                                        touched.number_of_different_riders
                                            ? errors.number_of_different_riders
                                            : false
                                    }
                                    value={values.number_of_different_riders}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Number of rides"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Vehicle Type"
                                    name="vehicle_type"
                                    select={true}
                                    error={!!(errors.vehicle_type && touched.vehicle_type)}
                                    helperText={
                                        errors.vehicle_type && touched.vehicle_type
                                            ? errors.vehicle_type
                                            : false
                                    }
                                    value={values.vehicle_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle Type"
                                >
                                    <MenuItem value={"Bike"}>Bike</MenuItem>
                                    <MenuItem value={"Scooter"}>Scooter</MenuItem>
                                    <MenuItem value={"Delivery - KG3"}>Delivery - KG3</MenuItem>
                                    <MenuItem value={"Delivery - KG4"}>Delivery - KG4</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Vehicle ID*"
                                    name="vehicle_id"
                                    disabled={!!isEditMode}
                                    error={!!(errors.vehicle_id && touched.vehicle_id)}
                                    helperText={
                                        errors.vehicle_id && touched.vehicle_id
                                            ? errors.vehicle_id
                                            : false
                                    }
                                    value={values.vehicle_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle ID"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Device Id"
                                    select={true}
                                    // disabled={isEditMode ? true : false}
                                    name="iot_id"
                                    value={values.iot_id}
                                    error={!!(errors.iot_id && touched.iot_id)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Device Id"
                                    fullWidth
                                >
                                    <MenuItem selected disabled value="select">
                                        Select Device Id
                                    </MenuItem>
                                    {deviceData?.map((device, index) => (
                                        <MenuItem key={index} value={device.id}>
                                            {`${device.name} (${device.imei})`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Vehicle Type"
                                    name="vehicle_type"
                                    select={true}
                                    error={!!(errors.vehicle_type && touched.vehicle_type)}
                                    helperText={
                                        errors.vehicle_type && touched.vehicle_type
                                            ? errors.vehicle_type
                                            : false
                                    }
                                    value={values.vehicle_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle Type"
                                >
                                    <MenuItem value={"Bike"}>Bike</MenuItem>
                                    <MenuItem value={"Scooter"}>Scooter</MenuItem>
                                    <MenuItem value={"Delivery - KG3"}>Delivery - KG3</MenuItem>
                                    <MenuItem value={"Delivery - KG4"}>Delivery - KG4</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    )}
                </Modal>
            )}
            {selectedDevice && (
                <Modal
                    fullWidth
                    maxWidth="sm"
                    onClose={() => setLockDialogConfirmationStatus(false)}
                    modalHeading="Confirm"
                    status={lockDialogConfirmationStatus}
                    onSave={() => {
                        dispatch(
                            unlockDevice({
                                imei: selectedDevice.values.imei,
                                // module:""
                            }),
                        )
                        console.log("lala", selectedDevice)
                        dispatch(fetchData({ module: "iot" }))
                        setLockDialogConfirmationStatus(false)
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            Are you sure want to unlock this IOT?
                        </Grid>
                    </Grid>
                </Modal>
            )}

            <Modal
                disableSave={true}
                fullWidth
                maxWidth="md"
                onClose={() => {
                    const datatableId = "container_history"
                    const app = sessionStorage.getItem("app")

                    localStorage.setItem(
                        `${window.location.pathname + app + datatableId}_filter`,
                        JSON.stringify([]),
                    )
                    localStorage.setItem(
                        `${window.location.pathname + app + datatableId}_sort`,
                        JSON.stringify([]),
                    )
                    localStorage.setItem(
                        `${window.location.pathname + app + datatableId}_pageIndex`,
                        0,
                    )
                    setHistoryModalStatus(false)
                }}
                modalHeading="Container History"
                status={historyModalStatus}
                onSave={() => {
                    setHistoryModalStatus(false)
                }}
            >
                <div style={{ padding: "0px 40px" }}>
                    <Grid container spacing={2} xs={12}>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">ID</div>
                                <div>{vehicleHisotory[0]?.id}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Device ID</div>
                                <div>{vehicleHisotory[0]?.device_id}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">
                                    API Trigger Level Percent
                                </div>
                                <div>{vehicleHisotory[0]?.api_trigger_level_percent}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Substance</div>
                                <div>{vehicleHisotory[0]?.substance}</div>
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">
                                    Amount To Fill Litres
                                </div>
                                <div>{vehicleHisotory[0]?.amount_to_fill_litres}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">
                                    Remaining Fuel Litres
                                </div>
                                <div>{vehicleHisotory[0]?.remaining_fuel_litress || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Ullage</div>
                                <div>{vehicleHisotory[0]?.ullage || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Raw Distance</div>
                                <div>{vehicleHisotory[0]?.raw_distance || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Temprature</div>
                                <div>{vehicleHisotory[0]?.temprature || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Pick Up Event</div>
                                <div>{vehicleHisotory[0]?.pick_up_event || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Fill Level</div>
                                <div>{vehicleHisotory[0]?.fill_level || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Fill Error</div>
                                <div>{vehicleHisotory[0]?.fill_error || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">SNR</div>
                                <div>{vehicleHisotory[0]?.snr || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">RSSI</div>
                                <div>{vehicleHisotory[0]?.rssi || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Is Extended Uplink</div>
                                <div>{vehicleHisotory[0]?.is_extended_uplink || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Type</div>
                                <div>{vehicleHisotory[0]?.type || "-"}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Created At</div>
                                <div>
                                    {moment(vehicleHisotory[0]?.created_at).format(
                                        "DD-MMM-YYYY HH:mm:ss",
                                    ) || "-"}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Average Usage</div>
                                <div>{formatAmount(vehicleHisotory[0]?.average_usage || "-")}</div>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <div className="container-history-heading">Standard Deviation</div>
                                <div>{formatAmount(vehicleHisotory[0]?.std_deviation || "-")}</div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Grid style={{ marginTop: "20px" }} container>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                        <Datatable
                            filteredResult={filteredResult}
                            hasFilterAffectsMap={false}
                            datatableTitle="History"
                            id="container_history"
                            modalSize="xl"
                            refreshAction={() => {
                                const { values } = informationToDisplay
                                dispatch(
                                    addPage({
                                        key: "containers_history",
                                        data: initialState.vehicles,
                                    }),
                                )
                                dispatch(
                                    fetchData(
                                        {
                                            module: "containers_history",
                                            device_id: values.device_id,
                                        },
                                        {
                                            reUpdateSourceData: false,
                                        },
                                    ),
                                )
                            }}
                            isMultiSelect={false}
                            columns={columns2}
                            data={vehicleHisotory}
                        />
                    </Grid>
                </Grid>
            </Modal>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default WavSmartRouting
