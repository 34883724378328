import React, { useEffect, useMemo } from "react"
import { Button, Card, Col, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { useDispatch, useSelector } from "react-redux"
import { selectDatatable, selectFilteredData } from "../../redux/templateData/selectors"
import { initialState } from "../../redux/templateData/init"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"

function Rules() {
    const history = useNavigate()
    const dispatch = useDispatch()
    const datatable = useSelector(selectDatatable)
    const filteredResult = useSelector(selectFilteredData)
    const module = "rules"

    const { setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: datatable.data,
            meta: datatable.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    const data = useMemo(() => datatable.data, [datatable.data])

    return (
        <div style={{ backgroundColor: "white", height: "100%" }}>
            <Datatable
                filteredResult={filteredResult}
                refreshAction={() => {
                    dispatch(fetchData({ module }))
                }}
                datatableTitle="Rules"
                sortable_key="id"
                columns={columns}
                data={data}
            />
        </div>
    )
}

export default Rules
