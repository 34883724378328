import React, { useEffect } from "react"
import { app_key_arr } from "../../redux/globalConstants/globalConstants"

const StationSummaryPopUp = ({ data }) => {
    const app_key = sessionStorage.getItem("app_key")
    useEffect(() => {
        console.log("SUMMRY DATA:", data)
    }, [data])
    function getValueByKey(key) {
        for (let i = 0; i < data.length; i++) {
            if (data[i][0] === key) {
                return data[i][1]
            }
        }
        return null
    }

    function convertToFormattedName(inputString) {
        if (inputString.includes("_")) {
            let parts = inputString.split("_")
            let capitalizedParts = []

            for (let part of parts) {
                let capitalizedPart = part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
                capitalizedParts.push(capitalizedPart)
            }

            let result = capitalizedParts.join(" ")
            console.log("vsg", result)
            return result
        } else {
            console.log("vsg", inputString)
            return inputString
        }
    }

    return (
        <div className="summaryPopUp" style={{ width: "15rem", minHeight: "10rem" }}>
            <div className="header bin__summary-header">Station Information</div>
            <div className="d-flex mt-4 align-items-start">
                <table className="table table-striped p-2">
                    <tbody>
                        <tr>
                            <td className="fw-normal text-capitalize">Location</td>
                            <td className="text-end">{getValueByKey("location")}</td>
                        </tr>
                        {getValueByKey("storage_type") != null && (
                            <tr>
                                {/* {console.log("distance---->", getValueByKey("raw_distance"))} */}

                                <td className="fw-normal text-capitalize">
                                    {app_key_arr.includes(app_key)
                                        ? "Facility Type"
                                        : "Storage Type"}
                                </td>
                                <td className="text-end">
                                    {convertToFormattedName(getValueByKey("storage_type"))}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className="fw-normal text-capitalize">Type</td>
                            <td className="text-end">{getValueByKey("type")}</td>
                        </tr>
                        {app_key_arr.includes(app_key) && (
                            <>
                                <tr>
                                    <td className="fw-normal text-capitalize">Opening Hours</td>
                                    <td className="text-end">{getValueByKey("opening_hour")}</td>
                                </tr>
                                <tr>
                                    <td className="fw-normal text-capitalize">Contact Number</td>
                                    <td className="text-end">{getValueByKey("contact_number")}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default StationSummaryPopUp
