import React, { useEffect, useState } from "react"
import { Card, Col, Offcanvas } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
// import { IOT_INSTRUCTIONS, VehicleIOTDatatable } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { selectIOTAssetData } from "../../redux/iot/selector"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import { initialState } from "../../redux/templateData/init"
import { addData, deleteData, editData } from "../../redux/actions"
import {
    selectDatatable,
    selectFilteredData,
    selectModuleSpecificData,
} from "../../redux/templateData/selectors"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import styles from "./DataTable.module.css"
import { useFormik } from "formik"
import { IOTValidation } from "../../validations"
import Modal from "../../components/Modal"
import { unlockDevice } from "../../redux/iot/actions"
import { FormHelperText, Grid, MenuItem, TextField } from "@mui/material"
import { useModalCtx } from "../../providers/ModalProvider"
import { Data } from "@react-google-maps/api"

let APIInterval

function WavSmartIOT() {
    const [selectedDevice, setSelectedDevice] = useState({})
    const iotTypes = useSelector(selectModuleSpecificData("iot_types"))
    const [IOT_INSTRUCTIONS, setIOTInstructions] = useState([])
    const module = "iot"
    const assetData = useSelector(selectIOTAssetData)
    const dispatch = useDispatch()
    const datatable = useSelector(selectDatatable)
    const filteredData = useSelector(selectFilteredData)
    const [openInstructionPanelStatus, setInstructionPanelStatus] = useState(false)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const [dialogConfirmationStatus, setDialogConfirmationStatus] = useState(false)
    const [lockDialogConfirmationStatus, setLockDialogConfirmationStatus] = useState(false)
    const [isEditMode, setEditMode] = useState(false)
    const modalContext = useModalCtx()
    let { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext

    const columns = React.useMemo(
        () =>
            computeColumns({
                data: datatable.data,
                meta: datatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [datatable.data],
    )

    const { values, errors, touched, resetForm, handleBlur, handleChange, setValues, submitForm } =
        useFormik({
            initialValues: {
                name: "",
                device_id: "",
                mac_address: null,
                imei: null,
            },
            onSubmit: () => {
                let obj = { ...values }
                Object.keys(obj).forEach((key) => {
                    if (obj[key] === null || obj[key] === "") {
                        obj[key] = null
                    }
                })

                if (isEditMode) {
                    dispatch(editData(obj))
                } else {
                    dispatch(addData(obj))
                }
            },
            validationSchema: IOTValidation,
        })

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    useEffect(() => {
        APIInterval = setInterval(() => {
            dispatch(fetchData({ module }, { reUpdateSourceData: false }))
        }, 5000)

        return () => {
            clearInterval(APIInterval)
        }
    }, [])

    const data = React.useMemo(() => datatable.data, [datatable.data])

    return (
        <div style={{ backgroundColor: "white", height: "100%" }}>
            {data?.length > 0 ? (
                <Datatable
                    id="wavsmart_iot_datatable"
                    addActionName="Add Device"
                    addAction={() => {
                        setEditMode(false)
                        handleFormModalStatus(true)
                        resetForm()
                    }}
                    filteredResult={filteredData}
                    datatableTitle="IOT"
                    isMultiSelect={false}
                    refreshAction={() => {
                        dispatch(setModule(module))
                        dispatch(fetchData({ module }))
                    }}
                    rowSelected={(selection) => {
                        // console.log(selection);
                    }}
                    actions={[
                        {
                            name: "Send",
                            type: "lock",
                            buttonType: "icon",
                        },
                        {
                            name: "Send",
                            type: "send_instruction",
                            buttonType: "icon",
                        },
                        {
                            name: "Edit",
                            type: "edit",
                            buttonType: "icon",
                        },
                        { name: "Delete", type: "delete", buttonType: "icon" },
                    ]}
                    // modalHeading="Device History"
                    modalStatus={status}
                    modalContent={ModalContentMemonized}
                    handleModalStatus={setModalStatus}
                    columns={columns}
                    data={data}
                    onActionClick={(event, { type, data }) => {
                        setValues(data.original)
                        setSelectedDevice(data)
                        if (type == "send_instruction") {
                            console.log("data send Instructions: ", data.values.model_type)
                            let IOTDeviceInformation = iotTypes.find(
                                (deviceModal) => deviceModal.name == data.values.model_type,
                            )

                            setIOTInstructions(IOTDeviceInformation.allowed_features)
                            setInstructionPanelStatus(true)
                        } else if (type == "edit") {
                            setEditMode(true)
                            handleFormModalStatus(true)
                        } else if (type == "delete") {
                            setDialogConfirmationStatus(true)
                        } else if (type == "lock") {
                            setLockDialogConfirmationStatus(true)
                            // setTimeout(() => {
                            //   dispatch(fetchData({ module: 'iot' }));
                            // }, 3000);
                        }
                    }}
                />
            ) : (
                ""
            )}

            {/* <!-- End Row --> */}
            {selectedDevice && (
                <Modal
                    fullWidth
                    maxWidth="sm"
                    onClose={() => setLockDialogConfirmationStatus(false)}
                    modalHeading="Confirm"
                    status={lockDialogConfirmationStatus}
                    onSave={() => {
                        dispatch(
                            unlockDevice({
                                imei: selectedDevice.values.imei,
                                // module:""
                            }),
                        )
                        dispatch(fetchData({ module: "iot" }))
                        setLockDialogConfirmationStatus(false)
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            Are you sure want to unlock this IOT?
                        </Grid>
                    </Grid>
                </Modal>
            )}
            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={dialogConfirmationStatus}
                onSave={() => {
                    dispatch(deleteData(values))
                    setDialogConfirmationStatus(false)
                }}
            >
                {" "}
                Are you sure want to delete this IOT?
            </Modal>
            <Modal
                modalHeading={isEditMode ? "Edit Device" : "Add Device"}
                fullWidth
                maxWidth="md"
                status={formModalStatus}
                onClose={() => {
                    resetForm()
                    handleFormModalStatus(false)
                }}
                onSave={submitForm}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Name*"
                            // disabled={isEditMode ? true : false}
                            placeholder="Name"
                            name="name"
                            value={values.name}
                            error={!!(errors.name && touched.name)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        />
                        <FormHelperText error={!!(errors.name && touched.name)}>
                            {errors.name && touched.name ? errors.name : ""}
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="QR Code"
                            // disabled={isEditMode ? true : false}
                            placeholder="QR Code"
                            name="qr_code"
                            value={values.qr_code}
                            error={!!(errors.qr_code && touched.qr_code)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        />
                        <FormHelperText error={!!(errors.qr_code && touched.qr_code)}>
                            {errors.qr_code && touched.qr_code ? errors.qr_code : ""}
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Model Type*"
                            placeholder="Model Type"
                            name="model_type"
                            value={values.model_type}
                            error={!!errors.model_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            select={true}
                        >
                            <MenuItem selected disabled value="select">
                                Select Device Id
                            </MenuItem>
                            {iotTypes?.map((device, index) => (
                                <MenuItem key={index} value={device.name}>
                                    {device.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormHelperText error={!!errors.model_type}>
                            {errors.model_type ? errors.model_type : ""}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Device Id*"
                            // disabled={isEditMode ? true : false}
                            placeholder="Select Vehicle"
                            name="device_id"
                            value={values.device_id}
                            error={!!(errors.device_id && touched.device_id)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        />
                        <FormHelperText error={!!(errors.device_id && touched.device_id)}>
                            {errors.device_id && touched.device_id ? errors.device_id : ""}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Mac Address*"
                            name="mac_address"
                            error={!!(errors.mac_address && touched.mac_address)}
                            helperText={
                                errors.mac_address && touched.mac_address
                                    ? errors.mac_address
                                    : false
                            }
                            value={values.mac_address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Mac Address"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="IMEI Number*"
                            // type="number"
                            name="imei"
                            // disabled={isEditMode ? true : false}
                            error={!!(errors.imei && touched.imei)}
                            helperText={errors.imei && touched.imei ? errors.imei : false}
                            value={values.imei}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="IMEI Number"
                        />
                    </Grid>
                </Grid>
            </Modal>
        </div>
    )
}

export default WavSmartIOT
