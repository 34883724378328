import React, { createContext, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectFullScreenStatus, selectModalStatus } from "../redux/general/selectors"
import { setFullScreenStatus, setModalStatus } from "../redux/actions"

const ModalContext = createContext({
    modalStatus: false,
    modalStatusHandler: () => {},
    isFullScreen: false,
    toggleFullScreen: (status) => {},
})

function ModalProvider({ children }) {
    const dispatch = useDispatch()
    const status = useSelector(selectModalStatus)
    const isFullScreen = useSelector(selectFullScreenStatus)

    var elem = document.documentElement
    var i = true

    const Fullscreen = (vale) => {
        console.log("elem:", elem)
        switch (vale) {
            case true:
                if (elem.requestFullscreen) {
                    elem.requestFullscreen()
                } else if (elem.webkitRequestFullscreen) {
                    /* Safari */
                    elem.webkitRequestFullscreen()
                } else if (elem.msRequestFullscreen) {
                    /* IE11 */
                    elem.msRequestFullscreen()
                }
                i = false
                break
            case false:
                document.exitFullscreen()
                i = true
                break
            default:
                break
        }
    }

    const handleChange = () => {
        dispatch(setModalStatus(!status))
    }

    const handleFullScreenMode = (mode) => {
        Fullscreen(mode)
        // dispatch(setFullScreenStatus(!isFullScreen))
    }

    return (
        <ModalContext.Provider
            value={{
                isFullScreen,
                toggleFullScreen: handleFullScreenMode,
                modalStatus: status,
                modalStatusHandler: handleChange,
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}

export const useModalCtx = () => {
    const ctx = useContext(ModalContext)
    return ctx
}

export default ModalProvider
