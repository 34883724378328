import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import _ from "lodash"

function NotFound() {
    const navigate = useNavigate()

    useEffect(() => {
        console.log("localStorage.getItem('token'):", localStorage.getItem("token"))
        if (_.isNull(localStorage.getItem("token"))) {
            navigate("/")
        }
    }, [])

    return <div>NotFound</div>
}

export default NotFound
