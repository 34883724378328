import { Card, Col, Row } from "react-bootstrap"
import { VehicleDatatable } from "../../Data/DummyData"
import { SavetableServices } from "../../Data/TablesData/EditableDataTable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"

function Services() {
    return (
        <div>
            <PageHeader titles="Services" active="Services" items={["Tables"]} />
            {/* <!-- Row --> */}

            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Total Users</h6>
                                            <h2 className="mb-0 number-font">44,278</h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                    <span className="text-muted fs-12">
                                        <span className="text-secondary mx-2">
                                            <i className="fe fe-arrow-up-circle  text-secondary"></i>{" "}
                                            5%
                                        </span>
                                        Last week
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <div className="card overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Total Profit</h6>
                                            <h2 className="mb-0 number-font">67,987</h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                    <span className="text-muted fs-12">
                                        <span className="text-pink mx-2">
                                            <i className="fe fe-arrow-down-circle text-pink"></i>{" "}
                                            0.75%
                                        </span>
                                        Last 6 days
                                    </span>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <div className="card overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Total Expenses</h6>
                                            <h2 className="mb-0 number-font">$76,965</h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                    <span className="text-muted fs-12">
                                        <span className="text-green mx-2">
                                            <i className="fe fe-arrow-up-circle text-green"></i>{" "}
                                            0.9%
                                        </span>
                                        Last 9 days
                                    </span>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Total Cost</h6>
                                            <h2 className="mb-0 number-font">$59,765</h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                    <span className="text-muted fs-12">
                                        <span className="text-warning mx-2">
                                            <i className="fe fe-arrow-up-circle text-warning"></i>{" "}
                                            0.6%
                                        </span>
                                        Last year
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="row">
                <Col lg={12}>
                    <Card>
                        {/* <Card.Header>
              <Card.Title as="h3">Add New Row with Edit Table</Card.Title>
            </Card.Header> */}
                        <Card.Body>
                            <SavetableServices data={VehicleDatatable.data} hasAddFeature={false} />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default Services
