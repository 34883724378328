import Layout from "../../Layouts/Layout"
import BSVehicles from "./BSVehicles"
import GeneralVehicles from "./GeneralVehicles"

function Vehicles() {
    const app = sessionStorage.getItem("app")

    const renderComponent = () => {
        switch (app) {
            default:
                return <BSVehicles />
        }
    }

    return renderComponent()
}

export default Vehicles
