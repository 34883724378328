import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { batteryDatatable, VehicleDatatable } from "../Data/DummyData"
import Datatable, { computeColumns } from "../Data/TablesData/Datatable"
import PageHeader from "../Layouts/PageHeader/PageHeader"
import AssetLocationMap from "./Maps/AssetLocationMap"
import { ModalContentMemonized } from "./WorkOrder/WorkOrder"
import { useDatatableCtx } from "../components/Datatable/DatatableContext"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    selectDatatable,
    selectFilteredData,
    selectModuleSpecificData,
} from "../redux/templateData/selectors"
import { DashboardStatistics, renderInfoCardDataFunction } from "../Data/DummyData"
import { useInfoCtx } from "../providers/InfoCardProvider"
import { addPage, fetchData, setFilteredData, setModule } from "../redux/templateData/actions"
import { initialState } from "../redux/templateData/init"
import Modal from "../components/Modal"
import { useModalCtx } from "../providers/ModalProvider"
import { useFormik } from "formik"
import { Grid, MenuItem, TextField } from "@mui/material"
import { addData, deleteData, editData } from "../redux/actions"
import { BatteryFormValidations } from "../validations"

function Battery() {
    const module = "batteries"
    const batteryData = useSelector(selectDatatable)
    const dispatch = useDispatch()
    const [dialogConfirmationStatus, setDialogConfirmationStatus] = useState(false)
    const modalContext = useModalCtx()
    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext
    const batteryHisotory = useSelector(selectModuleSpecificData("battery_history"))
    const [isEditMode, setEditMode] = useState(false)
    const [historyModalStatus, setHistoryModalStatus] = useState(false)
    const { renderInfoCardData } = useInfoCtx()
    const filteredResult = useSelector(selectFilteredData)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns2 = React.useMemo(
        () =>
            computeColumns({
                data: batteryHisotory,
                meta: batteryDatatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [batteryHisotory],
    )

    const { values, errors, touched, resetForm, handleBlur, handleChange, setValues, submitForm } =
        useFormik({
            initialValues: {
                battery_id: "",
                battery_firmware_version: "",
                battery_cell_voltage: "",
                battery_charging_state: "",
                battery_level: "",
                is_over_heating: false,
                number_of_recharges: "",
                distance_since_last_recharge: "",
            },
            onSubmit: () => {
                const obj = { ...values }
                Object.keys(obj).forEach((key) => {
                    if (obj[key] === null || obj[key] === "") {
                        obj[key] = null
                    }
                })

                if (isEditMode) {
                    dispatch(editData(obj))
                } else {
                    dispatch(addData(obj))
                }
            },
            validationSchema: BatteryFormValidations,
        })

    const columns = useMemo(
        () =>
            computeColumns({
                data: batteryData.data,
                meta: batteryData.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [batteryData.data],
    )

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    const data = useMemo(() => batteryData.data, [batteryData.data])

    return (
        <div>
            <PageHeader titles="Battery" active="Battery" items={["Tables"]} />
            {/* <!-- Row --> */}
            <Row>
                {DashboardStatistics.filter((item) => item.module === "batteries").map(
                    (card, index) => (
                        <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">{card.heading}</h6>
                                            <h2 className="mb-0 number-font">
                                                {renderInfoCardData(card)}
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ),
                )}
            </Row>
            <div>
                <AssetLocationMap
                    ids={data.map((d) => d.battery_id)}
                    type={"battery_id"}
                    filteredResult={filteredResult}
                />
            </div>
            <Row>
                <Col lg={12} style={{ textAlign: "right" }}>
                    <div style={{ marginBottom: "20px" }}>
                        <Button
                            onClick={() => {
                                setEditMode(false)
                                handleFormModalStatus(true)
                                resetForm()
                            }}
                            variant="secondary"
                        >
                            Add Battery
                        </Button>
                    </div>
                </Col>
            </Row>
            <div className="row">
                <Col lg={12}>
                    {/* META DATA: {JSON.stringify(batteryData.meta)} */}
                    <Card>
                        <Card.Body>
                            <Datatable
                                sortable_key="battery_id"
                                actions={[
                                    { name: "history", type: "history", buttonType: "icon" },
                                    { name: "Edit", type: "edit", buttonType: "icon" },
                                    { name: "Delete", type: "delete", buttonType: "icon" },
                                ]}
                                onActionClick={(event, { type, data }) => {
                                    setValues(data.original)
                                    if (type == "edit") {
                                        console.log("DATA HERE: ", data)
                                        setEditMode(true)
                                        // console.log('data.original: ', data.original);
                                        handleFormModalStatus(true)
                                    } else if (type == "delete") {
                                        setDialogConfirmationStatus(true)
                                    } else if (type == "history") {
                                        dispatch(
                                            addPage({
                                                key: "battery_history",
                                                data: initialState.vehicles,
                                            }),
                                        )
                                        dispatch(
                                            fetchData({
                                                module: "battery_history",
                                                battery_id: data.values.battery_id,
                                            }),
                                        )
                                        setHistoryModalStatus(true)
                                    }
                                }}
                                isMultiSelect={false}
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            <Modal
                modalHeading={isEditMode ? "Edit" : "Add"}
                fullWidth
                maxWidth="md"
                status={formModalStatus}
                onClose={() => {
                    resetForm()
                    handleFormModalStatus(false)
                }}
                onSave={submitForm}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {/* battery_id, battery_firmware_version, battery_cell_voltage */}
                        <TextField
                            label="Battery ID*"
                            name="battery_id"
                            disabled={!!isEditMode}
                            error={!!(errors.battery_id && touched.battery_id)}
                            helperText={
                                errors.battery_id && touched.battery_id ? errors.battery_id : false
                            }
                            value={values.battery_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Battery ID"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Battery Firmware Version*"
                            name="battery_firmware_version"
                            error={
                                !!(
                                    errors.battery_firmware_version &&
                                    touched.battery_firmware_version
                                )
                            }
                            helperText={
                                errors.battery_firmware_version && touched.battery_firmware_version
                                    ? errors.battery_firmware_version
                                    : false
                            }
                            value={values.battery_firmware_version}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Battery Firmware Version"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Battery Cell Voltage"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            name="battery_cell_voltage"
                            error={!!(errors.battery_cell_voltage && touched.battery_cell_voltage)}
                            helperText={
                                errors.battery_cell_voltage && touched.battery_cell_voltage
                                    ? errors.battery_cell_voltage
                                    : false
                            }
                            value={values.battery_cell_voltage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Battery Cell Voltage"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Battery Charging State"
                            name="battery_charging_state"
                            select
                            error={
                                !!(errors.battery_charging_state && touched.battery_charging_state)
                            }
                            helperText={
                                errors.battery_charging_state && touched.battery_charging_state
                                    ? errors.battery_charging_state
                                    : false
                            }
                            value={values.battery_charging_state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Battery Charging State"
                        >
                            <MenuItem value="charging">Charging</MenuItem>
                            <MenuItem value="charged">Charged</MenuItem>
                            <MenuItem value="discharged">Discharged</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Battery level"
                            name="battery_level"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            error={!!(errors.battery_level && touched.battery_level)}
                            helperText={
                                errors.battery_level && touched.battery_level
                                    ? errors.battery_level
                                    : false
                            }
                            value={values.battery_level}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Battery Level"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Is Overheating"
                            name="is_over_heating"
                            select={true}
                            error={!!(errors.is_over_heating && touched.is_over_heating)}
                            helperText={
                                errors.is_over_heating && touched.is_over_heating
                                    ? errors.is_over_heating
                                    : false
                            }
                            value={values.is_over_heating}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Battery Is Overheating"
                        >
                            <MenuItem value={false}>No</MenuItem>
                            <MenuItem value={true}>Yes</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Number of recharges"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            name="number_of_recharges"
                            error={!!(errors.number_of_recharges && touched.number_of_recharges)}
                            helperText={
                                errors.number_of_recharges && touched.number_of_recharges
                                    ? errors.number_of_recharges
                                    : false
                            }
                            value={values.number_of_recharges}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Number of recharges"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Average battery distance"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            name="average_battery_distance"
                            error={
                                !!(
                                    errors.average_battery_distance &&
                                    touched.average_battery_distance
                                )
                            }
                            helperText={
                                errors.average_battery_distance && touched.average_battery_distance
                                    ? errors.average_battery_distance
                                    : false
                            }
                            value={values.average_battery_distance}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Average battery distance"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Distance since last recharge"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            name="distance_since_last_recharge"
                            error={
                                !!(
                                    errors.distance_since_last_recharge &&
                                    touched.distance_since_last_recharge
                                )
                            }
                            helperText={
                                errors.distance_since_last_recharge &&
                                touched.distance_since_last_recharge
                                    ? errors.distance_since_last_recharge
                                    : false
                            }
                            value={values.distance_since_last_recharge}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Distance Since Last Recharge"
                        />
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={dialogConfirmationStatus}
                onSave={() => {
                    dispatch(deleteData(values))
                    setDialogConfirmationStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        Are you sure want to delete this record?
                    </Grid>
                </Grid>
            </Modal>

            <Modal
                fullWidth
                maxWidth="md"
                disableSave={true}
                onClose={() => setHistoryModalStatus(false)}
                modalHeading="Battery History"
                status={historyModalStatus}
                onSave={() => {
                    setHistoryModalStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Datatable
                            modalSize="xl"
                            isMultiSelect={false}
                            columns={columns2}
                            data={batteryHisotory}
                        />
                    </Grid>
                </Grid>
            </Modal>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default Battery
