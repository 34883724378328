import { useEffect, useMemo } from "react"
import { Card, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { AdminUsersData } from "../../Data/WebsiteData/efleety"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import { selectData, selectDatatable } from "../../redux/templateData/selectors"
import { initialState } from "../../redux/templateData/init"

function AdminUsers() {
    const data = useSelector(selectDatatable)
    const dispatch = useDispatch()
    const module = "operator_admin_users"

    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: data.data,
            meta: data.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    // const data = useMemo(() => AdminUsersData.data, [AdminUsersData.data]);

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    return (
        <div>
            <PageHeader titles="Dustbins" active="Dustbins" items={["Tables"]} />
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                sortable_key="name"
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data.data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </div>
    )
}

export default AdminUsers
