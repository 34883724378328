import { createAction } from "@reduxjs/toolkit"
import TYPES from "../types"

export const generalApiCall = createAction(TYPES.GENERAL_API_CALL)

export const mapRouteWaypointApiCall = createAction(TYPES.MAP_WAYPOINTS_API_CALL)

export const setMapWaypointResponse = createAction(TYPES.SET_MAP_WAYPOINTS_API_RESPONSE)

export const setResponse = createAction(TYPES.SET_GENERAL_API_RESPONSE)
