import { Card, Col, Row } from "react-bootstrap"
import { CusotmersData, CustomersData } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { BasicDataTable } from "../../Data/TablesData/TableData"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { useEffect, useMemo } from "react"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { initialState } from "../../redux/templateData/init"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import { useDispatch, useSelector } from "react-redux"
import { selectDatatable, selectFilteredData } from "../../redux/templateData/selectors"
import { DashboardStatistics } from "../../Data/WebsiteData/moby"
import { useInfoCtx } from "../../providers/InfoCardProvider"

function CustomerCardTransaction() {
    const dispatch = useDispatch()
    const datatable = useSelector(selectDatatable)
    const { renderInfoCardData } = useInfoCtx()
    const filteredResult = useSelector(selectFilteredData)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const module = "payment_transactions"
    const columns = useMemo(
        () =>
            computeColumns({
                data: datatable.data,
                meta: datatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [datatable.data],
    )

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    const data = useMemo(() => datatable.data, [datatable.data])

    return (
        <div style={{ backgroundColor: "white", height: "100%" }}>
            {/* {DashboardStatistics.filter(
                            (item) => item.module === "payment_transactions",
                        ).map((card, index) => (
                            <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                                <Card className="overflow-hidden">
                                    <Card.Body>
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">{card.heading}</h6>
                                                <h2 className="mb-0 number-font">
                                                    {renderInfoCardData(card)}
                                                </h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1"></div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))} */}
            {data.length > 0 && (
                <Datatable
                    filteredResult={filteredResult}
                    refreshAction={() => {
                        dispatch(fetchData({ module }))
                    }}
                    datatableTitle="Customer Card Transactions"
                    sortable_key="name"
                    modalStatus={status}
                    modalContent={ModalContentMemonized}
                    handleModalStatus={setModalStatus}
                    columns={columns}
                    data={data}
                />
            )}
        </div>
    )
}

export default CustomerCardTransaction
