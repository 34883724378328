import React, { useEffect, useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"
import NearMeIcon from "@mui/icons-material/NearMe"
import CloseIcon from "@mui/icons-material/Close"

function display(a) {
    const d = Number(a)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hr ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : ""
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
    return hDisplay + mDisplay
}

function MapExtraInformation({
    filteredData,
    routeData,
    setClose,
    renderSaveButton,
    closeRoute,
    totalWaste,
    allRoutes,
    updateCoordinatesToStorage,
}) {
    const [routeInfo, setRouteInfo] = useState([])
    const app_key = sessionStorage.getItem("app_key")

    const vehiclesAppKey = ["keego", "bombay_softwares", "gow"]

    const handleClose = () => {
        setClose(false)
        closeRoute()
    }

    useEffect(() => {
        console.log("all routes:", allRoutes)
        setRouteInfo([...allRoutes])
    }, [allRoutes])

    const setPosition = (data) => {
        updateCoordinatesToStorage({ lat: data.route.origin[0], lng: data.route.origin[1] })
    }

    return (
        <>
            <div
                className="summaryPopUp routing-details-filters bin__summary"
                style={{
                    width: "20rem",
                    height: `${routeInfo?.length > 1 ? "35rem" : "20rem"}`,
                    // border: "2px solid navy", // Add border CSS property here
                    borderRadius: "10px",
                }}
            >
                <div className="header bin__summary-header">
                    <span>Route Information</span>
                    <span
                        style={{ position: "absolute", right: "1%", cursor: "pointer" }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div
                    style={{
                        marginTop: "3rem",
                        height: `${routeInfo?.length > 1 ? "27.5rem" : "12.5rem"}`,
                        overflowY: `${routeInfo?.length > 1 ? "auto" : "hidden"}`,
                    }}
                >
                    {routeInfo?.map((data, index) => {
                        return (
                            <div
                                key={index}
                                className="d-flex justify-content-start flex-column mt-5"
                            >
                                {/* {routeInfo.length > 1 && ( */}
                                <>
                                    {index != 0 && (
                                        <hr
                                            style={{
                                                border: "none",
                                                borderTop: "1px solid #ccc",
                                                margin: "20px 0",
                                            }}
                                        />
                                    )}
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ position: "relative" }}
                                    >
                                        <h4 className="text-dark text-center text-capitalize">
                                            {data.route?.zone?.split("_").join(" ")}
                                        </h4>
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: 0,
                                                right: "15%",
                                            }}
                                        >
                                            <NearMeIcon
                                                style={{ color: "#000000" }}
                                                onClick={() => {
                                                    setPosition(data)
                                                }}
                                            />
                                        </span>
                                    </div>
                                </>
                                {/* )} */}

                                <table className="table table-striped mt-0">
                                    <tbody>
                                        {!vehiclesAppKey.includes(app_key) && (
                                            <tr>
                                                <td className="fw-normal text-capitalize">
                                                    Waste:
                                                </td>
                                                <td className="text-end" style={{ width: "50%" }}>
                                                    {/* + filteredData.length * 5 * 60 */}
                                                    {`${data.route.assetsData
                                                        ?.reduce((sum, data) => {
                                                            sum = sum + data.fill_level
                                                            return sum
                                                        }, 0)
                                                        .toFixed(0)}
                                                liters`}
                                                    {/* {totalWaste
                                                    ? `${Number(totalWaste)?.toFixed(2)} liters`
                                                    : isNaN(
                                                            filteredData.reduce((acc, data) => {
                                                                acc += data.fill_level
                                                                return acc
                                                            }, 0),
                                                        )
                                                      ? "-"
                                                      : `${filteredData
                                                            .reduce((acc, data) => {
                                                                acc += data.fill_level
                                                                return acc
                                                            }, 0)
                                                            .toFixed(2)} liters`} */}
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td className="fw-normal text-capitalize">Time:</td>
                                            <td className="text-end">
                                                {/* {routeData[0]?.duration} */}
                                                {display(data?.duration)}
                                                {/* {moment
                                        .duration(
                                            routeData[0]?.duration + filteredData.length * 5,
                                            "minutes",
                                        )
                                        .format("h [hrs], m [min]")} */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fw-normal text-capitalize">Distance:</td>
                                            <td className="text-end">
                                                {data?.distance
                                                    ? `${Number(data?.distance / 1000)?.toFixed(2)} km`
                                                    : "N/A"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                className="fw-normal text-capitalize"
                                                style={{ width: "60%" }}
                                            >
                                                {vehiclesAppKey.includes(app_key)
                                                    ? "Number of Bikes"
                                                    : "Number of Containers:"}
                                            </td>
                                            <td className="text-end">
                                                {data.route.assetsData?.length}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })}
                </div>
                <div className="mt-4">{renderSaveButton()}</div>
            </div>
        </>
    )
}

export default MapExtraInformation
