import { Box } from "@mui/material"
import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { DashboardStatistics } from "../../Data/DummyData"
import { dataMapping } from "../../Data/DummyData"

function Configurations() {
    return (
        <>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <input placeholder="Name" type="text" className="form-control" />
                        </Col>
                        <Col md={3}>
                            <select
                                onClick={(e) => {
                                    // console.log(e.target.value);
                                }}
                                className="form-control"
                            >
                                {dataMapping.map((object, index) => (
                                    <option key={index} value={object.action}>
                                        {object.key}
                                    </option>
                                ))}
                            </select>
                            {/* <input placeholder="utility" type="text" class="form-control" /> */}
                        </Col>
                        <Col md={3}>
                            <input placeholder="Order" type="text" className="form-control" />
                        </Col>
                        <Col md={3}>
                            <Button variant="secondary">Submit</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Box marginTop={3}>
                                {DashboardStatistics.map((tile, index) => (
                                    <Box key={`DASH${index}`} marginBottom={1} display="flex">
                                        <Box flex={1}>{tile.heading}</Box>
                                        <Box flex={1}>{tile.order}</Box>
                                        <Box flex={1} marginLeft={10}>
                                            <Button variant="secondary">Remove</Button>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default Configurations
