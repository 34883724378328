import React, { useEffect, useState } from "react"
import styles from "./AdditionalInfo.module.scss"
import { useSelector } from "react-redux"
import { selectAuthUser, selectWebsites } from "../../redux/auth/selectors"

export function transformData(originalData) {
    const transformedData = {}
    for (const key in originalData) {
        if (Object.prototype.hasOwnProperty.call(originalData, key)) {
            const nestedObject = originalData[key]
            const transformedNestedObject = {}
            Object.keys(nestedObject).forEach((nestedKey) => {
                transformedNestedObject[nestedKey] = {
                    value: nestedObject[nestedKey],
                }
            })
            transformedData[key] = transformedNestedObject
        }
    }
    return transformedData
}

function formatWords(words) {
    return words
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
}
function InfoChipEfleety({ label, imageUrl }) {
    const modifiedImagePath = imageUrl?.replace("/assets/images", "")
    // const getLabelColor = (text) => {
    //     switch (text.toLowerCase()) {
    //         case "full":
    //             return "#999900"
    //         case "overflow":
    //             return "red"
    //         default:
    //             return "inherit"
    //     }
    // }
    // const color = getLabelColor(label.split(":")[0].trim())
    //console.log(color);
    return (
        <div
            className={styles.info_chip2}
            style={{
                padding: "8px",
                paddingTop: "20px",
                paddingBottom: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            {modifiedImagePath ? (
                <div className={styles.icon_container}>
                    <img src={modifiedImagePath} className={styles.icon} />
                    <span className={styles.icon_font}>{label.split(": ")[1]}</span>
                </div>
            ) : (
                <span>{formatWords(label.replace("total_", ""))}</span>
            )}
        </div>
    )
}

function InfoChipWavsmart({ label }) {
    const getLabelColor = (label) => {
        console.log("sas", label.toLowerCase())
        switch (label.toLowerCase()) {
            case "total":
                return {
                    backgroundColor: "#3333FF",
                    color: "white",
                }
            case "empty":
                return {
                    backgroundColor: "#008000",
                    color: "white",
                }
            case "normal":
                return {
                    backgroundColor: "#FEE12B",
                    color: "black",
                }
            case "warning":
                return {
                    backgroundColor: "#FFA500",
                    color: "black",
                }
            case "overflow":
                return {
                    backgroundColor: "#FF0000",
                    color: "white",
                }
            case "disabled":
                return {
                    backgroundColor: "#808080",
                    color: "white",
                }
            default:
                return "inherit"
        }
    }
    const color = getLabelColor(label.split(":")[0].trim())
    //console.log(color);
    return (
        <div className="col p-0 mx-1 " style={{ width: "40%" }}>
            <div
                className={styles.info_chip}
                style={{
                    backgroundColor: `${color.backgroundColor}`,
                    color: `${color.color}`,
                }}
            >
                {label.split(":")[1].trim()}
            </div>
        </div>
    )
}
function AdditionalInfo({ data, data2, app_key, keys }) {
    const [modifiedEfleetyStats, setModifiedEfleetyStats] = useState({})
    const [modifiedWavsmartStats, setModifiedWavsmartStats] = useState({})
    useEffect(() => {
        const transformedEfleetyStats = transformData(data2)
        const transformedWavsmartStats = transformData(data)

        if (transformedEfleetyStats) {
            setModifiedEfleetyStats(transformedEfleetyStats)
            console.log("", transformedEfleetyStats)
        }
        if (transformedWavsmartStats) {
            setModifiedWavsmartStats(transformedWavsmartStats)
        }
    }, [data2, data])
    const Websites = useSelector(selectWebsites)

    const [efleetyKeys, setEfleetyKeys] = useState([])
    useEffect(() => {
        if (data2) {
            setEfleetyKeys(Object.keys(data2[app_key]))
            // console.log("data2", data2[app_key], app_key)
            // console.log("data3", Object.keys(data2[app_key]))
            // console.log("data5", app_key, efleetyKeys)
            // console.log(
            //     "data6",
            //     app_key,
            //     data2[app_key][efleetyKeys[0]],
            //     data2[app_data2 == undefinedkey][efleetyKeys[1]],
            //     data2[app_key][efleetyKeys[2]],
            //     data2[app_key][efleetyKeys[3]],
            // )
        }
    }, [data2, app_key])

    if (data && Websites.some((obj) => obj.name === "Tilt Sensor")) {
        return (
            <div
                className="container"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0",
                }}
            >
                {console.log("dsfas", data2)}

                <div
                    className="row"
                    style={{
                        // maxWidth: "400px",
                        padding: "0",
                        textAlign: "left",
                        marginLeft: "0",
                        marginRight: "0",
                    }}
                >
                    {/* {console.log("vwsfQ", data)} */}
                    <InfoChipWavsmart
                        label={`Empty: ${data[app_key]?.empty ? data[app_key].empty : 0}`}
                    />
                    <InfoChipWavsmart
                        label={`Normal: ${data[app_key]?.normal ? `${data[app_key].normal}` : "0"}`}
                    />
                    <InfoChipWavsmart
                        label={`Warning: ${data[app_key]?.warning ? data[app_key].warning : 0}`}
                    />
                    <InfoChipWavsmart
                        label={`Overflow: ${data[app_key]?.overflow ? data[app_key].overflow : 0}`}
                    />
                </div>
            </div>
        )
    } else if (data2 && Websites.some((obj) => obj.name === "Keego")) {
        return (
            <div
                className={styles.info_chip_container}
                style={{
                    // maxWidth: "400px",
                    // backgroundColor: "black",
                    padding: "0",
                    textAlign: "left",
                    marginLeft: "0",
                    marginRight: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                }}
            >
                {data2 &&
                    data2[app_key] &&
                    Object.keys(data2[app_key])
                        .slice(0, 4)
                        .map((key) => {
                            const value = data2[app_key][key].value
                            // console.log("Key:", key, "Value:", value)

                            const uniqueKey = `${key}-${value}`
                            return (
                                <>
                                    <div key={uniqueKey}>
                                        {/* {console.log("vwsfQ", data2)} */}
                                        <InfoChipEfleety
                                            label={`${key}: ${value}`}
                                            imageUrl={data2[app_key][key].icon_path}
                                        />
                                    </div>
                                </>
                            )
                        })}
            </div>
        )
    }
}
export default AdditionalInfo
