import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { DustbinDatatable } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import AddIcon from "@mui/icons-material/Add"
import AssetLocationMap from "../Maps/AssetLocationMap"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { useEffect, useMemo, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { initialState } from "../../redux/templateData/init"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import {
    selectDatatable,
    selectFilteredData,
    selectModuleSpecificData,
} from "../../redux/templateData/selectors"
import { DashboardStatistics } from "../../Data/DummyData"
import { useInfoCtx } from "../../providers/InfoCardProvider"
import { useFormik } from "formik"
import { useModalCtx } from "../../providers/ModalProvider"
import Modal from "../../components/Modal"
import { Grid, MenuItem, TextField } from "@mui/material"
import { addData, deleteData, editData } from "../../redux/actions"
import { StationsValidations } from "../../validations"
import LocationSelectorMap from "../Maps/LocationSelectorMap"
import AssetLocationMapNew from "../Maps/AssetLocationMapNew"
import MapsWithMultipleRoute from "../Maps/MapWithMultipleRoute"
import { selectMapViewStatus } from "../../redux/general/selectors"
import { setMapViewStatus } from "../../redux/actions"
import { app_key_arr } from "../../redux/globalConstants/globalConstants"

function Stations() {
    const datatable = useSelector(selectDatatable)
    const filteredResult = useSelector(selectFilteredData)
    const dispatch = useDispatch()
    const stationHistory = useSelector(selectModuleSpecificData("station_history"))
    const [dialogConfirmationStatus, setDialogConfirmationStatus] = useState(false)
    const { renderInfoCardData } = useInfoCtx()
    const [isEditMode, setEditMode] = useState(false)
    const toggleMapTable = useSelector(selectMapViewStatus)
    const modalContext = useModalCtx()
    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext
    const module = "stations"
    const [historyModalStatus, setHistoryModalStatus] = useState(false)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const mapReference = useRef()

    const {
        values,
        errors,
        touched,
        resetForm,
        handleBlur,
        handleChange,
        setFieldValue,
        setValues,
        submitForm,
    } = useFormik({
        initialValues: {
            location: "",
            latitude: "",
            longitude: "",
            type: "pickup",
            storage_type: "",
        },
        onSubmit: () => {
            const obj = { ...values }
            Object.keys(obj).forEach((key) => {
                if (obj[key] === null || obj[key] === "") {
                    obj[key] = null
                }
            })

            if (isEditMode) {
                dispatch(editData(obj))
            } else {
                dispatch(addData(obj))
            }
        },
        validationSchema: StationsValidations,
    })

    const columns = useMemo(() =>
        computeColumns({
            data: datatable.data,
            meta: datatable.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const columns2 = React.useMemo(
        () =>
            computeColumns({
                data: stationHistory,
                meta: DustbinDatatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [stationHistory],
    )
    let data = useMemo(() => datatable.data, [datatable.data])
    const [newData, setNewData] = useState(null)
    // function convertToFormattedName(inputString) {
    //     console.log("Sahew", inputString)

    //     if (!inputString.includes("_")) {
    //         return inputString
    //     }
    //     // Split the input string into an array of words based on underscores
    //     const words = inputString.split("_")

    //     // Map over the words array to capitalize each word
    //     const capitalizedWords = words.map((word, index) => {
    //         if (index === 0) {
    //             // Capitalize only the first word
    //             return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    //         } else {
    //             // Capitalize subsequent words
    //             return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    //         }
    //     })

    //     // Join the capitalized words array into a single string with spaces
    //     const result = capitalizedWords.join(" ")

    //     return result
    // }
    function convertToFormattedName(inputString) {
        if (inputString.includes("_")) {
            let parts = inputString.split("_")
            let capitalizedParts = []

            for (let part of parts) {
                let capitalizedPart = part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
                capitalizedParts.push(capitalizedPart)
            }

            let result = capitalizedParts.join(" ")
            console.log("vsg", result)
            return result
        } else {
            console.log("vsg", inputString)
            return inputString
        }
    }

    useEffect(() => {
        if (data) {
            const updatedData = data.map((item) => ({
                ...item,
                // storage_type: convertToFormattedName(item.storage_type),
                storage_type: item.storage_type ? convertToFormattedName(item.storage_type) : null,
            }))
            setNewData(updatedData)
        }
    }, [data])

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    const handleLocationSelect = (location) => {
        setFieldValue("latitude", location.lat)
        setFieldValue("longitude", location.lng)
    }

    const wavsmartOpt = [
        { label: "Kerosene", value: "kerosene" },
        { label: "Water", value: "water" },
        { label: "Waste", value: "waste" },
    ]
    const keegoOpt = [
        { label: "Depot", value: "depot" },
        { label: "Repair Center", value: "repair_center" },
        { label: "City Bike Stand", value: "city_bike_stand" },
    ]

    const app_key = sessionStorage.getItem("app_key")

    const renderOptions = () => {
        if (app_key_arr.includes(app_key)) {
            return keegoOpt
        } else {
            return wavsmartOpt
        }
    }

    return (
        <div style={{ backgroundColor: "white", height: "100%" }}>
            {console.log("sad")}
            <div className={`map-view ${toggleMapTable == "map_view" ? "hide" : "show"}`}>
                <Row>
                    {DashboardStatistics.filter((item) => item.module === "stations").map(
                        (card, index) => (
                            <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                                <Card className="overflow-hidden">
                                    <Card.Body>
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">{card.heading}</h6>
                                                <h2 className="mb-0 number-font">
                                                    {renderInfoCardData(card)}
                                                </h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1"></div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ),
                    )}
                </Row>{" "}
            </div>
            <div className={`map-view ${toggleMapTable == "map_view" ? "show" : "hide"}`}>
                {/* <div className="map_statistics"><WavSmartDashboard /></div> */}
                <div id="map">
                    {data && <MapsWithMultipleRoute ref={mapReference} data={data} stations={[]} />}
                </div>
            </div>
            <div className={`${toggleMapTable == "map_view" ? "hide" : "show"}`}>
                {newData && (
                    <Datatable
                        filteredResult={filteredResult}
                        // buttons={[
                        //     {
                        //         index: 1,
                        //         text: "Map View",
                        //         icon: <AddIcon />,
                        //         action: () => {
                        //             setToggleMapTable(!toggleMapTable)
                        //         },
                        //     },
                        // ]}
                        addActionName="Add Station"
                        addAction={() => {
                            setEditMode(false)
                            handleFormModalStatus(true)
                            resetForm()
                        }}
                        datatableTitle="Stations"
                        refreshAction={() => {
                            dispatch(fetchData({ module }))
                        }}
                        sortable_key="station_id"
                        actions={[
                            {
                                name: "location",
                                type: "location",
                                buttonType: "icon",
                            },
                            {
                                name: "history",
                                type: "history",
                                buttonType: "icon",
                            },

                            { name: "Edit", type: "edit", buttonType: "icon" },
                            { name: "Delete", type: "delete", buttonType: "icon" },
                        ]}
                        onActionClick={(event, { type, data }) => {
                            setValues(data.original)
                            if (type == "location") {
                                mapReference.current.routesReset()
                                mapReference.current.setCenter({
                                    lat: data.values.latitude,
                                    lng: data.values.longitude,
                                })
                                mapReference.current.setZoom(15)
                                dispatch(setMapViewStatus("map_view"))
                                document.getElementById("map").scrollIntoView()
                            } else if (type == "edit") {
                                setEditMode(true)
                                handleFormModalStatus(true)
                            } else if (type == "delete") {
                                setDialogConfirmationStatus(true)
                            } else if (type == "history") {
                                dispatch(
                                    addPage({
                                        key: "station_history",
                                        data: initialState.vehicles,
                                    }),
                                )
                                dispatch(
                                    fetchData({
                                        module: "station_history",
                                        station_id: data.values.station_id,
                                    }),
                                )
                                setHistoryModalStatus(true)
                            }
                        }}
                        modalStatus={status}
                        modalContent={ModalContentMemonized}
                        handleModalStatus={setModalStatus}
                        columns={columns}
                        data={newData}
                    />
                )}
            </div>
            <Modal
                modalHeading={isEditMode ? "Edit" : "Add"}
                fullWidth
                maxWidth="md"
                status={formModalStatus}
                onClose={() => {
                    resetForm()
                    handleFormModalStatus(false)
                }}
                onSave={submitForm}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LocationSelectorMap
                            onLocationSelect={handleLocationSelect}
                            defaultLocation={{
                                lat: values.latitude,
                                lng: values.longitude,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* battery_id, battery_firmware_version, battery_cell_voltage */}
                        <TextField
                            label="Location*"
                            name="location"
                            // disabled={isEditMode ? true : false}
                            error={!!(errors.location && touched.location)}
                            helperText={
                                errors.location && touched.location ? errors.location : false
                            }
                            value={values.location}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Location"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Latitude*"
                            name="latitude"
                            error={!!(errors.latitude && touched.latitude)}
                            helperText={
                                errors.latitude && touched.latitude ? errors.latitude : false
                            }
                            value={values.latitude}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Latitude"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Longitude*"
                            name="longitude"
                            error={!!(errors.longitude && touched.longitude)}
                            helperText={
                                errors.longitude && touched.longitude ? errors.longitude : false
                            }
                            value={values.longitude}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Longitude"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Type"
                            name="type"
                            select
                            error={!!(errors.type && touched.type)}
                            helperText={errors.type && touched.type ? errors.type : false}
                            value={values.type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Station Type"
                        >
                            <MenuItem value="pickup">Pickup</MenuItem>
                            <MenuItem value="dropoff">Drop off</MenuItem>
                            <MenuItem value="pickup_dropoff_both">Both</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label={app_key_arr.includes(app_key) ? "Facility Type" : "Storage Type"}
                            name="storage_type"
                            select
                            error={!!(errors.storage_type && touched.storage_type)}
                            helperText={
                                errors.storage_type && touched.storage_type
                                    ? errors.storage_type
                                    : false
                            }
                            value={values.storage_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Station Type"
                        >
                            {renderOptions()?.map((el, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={el.value}
                                        className="text-capitalize"
                                    >
                                        {el.label}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </Grid>
                </Grid>
            </Modal>
            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={dialogConfirmationStatus}
                onSave={() => {
                    dispatch(deleteData(values))
                    setDialogConfirmationStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        Are you sure want to delete this record?
                    </Grid>
                </Grid>
            </Modal>
            <Modal
                fullWidth
                maxWidth="md"
                onClose={() => setHistoryModalStatus(false)}
                // modalHeading="Battery History"
                modalHeading=" "
                status={historyModalStatus}
                disableSave={true}
                onSave={() => {
                    setHistoryModalStatus(false)
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Datatable
                            filteredResult={stationHistory}
                            hasFilterAffectsMap={false}
                            modalSize="xl"
                            isMultiSelect={false}
                            columns={columns2}
                            data={stationHistory}
                            page_name="station_history"
                            datatableTitle="Station History"
                            id="station_history_scroller"
                        />
                    </Grid>
                </Grid>
            </Modal>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default Stations
