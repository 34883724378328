import moment from "moment"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import Datatable, { computeColumns, convertUTCToLocal } from "../../../Data/TablesData/Datatable"
import {
    allSimulationData,
    batteryDatatable,
    InspectionData,
    InspectionWithMetaData,
    IssuesDatatable,
    OperatorReportDatatable,
    RidersData,
    serviceIdActions,
    ServiceWithMetaData,
    vehicleBatteryHistory,
    VehicleDatatable,
    WorkOrderDatatable,
} from "../../DummyData"
import { addPage, fetchData } from "../../../redux/templateData/actions"
import { useDispatch, useSelector } from "react-redux"
import { initialState } from "../../../redux/templateData/init"
import {
    selectData,
    selectMetaInformation,
    selectModuleSpecificData,
} from "../../../redux/templateData/selectors"
import { useDatatableCtx } from "../../../components/Datatable/DatatableContext"
import { ModalContentMemonized } from "../../../screens/WorkOrder/WorkOrder"
import "./style.css"
import {
    generalApiCall,
    mapRouteWaypointApiCall,
    setResponse,
    setMapWaypointResponse,
} from "../../../redux/general/actions"
import { selectGeneralApiData } from "../../../redux/general/selectors"
import JMap from "../../../components/Map/JMap"

function ModalContentGeneral({ data }) {
    const { setHeading } = useDatatableCtx()
    const [vehicleData, setVehicleData] = useState({})
    const [vehicleRideHistory, setRideHistory] = useState([])
    const [historyData, setHistoryData] = useState({
        type: "vehicle_history",
        data: [],
        history: [],
    })

    useEffect(() => {
        setHeading("Vehicle Details")
        const vehicleRideHistory = allSimulationData.filter(
            (simulation) => simulation.vehicle_id == data.vehicle_id,
        )
        // console.log(vehicleRideHistory);
        setRideHistory([...vehicleRideHistory])
        const history = vehicleBatteryHistory.filter(
            (historyData) => historyData.vehicle_id == data.vehicle_id,
        )

        history.map((batteryHistoryData) => {
            batteryHistoryData.battery = batteryDatatable.data.find(
                (battery) => battery.battery_id == data.battery_id,
            )
        })

        const battery = history.map((data) => data.battery_id)

        const vehicleData = VehicleDatatable.data.filter((data) =>
            battery.includes(data.battery_id),
        )

        const firstEntry = vehicleData[0]
        setVehicleData(firstEntry)
        setHistoryData({
            id: data.battery_id,
            type: "battery_history",
            data: [...vehicleData],
            history: history.map((data) => ({
                ...data,
                battery: firstEntry,
            })),
        })
    }, [data])

    return (
        <>
            <Row>
                <Col xs={6}>
                    <div>
                        <b>Vehicle ID: </b> {data.vehicle_id}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Number of rides: </b>
                        {vehicleData?.number_of_rides}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Performance: </b>
                        {vehicleData?.performance}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Battery Level: </b>
                        {vehicleData?.battery_level}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Distance Travelled: </b>
                        {vehicleData?.distance_travelled}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Distance Since Last Inspection: </b>
                        {vehicleData?.distance_since_last_inspection}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Vehicle Active Time: </b>
                        {vehicleData?.vehicle_active_time}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Vehicle Down Time: </b>
                        {vehicleData?.vehicle_down_time}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Average Distance Time: </b>
                        {vehicleData?.average_battery_distance}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Number of Different Riders: </b>
                        {vehicleData?.number_of_different_riders}
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ padding: "10px 0px" }}>
                <Col xs={6}>
                    <b>Battery Usage</b>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="table-responsive">
                        <table
                            className="table table-bordered text-nowrap border-bottom"
                            border={1}
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Battery Id</th>
                                    <th>Battery Charging Status</th>
                                    <th>Battery Firmware Version</th>
                                    <th>Battery Cell Voltage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {historyData.history.map((data, key) => (
                                    <tr key={key}>
                                        {console.log("Safa", VehicleDatatable)}
                                        <td>{moment(data.start_date).format("DD MMM YYYY")}</td>
                                        <td>{moment(data.start_date).format("DD MMM YYYY")}</td>
                                        <td>{data.battery_id}</td>
                                        <td>{data.battery?.battery_charging_state}</td>
                                        <td>{data.battery?.battery_firmware_version}</td>
                                        <td>{data.battery?.battery_cell_voltage}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <Row style={{ padding: "10px 0px" }}>
                <Col xs={6}>
                    <b>Vehicle Service History</b>
                    <br />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="table-responsive">
                        <table
                            className="table table-bordered text-nowrap border-bottom"
                            border={1}
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th>Service Date</th>
                                    <th>Service Type </th>
                                    <th>Service Location</th>
                                    <th>Mechanic ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleRideHistory.map((data, key) => (
                                    <tr key={key}>
                                        <td>
                                            {moment(data.start_date).format("DD MMM YYYY hh:mm a")}
                                        </td>
                                        <td>
                                            {moment(data.end_date).format("DD MMM YYYY hh:mm a")}
                                        </td>
                                        <td>{data.from}</td>
                                        <td>{data.to}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </>
    )
}

const ModalContent = React.memo(ModalContentGeneral)

export { ModalContent }

function ModalContentAsset({ data, meta }) {
    const [vehicleRideHistory, setRideHistory] = useState([])
    const location = useLocation()

    const [historyData, setHistoryData] = useState({
        type: "vehicle_history",
        data: [],
        history: [],
    })

    const [tableData, setData] = useState({
        vehicleInspectedList: [],
        serviceReminderList: [],
        operationsReportList: [],
        issuesList: [],
        workOrderList: [],
    })

    useEffect(() => {
        const vehicleInspectionData = InspectionData.data.filter(
            (inspection) => inspection.submitted_by == data.name,
        )
        const serviceReminderData = ServiceWithMetaData.data.filter(
            (serviceReminder) => serviceReminder.sent_to == data.name,
        )
        const issuesData = IssuesDatatable.data.filter((issue) => issue.submitted_by == data.name)
        const workOrderData = WorkOrderDatatable.data.filter(
            (issue) => issue.submitted_by == data.name,
        )

        const vehicleRideHistory = allSimulationData.filter(
            (simulation) => simulation.rider == data.name,
        )
        // console.log('vehicleRideHistory: ', data.name);
        setRideHistory([...vehicleRideHistory])

        setData({
            ...tableData,
            vehicleInspectedList: vehicleInspectionData,
            serviceReminderList: serviceReminderData,
            issuesList: issuesData,
            workOrderList: workOrderData,
        })
    }, [data])

    const paths = []

    // if(['/maintenance/','/maintenance'].includes(location.pathname)){
    //   paths=['service', 'all']
    // }else{
    //   paths=['rider']
    // }

    return (
        <>
            {!["/maintenance/", "/maintenance"].includes(location.pathname) && (
                <Row style={{ padding: "10px 0px" }}>
                    <Col xs={6}>
                        <b>Name: </b>
                        {data.name}
                    </Col>
                    <Col xs={6}>
                        <b>Email: </b>
                        {data.email}
                    </Col>
                    <Col xs={6}>
                        <b>Contact Number: </b>
                        {data.contact_number}
                    </Col>
                    <Col xs={6}>
                        <b>Total Rides: </b>
                        {data.total_rides}
                    </Col>
                    <Col xs={6}>
                        <b>Distance Travelled: </b>
                        {data.distance_travelled}
                    </Col>
                    <Col xs={6}>
                        <b>Distance Travelled this week: </b>
                        {data.distance_travelled_this_week}
                    </Col>
                    <Col xs={6}>
                        <b>Stationary VS moving time % : </b>
                        {data.stationary_moving_time}
                    </Col>
                    <Col xs={6}>
                        <b>Average speed: </b>
                        {data.average_speed}
                    </Col>
                    <Col style={{ padding: "20px" }} xs={6}>
                        <b>Rider History</b>
                        <br />
                    </Col>

                    <Col xs={12}>
                        <div className="table-responsive">
                            <table
                                className="table table-bordered text-nowrap border-bottom"
                                border={1}
                                width="100%"
                            >
                                <thead>
                                    <tr>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>From</th>
                                        <th>To</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vehicleRideHistory.map((data, key) => (
                                        <tr key={key}>
                                            <td>{moment(data.start_date).format("DD MMM YYYY")}</td>
                                            <td>{moment(data.start_date).format("DD MMM YYYY")}</td>
                                            <td>{data.from}</td>
                                            <td>{data.to}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            )}

            {["/maintenance/", "/maintenance"].includes(location.pathname) && (
                <>
                    <Row style={{ padding: "10px 0px" }}>
                        <Col xs={6}>
                            <b>Total Vehicle: </b>
                            {VehicleDatatable.data.length}
                        </Col>
                        <Col xs={6}>
                            <b>Total Services: </b>
                            {InspectionData.data.length}
                        </Col>
                        <Col xs={6}>
                            <b>Total Operations: </b>
                            {OperatorReportDatatable.data.length}
                        </Col>
                        <Col xs={6}>
                            <b>Total Issues: </b>
                            {IssuesDatatable.data.length}
                        </Col>
                        <Col xs={6}>
                            <b>Work Orders: </b>
                            {WorkOrderDatatable.data.length}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <b>Vehicle Inspected List</b>
                        </Col>
                        <Col style={{ marginTop: "10px" }} xs={12}>
                            <div className="table-responsive">
                                <table
                                    className="table table-bordered text-nowrap border-bottom"
                                    border={1}
                                    width="100%"
                                >
                                    <thead>
                                        <tr>
                                            <th>VEHICLE ID</th>
                                            <th>DATE</th>
                                            <th>DURATION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.vehicleInspectedList.map(
                                            (inspectionData, index) => (
                                                <tr key={index}>
                                                    <td>{inspectionData.vehicle_id}</td>
                                                    <td>{inspectionData.started_at}</td>
                                                    <td>{inspectionData.duration}</td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <b>Service Reminder List</b>
                        </Col>
                        <Col style={{ marginTop: "10px" }} xs={12}>
                            <div className="table-responsive">
                                <table
                                    className="table table-bordered text-nowrap border-bottom"
                                    border={1}
                                    width="100%"
                                >
                                    <thead>
                                        <tr>
                                            <th>Issue</th>
                                            <th>Status</th>
                                            <th>Vehicle ID</th>
                                            <th>DATE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.serviceReminderList.map(
                                            (serviceReminder, index) => (
                                                <tr key={index}>
                                                    <td>{serviceReminder.description}</td>
                                                    <td>{serviceReminder.status}</td>
                                                    <td>{serviceReminder.vehicle_id}</td>
                                                    <td>{serviceReminder.created_at}</td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <b>Operation Report List</b>
                        </Col>
                        <Col style={{ marginTop: "10px" }} xs={12}>
                            <div className="table-responsive">
                                <table
                                    className="table table-bordered text-nowrap border-bottom"
                                    border={1}
                                    width="100%"
                                >
                                    <thead>
                                        <tr>
                                            <th>ISSUE</th>
                                            <th>VEHICLE ID</th>
                                            <th>DATE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.vehicleInspectedList.map(
                                            (inspectionData, index) => (
                                                <tr key={index}>
                                                    <td>{inspectionData.vehicle_id}</td>
                                                    <td>{inspectionData.started_at}</td>
                                                    <td>{inspectionData.duration}</td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <b>Issues List</b>
                        </Col>
                        <Col style={{ marginTop: "10px" }} xs={12}>
                            <div className="table-responsive">
                                <table
                                    className="table table-bordered text-nowrap border-bottom"
                                    border={1}
                                    width="100%"
                                >
                                    <thead>
                                        <tr>
                                            <th>ISSUE</th>
                                            <th>VEHICLE ID</th>
                                            <th>DATE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.issuesList.map((issue, index) => (
                                            <tr key={index}>
                                                <td>{issue.description}</td>
                                                <td>{issue.vehicle_id}</td>
                                                <td>{issue.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <b>Work Order List</b>
                        </Col>
                        <Col style={{ marginTop: "10px" }} xs={12}>
                            <div className="table-responsive">
                                <table
                                    className="table table-bordered text-nowrap border-bottom"
                                    border={1}
                                    width="100%"
                                >
                                    <thead>
                                        <tr>
                                            <th>ISSUE</th>
                                            <th>VEHICLE ID</th>
                                            <th>DATE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.workOrderList.map((workOrder, index) => (
                                            <tr key={index}>
                                                <td>{workOrder.description}</td>
                                                <td>{workOrder.vehicle_id}</td>
                                                <td>{workOrder.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export { ModalContentAsset }

export function OpenRiderDetails({ data }) {
    const [historyData, setHistoryData] = useState({
        type: "vehicle_history",
        data: [],
        history: [],
    })

    return (
        <>
            <Row style={{ padding: "10px 0px" }}>
                <Col xs={6}>
                    <b>Name: </b>
                    {data.name}
                </Col>
                <Col xs={6}>
                    <b>Email: </b>
                    {data.email}
                </Col>
                <Col xs={6}>
                    <b>Contact Number: </b>
                    {data.contact_number}
                </Col>
                <Col xs={6}>
                    <b>Total Rides: </b>
                    {data.total_rides}
                </Col>
            </Row>
        </>
    )
}

export function BatteryDetails({ data }) {
    const [historyData, setHistoryData] = useState({
        type: "vehicle_history",
        data: [],
        history: [],
    })

    useEffect(() => {
        const history = vehicleBatteryHistory.filter(
            (vbData) => vbData.battery_id == data.battery_id,
        )
        const vehicles = history.map((_history) => _history.vehicle_id)
        const batteryData = VehicleDatatable.data.filter((vehicleData) =>
            vehicles.includes(vehicleData.vehicle_id),
        )

        const singleBatteryData = batteryData[0]

        setHistoryData({
            id: data.battery_id,
            type: "battery_history",
            data: [...batteryData],
            history: history.map((data) => ({
                ...data,
                vehicle: singleBatteryData,
            })),
        })
    }, [])

    return (
        <>
            <Row>
                <Col xs={6}>
                    <div>
                        <b>Number of Recharges: </b> {data.number_of_recharges}
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <b>Distance Travelled Since Last Recharge: </b>
                        {data.distance_since_last_recharge}
                    </div>
                </Col>
            </Row>
            <br />
            <div className="table-responsive">
                <table
                    className="table table-bordered text-nowrap border-bottom"
                    border={1}
                    width="100%"
                >
                    <thead>
                        <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Vehicle ID</th>
                            <th>Battery Id</th>
                            <th>Battery Charging Status</th>
                            <th>Battery Firmware Version</th>
                            <th>Battery Cell Voltage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyData.history.map((data, key) => (
                            <tr key={key}>
                                <td>{moment(data?.start_date).format("DD MMM YYYY")}</td>
                                <td>{moment(data?.start_date).format("DD MMM YYYY")}</td>
                                <td>{data?.vehicle_id}</td>
                                <td>{data?.vehicle?.battery_charging_state}</td>
                                <td>{data?.vehicle?.battery_charging_state}</td>
                                <td>{data?.vehicle?.battery_firmware_version}</td>
                                <td>{data?.vehicle?.battery_cell_voltage}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export function TripDetails({ data, meta: columnMetaInformation }) {
    const dispatch = useDispatch()
    const tripData = useSelector(selectGeneralApiData({ key: "trip_by_id" }))
    const tripRoute = useSelector(selectGeneralApiData({ key: "trip_route" }))
    const mapRef = useRef()

    useEffect(() => {
        dispatch(
            generalApiCall({
                endpoint: `api/v1/trip/summary?trip_id=${data.id}`,
                params: {},
                method: "GET",
                key: "trip_by_id",
            }),
        )

        dispatch(
            mapRouteWaypointApiCall({
                endpoint: `api/v1/trip/route?trip_id=${data.id}`,
                params: {},
                method: "GET",
                key: "trip_route",
            }),
        )

        return () => {
            dispatch(
                setMapWaypointResponse({
                    key: "trip_route",
                    data: undefined,
                }),
            )

            dispatch(
                setResponse({
                    key: "trip_by_id",
                    data: undefined,
                }),
            )
        }
    }, [])

    useEffect(() => {
        let waypoints = []

        if (tripRoute !== undefined && tripData !== undefined) {
            waypoints = tripRoute.map((waypoints) => ({
                location: new window.google.maps.LatLng(waypoints.latitude, waypoints.longitude),
                stopover: false,
            }))

            mapRef.current.drawRoute({
                to: {
                    lat: tripData.pickup_from_lat,
                    lng: tripData.pickup_from_long,
                },
                destination: {
                    lat: tripData.drop_at_lat,
                    lng: tripData.drop_at_long,
                },
                waypoints,
            })
        }
    }, [tripRoute, tripData, mapRef])

    const {
        customer_name,
        trip_from,
        trip_to,
        started_at,
        ended_at,
        vehicle_type,
        total_fare,
        carbon_saved,
        travelled_time,
        total_locked_time,
        total_unlocked_time,
    } = tripData || {}

    const configurations = useMemo(
        () => ({
            center: {
                latitude: 24.57127,
                longitude: 73.691544,
            },
        }),
        [],
    )

    return (
        <>
            <JMap ref={mapRef} config={configurations} />
            <Card className="mb-3">
                <Card.Body>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Customer:</strong>
                        </Col>
                        <Col md={6}>{customer_name}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>From:</strong>
                        </Col>
                        <Col md={6}>{trip_from}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>To:</strong>
                        </Col>
                        <Col md={6}>{trip_to}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Started at:</strong>
                        </Col>
                        <Col md={6}>{convertUTCToLocal(started_at).time}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Ended at:</strong>
                        </Col>
                        <Col md={6}>{convertUTCToLocal(ended_at).time}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Vehicle Type:</strong>
                        </Col>
                        <Col md={6}>{vehicle_type}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Total Fare:</strong>
                        </Col>
                        <Col md={6}>{total_fare}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Carbon Saved:</strong>
                        </Col>
                        <Col md={6}>{carbon_saved}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Travelled Time:</strong>
                        </Col>
                        <Col md={6}>{travelled_time}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Total Locked Time:</strong>
                        </Col>
                        <Col md={6}>{total_locked_time}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Total Unlocked Time:</strong>
                        </Col>
                        <Col md={6}>{total_unlocked_time}</Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export function CustomerDetails({ data, meta: columnMetaInformation }) {
    const dispatch = useDispatch()
    const customerData = useSelector(selectGeneralApiData({ key: "customer_details_by_id" }))

    useEffect(() => {
        dispatch(
            generalApiCall({
                endpoint: `api/v1/customer/details?customer_id=${data.customer_id}`,
                params: {},
                method: "GET",
                key: "customer_details_by_id",
            }),
        )
    }, [])

    const {
        name,
        country_phonecode,
        mobile_number,
        email,
        email_verified_at,
        mobile_verified_at,
        rides,
        created_at,
        updated_at,
    } = customerData || {}

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Customer:</strong>
                        </Col>
                        <Col md={6}>{name}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Country Phonecode:</strong>
                        </Col>
                        <Col md={6}>{country_phonecode}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Mobile Number:</strong>
                        </Col>
                        <Col md={6}>{mobile_number}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Mobile Verified At:</strong>
                        </Col>
                        <Col md={6}>
                            {mobile_verified_at ? convertUTCToLocal(mobile_verified_at)?.time : ""}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Email:</strong>
                        </Col>
                        <Col md={6}>{email}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Email Verified At:</strong>
                        </Col>
                        <Col md={6}>
                            {email_verified_at ? convertUTCToLocal(email_verified_at)?.time : ""}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Rides:</strong>
                        </Col>
                        <Col md={6}>{rides}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Created At:</strong>
                        </Col>
                        <Col md={6}>{created_at ? convertUTCToLocal(created_at)?.time : ""}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <strong>Updated At:</strong>
                        </Col>
                        <Col md={6}>{updated_at ? convertUTCToLocal(updated_at)?.time : ""}</Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export function DeviceDetails({ data, meta: columnMetaInformation }) {
    const dispatch = useDispatch()
    const { setHeading } = useDatatableCtx()

    const module = "iot_history"
    const moduleData = useSelector(selectModuleSpecificData(module))
    const metaData = useSelector(selectMetaInformation)

    const columns = React.useMemo(
        () =>
            computeColumns({
                data: moduleData,
                meta: metaData,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [moduleData],
    )

    const datatableData = React.useMemo(() => moduleData, [moduleData])

    const [displayToggle, setDisplayToggle] = useState(false)

    useEffect(() => {
        console.log("Sfad", 2)
        setDisplayToggle(false)
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        setHeading("Device History")
        dispatch(
            fetchData({
                module: "iot_history",
                iot_id: data[columnMetaInformation.key],
            }),
        )
        return () => {
            console.log("Component unmounted")
            setDisplayToggle(true)
        }
    }, [])

    const firstColumn = useMemo(
        () => (moduleData && moduleData.length > 0 ? moduleData[0] : {}),
        [moduleData],
    )

    return (
        <>
            <Row>
                <Col
                    // className="scroll"
                    style={{ marginTop: "10px", maxHeight: "900px", padding: "0px" }}
                    xs={12}
                >
                    {moduleData.length > 0 ? (
                        <Datatable
                            filteredResult={moduleData}
                            refreshAction={() => {
                                dispatch(fetchData({ module }))
                            }}
                            datatableTitle="Device History"
                            displayToggle={displayToggle}
                            style={{ height: "340px", overflowY: "scroll" }}
                            page_name="iot_history"
                            id="iot_history_datatable"
                            isMultiSelect={false}
                            rowSelected={(selection) => {
                                // console.log(selection);
                            }}
                            // actions={[{ name: 'Send Instruction', type: 'send_instruction' }]}
                            // modalHeading="Device History"
                            // modalStatus={status}
                            // modalContent={ModalContentMemonized}
                            // handleModalStatus={setModalStatus}
                            columns={columns}
                            data={datatableData}
                            // onActionClick={(event, { type }) => {
                            //   if (type == 'send_instruction') {
                            //     setInstructionPanelStatus(true);
                            //   }
                            // }}
                        />
                    ) : (
                        <div className="text-center">Loading...</div>
                    )}
                </Col>
            </Row>
        </>
    )
}

// export function DeviceDetails({ data }) {
//   const dispatch = useDispatch();
//   const { setHeading } = useDatatableCtx();

//   let module = 'iot_gps_history';
//   const moduleData = useSelector(selectModuleSpecificData(module));
//   useEffect(() => {
//     if (!initialState.hasOwnProperty(module)) {
//       dispatch(
//         addPage({
//           key: module,
//           data: initialState.vehicles,
//         })
//       );
//     }

//     setHeading('Device History');
//     dispatch(
//       fetchData({ module: 'iot_gps_history', device_id: data.device_id })
//     );
//   }, []);

//   let firstColumn = useMemo(() => {
//     return (moduleData && moduleData.length > 0) ? moduleData[0] : {};
//   }, [moduleData]);

//   return (
//     <>
//       <Row>
//         <Col style={{ marginTop: '10px' }} xs={12}>
//           <div className="table-responsive" style={{ height: '400px' }}>
//             {(moduleData && moduleData.length > 0) && (
//               <>
//                 <table
//                   className="table table-bordered text-nowrap border-bottom"
//                   border={1}
//                   height="30%"
//                   width="100%"
//                 >
//                   <thead>
//                     <tr>
//                       {Object.keys(firstColumn).map((data) => (
//                         <th>{data}</th>
//                       ))}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {moduleData.map((data, index) => {
//                       return (
//                         <tr key={index}>
//                           <td>{data?.vehicle_id}</td>
//                           <td>{data?.imei}</td>
//                           <td>{data?.mac_addresss}</td>
//                           <td>{data?.device_id}</td>
//                           <td>{data?.received_ts}</td>
//                           <td>{data?.latitude}</td>
//                           <td>{data?.longitude}</td>
//                           <td>{data?.last_location}</td>
//                           <td>{data?.last_located_at}</td>
//                           <td>{data?.ignition}</td>
//                           <td>{data?.motion_status}</td>
//                           <td>
//                             {moment(data?.created_at).format('DD-MMM-YYYY')}
//                           </td>

//                           <td>
//                             {moment(data?.updated_at).format('DD-MMM-YYYY')}
//                           </td>
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                 </table>
//               </>
//             )}
//           </div>
//         </Col>
//       </Row>
//     </>
//   );
// }

export function ServiceDetails({ data }) {
    const [tableData, setData] = useState([])

    useEffect(() => {
        const serviceActionHistory = serviceIdActions.filter(
            (service) => service.service_id == data.service_id,
        )
        setData([...serviceActionHistory])
    }, [])

    return (
        <>
            <Row>
                <Col style={{ marginTop: "10px" }} xs={12}>
                    <div className="table-responsive">
                        <table
                            className="table table-bordered text-nowrap border-bottom"
                            border={1}
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th>SERVICE ID</th>
                                    <th>VEHICLE ID</th>
                                    <th>SERVICE POINT</th>
                                    <th>STATUS</th>
                                    <th>DESCRIPTION</th>
                                    <th>NAME</th>
                                    <th>DATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((serviceReminder, index) => (
                                    <tr key={index}>
                                        <td>{serviceReminder?.service_id}</td>
                                        <td>{data?.vehicle_id}</td>
                                        <td>{serviceReminder?.service_point}</td>
                                        <td>{serviceReminder?.status}</td>
                                        <td>{serviceReminder?.description}</td>
                                        <td>{serviceReminder?.name}</td>
                                        <td>
                                            {moment(serviceReminder?.date).format("DD-MMM-YYYY")}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            {/* <Row>
        <Col xs={12}>
          <b>Service Reminder List</b>
        </Col>
        <Col style={{ marginTop: '10px' }} xs={12}>
          <div className="table-responsive">
            <table
              className="table table-bordered text-nowrap border-bottom"
              border={1}
              width="100%"
            >
              <thead>
                <tr>
                  <th>Sent To</th>
                  <th>Status</th>
                  <th>Vehicle ID</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                {tableData.serviceReminderList.map((serviceReminder, index) => {
                  return (
                    <tr key={index}>
                      <td>{serviceReminder.sent_to}</td>
                      <td>{serviceReminder.status}</td>
                      <td>{serviceReminder.vehicle_id}</td>
                      <td>{serviceReminder.created_at}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={12}>
          <b>Operation Report List</b>
        </Col>
        <Col style={{ marginTop: '10px' }} xs={12}>
          <div className="table-responsive">
            <table
              className="table table-bordered text-nowrap border-bottom"
              border={1}
              width="100%"
            >
              <thead>
                <tr>
                  <th>ISSUE</th>
                  <th>VEHICLE ID</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                {tableData.operationsReportList.map((inspectionData, index) => {
                  return (
                    <tr key={index}>
                      <td>{inspectionData.description}</td>
                      <td>{inspectionData.vehicle_id}</td>
                      <td>{inspectionData.created_at}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={12}>
          <b>Vehicle Inspection List</b>
        </Col>
        <Col style={{ marginTop: '10px' }} xs={12}>
          <div className="table-responsive">
            <table
              className="table table-bordered text-nowrap border-bottom"
              border={1}
              width="100%"
            >
              <thead>
                <tr>
                  <th>ISSUE ID</th>
                  <th>DATE</th>
                  <th>DURATION</th>
                </tr>
              </thead>
              <tbody>
                {tableData.vehicleInspectedList.map((inspectionData) => {
                  return (
                    <tr>
                      <td>{inspectionData.description}</td>
                      <td>{inspectionData.started_at}</td>
                      <td>{inspectionData.duration}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={12}>
          <b>Issues List</b>
        </Col>
        <Col style={{ marginTop: '10px' }} xs={12}>
          <div className="table-responsive">
            <table
              className="table table-bordered text-nowrap border-bottom"
              border={1}
              width="100%"
            >
              <thead>
                <tr>
                  <th>ISSUE</th>
                  <th>VEHICLE ID</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                {tableData.issuesList.map((issue, index) => {
                  return (
                    <tr key={index}>
                      <td>{issue.description}</td>
                      <td>{issue.vehicle_id}</td>
                      <td>{issue.created_at}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={12}>
          <b>Work Order List</b>
        </Col>
        <Col style={{ marginTop: '10px' }} xs={12}>
          <div className="table-responsive">
            <table
              className="table table-bordered text-nowrap border-bottom"
              border={1}
              width="100%"
            >
              <thead>
                <tr>
                  <th>ISSUE</th>
                  <th>VEHICLE ID</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                {tableData.workOrderList.map((workOrder, index) => {
                  return (
                    <tr key={index}>
                      <td>{workOrder.description}</td>
                      <td>{workOrder.vehicle_id}</td>
                      <td>{workOrder.created_at}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row> */}
        </>
    )
}

export function ServicePersonDetails({ data }) {
    const riderData = RidersData.data.find((rider) => rider.name == data.submitted_by)

    const [tableData, setData] = useState({
        vehicleInspectedList: [],
        serviceReminderList: [],
        operationsReportList: [],
        issuesList: [],
        workOrderList: [],
    })

    useEffect(() => {
        const vehicleInspectionData = InspectionData.data.filter(
            (inspection) => inspection.submitted_by == data.submitted_by,
        )
        const serviceReminderData = ServiceWithMetaData.data.filter(
            (serviceReminder) => serviceReminder.sent_to == data.submitted_by,
        )
        const issuesData = IssuesDatatable.data.filter(
            (issue) => issue.submitted_by == data.submitted_by,
        )
        const workOrderData = WorkOrderDatatable.data.filter(
            (issue) => issue.submitted_by == data.name,
        )

        setData({
            ...tableData,
            vehicleInspectedList: vehicleInspectionData,
            serviceReminderList: serviceReminderData,
            issuesList: issuesData,
            workOrderList: workOrderData,
        })
    }, [data])

    return (
        <>
            <Row style={{ padding: "10px 0px" }}>
                <Col xs={6}>
                    <b>Name: </b>
                    {riderData?.name}
                </Col>
                <Col xs={6}>
                    <b>Email: </b>
                    {riderData?.email}
                </Col>
                <Col xs={6}>
                    <b>Contact Number: </b>
                    {riderData?.contact_number}
                </Col>
            </Row>
            <Row style={{ padding: "10px 0px" }}>
                <Col xs={6}>
                    <b>Total Vehicle: </b>
                    {VehicleDatatable.data.length}
                </Col>
                <Col xs={6}>
                    <b>Total Services: </b>
                    {InspectionData.data.length}
                </Col>
                <Col xs={6}>
                    <b>Total Operations: </b>
                    {OperatorReportDatatable.data.length}
                </Col>
                <Col xs={6}>
                    <b>Total Issues: </b>
                    {IssuesDatatable.data.length}
                </Col>
                <Col xs={6}>
                    <b>Work Orders: </b>
                    {WorkOrderDatatable.data.length}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <b>Vehicle Inspected List</b>
                </Col>
                <Col style={{ marginTop: "10px" }} xs={12}>
                    <div className="table-responsive">
                        <table
                            className="table table-bordered text-nowrap border-bottom"
                            border={1}
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th>VEHICLE ID</th>
                                    <th>DATE</th>
                                    <th>DURATION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.vehicleInspectedList.map((inspectionData, _index) => (
                                    <tr key={`TABLE${_index}`}>
                                        <td>{inspectionData.vehicle_id}</td>
                                        <td>{inspectionData.started_at}</td>
                                        <td>{inspectionData.duration}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
                <Col xs={12}>
                    <b>Service Reminder List</b>
                </Col>
                <Col style={{ marginTop: "10px" }} xs={12}>
                    <div className="table-responsive">
                        <table
                            className="table table-bordered text-nowrap border-bottom"
                            border={1}
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th>Issue</th>
                                    <th>Status</th>
                                    <th>Vehicle ID</th>
                                    <th>DATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.serviceReminderList.map((serviceReminder, index) => (
                                    <tr key={index}>
                                        <td>{serviceReminder.description}</td>
                                        <td>{serviceReminder.status}</td>
                                        <td>{serviceReminder.vehicle_id}</td>
                                        <td>{serviceReminder.created_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
                <Col xs={12}>
                    <b>Operation Report List</b>
                </Col>
                <Col style={{ marginTop: "10px" }} xs={12}>
                    <div className="table-responsive">
                        <table
                            className="table table-bordered text-nowrap border-bottom"
                            border={1}
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th>ISSUE</th>
                                    <th>VEHICLE ID</th>
                                    <th>DATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.vehicleInspectedList.map((inspectionData, index) => (
                                    <tr key={index}>
                                        <td>{inspectionData.vehicle_id}</td>
                                        <td>{inspectionData.started_at}</td>
                                        <td>{inspectionData.duration}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
                <Col xs={12}>
                    <b>Issues List</b>
                </Col>
                <Col style={{ marginTop: "10px" }} xs={12}>
                    <div className="table-responsive">
                        <table
                            className="table table-bordered text-nowrap border-bottom"
                            border={1}
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th>ISSUE</th>
                                    <th>VEHICLE ID</th>
                                    <th>DATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.issuesList.map((issue, index) => (
                                    <tr key={index}>
                                        <td>{issue.description}</td>
                                        <td>{issue.vehicle_id}</td>
                                        <td>{issue.created_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
                <Col xs={12}>
                    <b>Work Order List</b>
                </Col>
                <Col style={{ marginTop: "10px" }} xs={12}>
                    <div className="table-responsive">
                        <table
                            className="table table-bordered text-nowrap border-bottom"
                            border={1}
                            width="100%"
                        >
                            <thead>
                                <tr>
                                    <th>ISSUE</th>
                                    <th>VEHICLE ID</th>
                                    <th>DATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.workOrderList.map((workOrder, index) => (
                                    <tr key={index}>
                                        <td>{workOrder.description}</td>
                                        <td>{workOrder.vehicle_id}</td>
                                        <td>{workOrder.created_at}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </>
    )
}
