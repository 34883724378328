import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt"
import Modal from "../../components/Modal"
import { Grid, MenuItem, TextField } from "@mui/material"
import { unlockDevice } from "../../redux/iot/actions"
import { useDispatch } from "react-redux"
import {
    addPage,
    fetchData,
    saveRoute,
    setFilteredData,
    setModule,
} from "../../redux/templateData/actions"
import { useLocation } from "react-router-dom"

const VehicleSummaryPopup = ({ data, objectForm, routeModify, onClose, isShowExtraInfo }) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const [lockDialogConfirmationStatus, setLockDialogConfirmationStatus] = useState(false)

    useEffect(() => {
        console.log("SUMMRY DATA:", data)
    }, [data])
    function getValueByKey(key) {
        for (let i = 0; i < data.length; i++) {
            if (data[i][0] === key) {
                return data[i][1]
            }
        }
        return null
    }

    return (
        <div className="summaryPopUp" style={{ width: "26rem", minHeight: "23.5rem" }}>
            <div className="header bin__summary-header">Vehicle Information</div>
            <div className="d-flex mt-4 align-items-start">
                <div className="progress-view">
                    {/* <div className="percent progress-one">
                        <svg width="120" height="120">
                            <circle cx="60" cy="60" r="55"></circle>
                            <circle
                                cx="60"
                                cy="60"
                                r="55"
                                style={{
                                    strokeDasharray: "345px",
                                    strokeDashoffset: `calc(345 - (345 * ${getValueByKey("percent_full")}) / 100)`,
                                }}
                            ></circle>
                        </svg>
                        <div className="number">
                            <span className="value">
                                {getValueByKey("percent_full")}
                                <span>%</span>
                            </span>
                            <span className="title">Fill Level</span>
                        </div>
                    </div> */}
                    <div className="percent progress-two">
                        <svg width="120" height="120">
                            <circle cx="60" cy="60" r="55"></circle>
                            <circle
                                cx="60"
                                cy="60"
                                r="55"
                                style={{
                                    strokeDasharray: "345px",
                                    strokeDashoffset: `calc(345 - (345 * ${getValueByKey("battery_level_percentage") != null ? getValueByKey("battery_level_percentage") : 0}) / 100)`,
                                }}
                            ></circle>
                        </svg>
                        <div className="number">
                            <span className="value">
                                {getValueByKey("battery_level")}
                                {console.log("saghj", getValueByKey("battery_level_percentage"))}
                                <span>
                                    {typeof getValueByKey("battery_level_percentage") ===
                                        "number" &&
                                    getValueByKey("battery_level_percentage") % 1 !== 0
                                        ? (
                                              (getValueByKey("battery_level_percentage") / 100) *
                                              100
                                          ).toFixed(2)
                                        : getValueByKey("battery_level_percentage")}
                                    %
                                </span>
                            </span>
                            <span className="title text-center">Battery Level</span>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <table className="table table-striped p-2">
                        <tbody>
                            <tr>
                                <td className="fw-normal text-capitalize">Vehicle Caegory</td>
                                <td className="text-end">{getValueByKey("vehicle_type")}</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Vehicle Model</td>
                                <td className="text-end">{getValueByKey("vehicle_type")}</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Current Status</td>
                                <td className="text-end">{getValueByKey("current_status")}</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Current Location</td>
                                <td className="text-end">{getValueByKey("current_location")}</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Lock Status</td>
                                <td className="text-end">{getValueByKey("status")}</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">
                                    New Maintainenace Date
                                </td>
                                <td className="text-end">
                                    {new Date().toLocaleDateString("en-US")}
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Lease Expiry Date</td>
                                <td className="text-end">
                                    {new Date().toLocaleDateString("en-US")}
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">GNSS Signal Strength</td>
                                <td className="text-end singalIcon">
                                    {" "}
                                    <SignalCellularAltIcon />
                                </td>
                            </tr>

                            {/* <tr>
                            <td className="fw-normal text-capitalize">IOT Name</td>
                            <td className="text-end">{getValueByKey("iot_name")}</td>
                        </tr>
                        <tr>
                            <td className="fw-normal text-capitalize">Number of rides</td>
                            <td className="text-end">{getValueByKey("number_of_rides")}</td>
                        </tr>
                        <tr>
                            <td className="fw-normal text-capitalize">Current Location</td>
                            <td className="text-end">{getValueByKey("current_location")}</td>
                        </tr>
                        <tr>
                            <td className="fw-normal text-capitalize">Current Status</td>
                            <td className="text-end">{getValueByKey("current_status")}</td>
                        </tr>
                        <tr>
                            <td className="fw-normal text-capitalize">Status</td>
                            <td className="text-end">{getValueByKey("status")}</td>
                        </tr>
                        <tr>
                            <td className="fw-normal text-capitalize">Vehicle Type</td>
                            <td className="text-end">{getValueByKey("vehicle_type")}</td>
                        </tr>
                        <tr>
                            <td className="fw-normal text-capitalize">Distance Travelled</td>
                            <td className="text-end">{getValueByKey("distance_travelled")}</td>
                        </tr>
                        <tr>
                            <td className="fw-normal text-capitalize">Different Riders</td>
                            <td className="text-end">
                                {getValueByKey("number_of_different_riders")}
                            </td>
                        </tr> */}
                        </tbody>
                    </table>
                    {location.pathname !== "/routing" && (
                        <div className="p-2 pt-1 d-flex flex-column" style={{ gap: "10px" }}>
                            {getValueByKey("status") === "Locked" && (
                                <Button
                                    style={{ flex: 1 }}
                                    className="btn btn-secondary "
                                    onClick={() => setLockDialogConfirmationStatus(true)}
                                >
                                    Unlock
                                </Button>
                            )}
                            {isShowExtraInfo && (
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        routeModify(objectForm)
                                    }}
                                    style={{ flex: 1 }}
                                >
                                    {objectForm.is_ghost ? "Add to Route" : "Remove from Route"}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
                <Modal
                    fullWidth
                    maxWidth="sm"
                    onClose={() => setLockDialogConfirmationStatus(false)}
                    modalHeading="Confirm"
                    status={lockDialogConfirmationStatus}
                    onSave={() => {
                        dispatch(
                            unlockDevice({
                                imei: getValueByKey("imei"),

                                // module:""
                            }),
                        )
                        onClose()
                        dispatch(fetchData({ module: "iot" }))
                        setLockDialogConfirmationStatus(false)
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            Are you sure want to unlock this IOT?
                        </Grid>
                    </Grid>
                </Modal>
            </div>
        </div>
    )
}

export default VehicleSummaryPopup
