import { useCallback, useEffect, useState } from "react"
import { GoogleMap, MarkerF, useJsApiLoader, MarkerClusterer } from "@react-google-maps/api"
import { Button } from "react-bootstrap"
import { Assets } from "../../assets"

const containerStyle = {
    width: "100%",
    height: "600px",
}

function MapsData() {
    const [map, setMap] = useState(null)

    const { isLoaded } = useJsApiLoader({
        id: "drawing-manager-example",
        googleMapsApiKey: "AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac",
        libraries: ["drawing"],
    })

    const onLoad = useCallback((map) => {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        setMap(map)
    }, [])

    const onUnmount = useCallback((map) => {
        setMap(null)
    }, [])

    const [markers, setMarkers] = useState([
        {
            station_id: 2,
            location: "Dundalk",
            latitude: 53.997551144596116,
            longitude: -6.406638667506018,
            type: "pickup",
            storage_type: "kerosene",
        },
        {
            station_id: 1,
            location: "Limerick",
            latitude: 52.6627282600906,
            longitude: -8.625207230763863,
            type: "pickup",
            storage_type: "water",
        },
        {
            id: 1,
            device_id: "862315061920293",
            percent_full: 100,
            api_trigger_level_percent: 30,
            substance: "LPG",
            last_contact: "Tue, 19 Dec 2023 08:30:24 GMT",
            latitude: 52.7063959072377,
            longitude: -8.899600977242905,
            estimated_runout_days: null,
            amount_to_fill_litres: 0,
            remaining_fuel_litres: 1000,
            ullage: null,
            type: "container",
            created_at: "2023-11-30 04:32:07.276397",
            updated_at: "2023-12-19 10:10:15.064625",
        },
        {
            id: 4,
            device_id: "862315069676244",
            percent_full: 78,
            api_trigger_level_percent: 30,
            substance: "RedDiesel",
            last_contact: "Tue, 19 Dec 2023 08:00:15 GMT",
            latitude: 52.7063959072377,
            longitude: -8.899600977242905,
            estimated_runout_days: 2,
            amount_to_fill_litres: 401,
            remaining_fuel_litres: 1454,
            ullage: 54,
            type: "container",
            created_at: "2023-11-30 04:32:13.811272",
            updated_at: "2023-12-19 10:10:20.232111",
        },
        {
            id: 5,
            device_id: "868326028641728",
            percent_full: 82,
            api_trigger_level_percent: 30,
            substance: "Kerosene",
            last_contact: "Tue, 19 Dec 2023 07:07:23 GMT",
            latitude: 52.70633890488845,
            longitude: -8.899918688506567,
            estimated_runout_days: 365,
            amount_to_fill_litres: 415,
            remaining_fuel_litres: 1960,
            ullage: 29,
            type: "container",
            created_at: "2023-11-30 04:32:15.078865",
            updated_at: "2023-12-19 10:10:22.819184",
        },
        {
            id: 6,
            device_id: "1B3364",
            percent_full: 44,
            api_trigger_level_percent: 30,
            substance: "Kerosene",
            last_contact: "Tue, 19 Dec 2023 09:34:52 GMT",
            latitude: 53.999366,
            longitude: -6.396571,
            estimated_runout_days: 311,
            amount_to_fill_litres: 666,
            remaining_fuel_litres: 606,
            ullage: 65,
            type: "container",
            created_at: "2023-11-30 04:32:16.352378",
            updated_at: "2023-12-19 10:10:02.148376",
        },
        {
            id: 7,
            device_id: "1B3365",
            percent_full: 33,
            api_trigger_level_percent: 30,
            substance: "Kerosene",
            last_contact: "Tue, 19 Dec 2023 09:27:10 GMT",
            latitude: 54.016359023121716,
            longitude: -6.31025327196983,
            estimated_runout_days: 238,
            amount_to_fill_litres: 760,
            remaining_fuel_litres: 422,
            ullage: 78,
            type: "container",
            created_at: "2023-11-30 04:32:17.674833",
            updated_at: "2023-12-19 10:10:04.749478",
        },
        {
            id: 8,
            device_id: "2077C4",
            percent_full: 50,
            api_trigger_level_percent: 30,
            substance: "GasOil",
            last_contact: "Tue, 19 Dec 2023 09:35:00 GMT",
            latitude: 54.057110084547205,
            longitude: -6.338769913482723,
            estimated_runout_days: 129,
            amount_to_fill_litres: 1299,
            remaining_fuel_litres: 1324,
            ullage: 69,
            type: "container",
            created_at: "2023-11-30 04:32:18.991166",
            updated_at: "2023-12-19 10:10:07.310088",
        },
        {
            id: 9,
            device_id: "2078C1",
            percent_full: 80,
            api_trigger_level_percent: 30,
            substance: "Kerosene",
            last_contact: "Tue, 19 Dec 2023 10:08:24 GMT",
            latitude: 54.01525504246823,
            longitude: -6.403153584135643,
            estimated_runout_days: 365,
            amount_to_fill_litres: 225,
            remaining_fuel_litres: 926,
            ullage: 32,
            type: "container",
            created_at: "2023-11-30 04:32:20.260420",
            updated_at: "2023-12-19 10:10:08.607917",
        },
        {
            id: 10,
            device_id: "29ED704",
            percent_full: 13,
            api_trigger_level_percent: 30,
            substance: "Kerosene",
            last_contact: "Tue, 19 Dec 2023 10:01:52 GMT",
            latitude: 52.71419896910701,
            longitude: -8.86484119418075,
            estimated_runout_days: 365,
            amount_to_fill_litres: 3757,
            remaining_fuel_litres: 719,
            ullage: 113,
            type: "container",
            created_at: "2023-11-30 04:32:21.551065",
            updated_at: "2023-12-19 10:10:09.970515",
        },
        {
            id: 11,
            device_id: "860147041814987",
            percent_full: 79,
            api_trigger_level_percent: 30,
            substance: "Water",
            last_contact: "Wed, 06 Dec 2023 07:09:39 GMT",
            latitude: 52.65791,
            longitude: -8.553266,
            estimated_runout_days: 14,
            amount_to_fill_litres: 15906,
            remaining_fuel_litres: 61933,
            ullage: 113,
            type: "container",
            created_at: "2023-11-30 04:32:22.860462",
            updated_at: "2023-12-19 10:10:11.249861",
        },
        {
            id: 12,
            device_id: "860536048877304",
            percent_full: 65,
            api_trigger_level_percent: 30,
            substance: "Water",
            last_contact: "Wed, 06 Dec 2023 08:11:17 GMT",
            latitude: 52.98096022835756,
            longitude: -8.6534539876045,
            estimated_runout_days: 44,
            amount_to_fill_litres: 2140350859,
            remaining_fuel_litres: 4175438561,
            ullage: 183,
            type: "container",
            created_at: "2023-11-30 04:32:24.127874",
            updated_at: "2023-12-19 10:10:12.541639",
        },
        {
            id: 13,
            device_id: "2077BC",
            percent_full: 35,
            api_trigger_level_percent: 30,
            substance: "Kerosene",
            last_contact: "Tue, 19 Dec 2023 09:23:15 GMT",
            latitude: null,
            longitude: null,
            estimated_runout_days: 84,
            amount_to_fill_litres: 491,
            remaining_fuel_litres: 295,
            ullage: 76,
            type: "container",
            created_at: "2023-12-11 17:10:04.517161",
            updated_at: "2023-12-19 10:10:06.003241",
        },
        {
            id: 14,
            device_id: "868326024290629",
            percent_full: 86,
            api_trigger_level_percent: 30,
            substance: "Water",
            last_contact: "Tue, 19 Dec 2023 07:06:17 GMT",
            latitude: 52.633453,
            longitude: -8.646241,
            estimated_runout_days: 280,
            amount_to_fill_litres: 658,
            remaining_fuel_litres: 4340,
            ullage: 37,
            type: "container",
            created_at: "2023-12-13 10:10:07.533763",
            updated_at: "2023-12-19 10:10:21.494006",
        },
    ])

    const [centerPosition, setCurrentPosition] = useState({
        lat: "52.660587",
        lng: "-8.627705",
    })

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setCurrentPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            })
        })
    }, [])

    const renderIcon = (data) => {
        if (data.type == "container") return Assets.GreenTank
        else if (data.type === "pickup") return Assets.PickPoint
    }

    const renderMarker = ({ data, index, cluster }) => (
        <MarkerF
            key={index}
            icon={renderIcon(data)}
            // label={{text: ' ', color:'black', fontSize: '18px'}}
            position={{ lat: data.latitude, lng: data.longitude }}
            // clusterer={cluster}
        />
    )

    const drawRoute = useCallback(() => {
        var start = new google.maps.LatLng(53.999366, -6.396571)
        var end = new google.maps.LatLng(53.997551144596116, -6.406638667506018)

        var display = new google.maps.DirectionsRenderer()
        var services = new google.maps.DirectionsService()

        var request = {
            origin: start,
            destination: end,
            travelMode: "DRIVING",
        }

        services.route(request, (result, status) => {
            if (status == "OK") {
                display.setDirections(result)
            }
        })

        display.setMap(map)
    }, [map])

    const drawRoute2 = useCallback(() => {
        var routes = [
            {
                origin: [53.999366, -6.396571],
                destination: [53.997551144596116, -6.406638667506018],
            },
            {
                origin: [54.01525504246823, -6.403153584135643],
                destination: [54.016359023121716, -6.31025327196983],
            },
        ]

        // latitude: 54.016359023121716,
        //   longitude: -6.31025327196983,

        var directionsService = new google.maps.DirectionsService()

        routes.map((route, index) => {
            console.log("ROute:", route, index)
            const start = new google.maps.LatLng(route.origin[0], route.origin[1])
            const end = new google.maps.LatLng(route.destination[0], [route.destination[1]])

            var request = {
                origin: start,
                destination: end,
                travelMode: google.maps.TravelMode.DRIVING,
            }

            var rendererOptions = {
                preserveViewport: true,
                suppressMarkers: true,
                routeIndex: index,
            }

            var directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions)

            directionsDisplay.setMap(map)

            directionsService.route(request, (result, status) => {
                console.log(result)

                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(result)
                }
            })
        })
    }, [map])

    return (
        <div>
            {isLoaded ? (
                <GoogleMap
                    id="marker-example"
                    mapContainerStyle={containerStyle}
                    zoom={6}
                    center={centerPosition}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    <div style={{ position: "absolute", top: 12, right: 60 }}>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setCurrentPosition({
                                    lat: parseFloat(52.660587),
                                    lng: parseFloat(-8.627705),
                                })
                            }}
                        >
                            Home Location
                        </Button>
                    </div>
                    <MarkerClusterer minimumClusterSize={2}>
                        {(clusterer) =>
                            markers.map((d, i) =>
                                renderMarker({ data: d, index: i, cluster: clusterer }),
                            )
                        }
                    </MarkerClusterer>
                </GoogleMap>
            ) : null}

            <button onClick={drawRoute}>Draw Route</button>
            <button onClick={drawRoute2}>Draw Route2</button>
        </div>
    )
}

export default MapsData
