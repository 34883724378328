import { Card, Col, Row } from "react-bootstrap"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { useMemo } from "react"
import { BillingDataDatatable } from "../../Data/WebsiteData/moby"

function GeneralBillingModel() {
    const columns = useMemo(
        () =>
            computeColumns({
                data: BillingDataDatatable.data,
                meta: BillingDataDatatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [],
    )

    const data = useMemo(() => BillingDataDatatable.data, [])

    return (
        <div>
            <PageHeader titles="Subscriptions" active="Subscripitons" items={["Tables"]} />
            <Row className="row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Billing Model</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Datatable isMultiSelect={false} columns={columns} data={data} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default GeneralBillingModel
