import moment from "moment"
import React, { useEffect } from "react"
import { Button } from "react-bootstrap"

const SummaryPopUp = ({ data, objectForm, routeModify, isShowExtraInfo }) => {
    function getValueByKey(key) {
        for (let i = 0; i < data.length; i++) {
            if (data[i][0] === key) {
                return data[i][1]
            }
        }
        return null
    }

    return (
        <div className="summaryPopUp " style={{ width: "26rem", height: "25.5rem" }}>
            <div className="header bin__summary-header">Bin Information</div>
            <div className="d-flex mt-4 align-items-start">
                <div className="progress-view">
                    <div className="percent progress-one">
                        <svg width="120" height="120">
                            <circle cx="60" cy="60" r="55"></circle>
                            <circle
                                cx="60"
                                cy="60"
                                r="55"
                                style={{
                                    strokeDasharray: "345px",
                                    strokeDashoffset: `calc(345 - (345 * ${getValueByKey("percent_full")}) / 100)`,
                                }}
                            ></circle>
                        </svg>
                        <div className="number">
                            <span className="value">
                                {getValueByKey("percent_full")}
                                <span>%</span>
                            </span>
                            <span className="title">Fill Level</span>
                        </div>
                    </div>
                    <div className="percent progress-two">
                        <svg width="120" height="120">
                            <circle cx="60" cy="60" r="55"></circle>
                            <circle
                                cx="60"
                                cy="60"
                                r="55"
                                style={{
                                    strokeDasharray: "345px",
                                    strokeDashoffset: `calc(345 - (345 * ${getValueByKey("battery_level")}) / 100)`,
                                }}
                            ></circle>
                        </svg>
                        <div className="number">
                            <span className="value">
                                {getValueByKey("battery_level")}
                                <span>%</span>
                            </span>
                            <span className="title text-center">Battery Level</span>
                        </div>
                    </div>
                </div>
                <div>
                    <table className="table table-striped p-2">
                        <tbody>
                            <tr>
                                {/* {console.log("distance---->", getValueByKey("raw_distance"))} */}
                                <td className="fw-normal text-capitalize">
                                    device id {getValueByKey("device_id")}
                                </td>
                                <td className="text-end">{getValueByKey("raw_distance")}</td>
                            </tr>
                            <tr>
                                {/* {console.log("distance---->", getValueByKey("raw_distance"))} */}
                                <td className="fw-normal text-capitalize">Distance</td>
                                <td className="text-end">{getValueByKey("raw_distance")}</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Fill Level</td>
                                <td className="text-end">{getValueByKey("percent_full")}%</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Temperature</td>
                                <td className="text-end">{getValueByKey("temperature")}&deg;C</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Battery</td>
                                <td className="text-end">{getValueByKey("battery_level")}%</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Signal</td>
                                <td className="text-end">{getValueByKey("snr")}</td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Zone</td>
                                <td className="text-end text-capitalize" style={{ width: "60%" }}>
                                    {getValueByKey("app_key") &&
                                        getValueByKey("app_key")?.split("_").join(" ")}
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-normal text-capitalize">Last Collection</td>
                                <td className="text-end">
                                    {moment(getValueByKey("last_contact"))?.format("DD/MM/YYYY")}
                                </td>
                            </tr>
                            <tr className="status-content">
                                <td className="fw-normal text-capitalize">Active Status</td>
                                <td className="text-end text-capitalize">{`${getValueByKey("is_active")}`}</td>
                            </tr>
                        </tbody>
                    </table>
                    {isShowExtraInfo && (
                        <Button
                            variant="secondary"
                            onClick={() => {
                                routeModify(objectForm)
                            }}
                            className="w-100"
                        >
                            {objectForm.is_ghost ? "Add to Route" : "Remove from Route"}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SummaryPopUp
