import motorcycleRed from "../../assets/images/media/motorcycle_red.png"
import motorcycleGreen from "../../assets/images/media/motorcycle_green.png"
import motorcycleBlue from "../../assets/images/media/motorcycle_blue.png"

import scooterRed from "../../assets/images/media/scooter_red.png"
import scooterGreen from "../../assets/images/media/scooter_green.png"
import scooterBlue from "../../assets/images/media/scooter_blue.png"

import kg3Red from "../../assets/images/media/kg3_red.png"
import kg3Green from "../../assets/images/media/kg3_green.png"
import kg3Blue from "../../assets/images/media/kg3_blue.png"

import kg4Red from "../../assets/images/media/kg4_red.png"
import kg4Green from "../../assets/images/media/kg4_green.png"
import kg4Blue from "../../assets/images/media/kg4_blue.png"

import vanRed1 from "../../assets/images/media/van_red.png"
import vanGreen1 from "../../assets/images/media/van_green.png"
import vanBlue from "../../assets/images/media/van_blue.png"

import solarPanelRed from "../../assets/images/media/solar_panel_red.png"
import solarPanelGreen from "../../assets/images/media/solar_panel_green.png"

import binRed from "../../assets/images/media/bin_red.png"
import binGreen from "../../assets/images/media/bin_green.png"

import pick_point from "../../assets/images/media/pick_point.png"
import drop_point from "../../assets/images/media/drop_point.png"
import pick_drop_point from "../../assets/images/media/pick_drop_point.png"

import DownloadIcon from "../../assets/images/download-icon.png"

export const Assets = {
    DownloadIcon,
}

const MapViewIcons = {
    motorcycleRed,
    motorcycleGreen,
    motorcycleBlue,

    scooterRed,
    scooterGreen,
    scooterBlue,

    kg3Red,
    kg3Blue,
    kg3Green,

    kg4Red,
    kg4Green,
    kg4Blue,

    vanRed1,
    vanGreen1,
    vanBlue,

    solarPanelRed,
    solarPanelGreen,

    binRed,
    binGreen,

    pick_drop_point,
    drop_point,
    pick_point,
}

export default MapViewIcons
