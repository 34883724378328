import React from "react"
import Layout from "../../Layouts/Layout"
import SummaryDashboard from "./SummaryDashboard"

function Summary() {
    const app = sessionStorage.getItem("app_key")

    const renderComponent = () => <SummaryDashboard />

    return renderComponent()
}

export default Summary
