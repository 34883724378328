import moment from "moment"

export const total = (
    { source, condition },
    // : {
    //     source: object[]
    //     condition: Array<{ type: string; value: string }> | undefined
    // }
) => {
    let filteredData = source

    if (condition)
        filteredData = source.filter((data) => condition.every((c) => data[c.type] === c.value))

    return filteredData.length.toLocaleString("en-IN")
}

export const computeInTime = (
    { source, unit, of },
    // : {
    //     source: object[]
    //     unit: string
    //     of: { start: string; end: string }
    // }
) => {
    const result = source.reduce((result, data) => {
        const difference = moment(data[of.end]).diff(data[of.start], "minutes")
        if (difference) return result + difference
        return result
    }, 0)

    return result.toLocaleString("en-IN") + unit
}

export const compute = (
    { source, hasFrontUnit, unit, condition, of },
    // : {
    //     // source: [{name:string}];
    //     hasFrontUnit: boolean
    //     source: object[]
    //     unit: string
    //     condition: Array<{ type: string; value: string }> | undefined
    //     of: string
    // }
) => {
    let filteredData = source
    if (condition)
        filteredData = source.filter((data) => condition.every((c) => data[c.type] === c.value))
    let result
    if (hasFrontUnit)
        result =
            unit +
            filteredData
                .reduce((result, data) => {
                    if (data[of]) {
                        return result + parseFloat(data[of])
                    } else return result + 0
                }, 0)
                .toLocaleString("en-US")
    else
        result = `${filteredData
            .reduce((result, data) => {
                if (data[of] !== null && typeof data[of] !== "undefined") {
                    return result + parseFloat(data[of])
                } else return result + 0
            }, 0)
            .toLocaleString("en-US")} ${unit}`
    return result
}

export function averageFullPercent(data, condition, filterCondition, parameter) {
    console.log(data)
    if (filterCondition && !parameter) {
        const containers_array = data
            .filter((item) => item[filterCondition] === "Waste")
            .filter((item) => item[condition] !== null)
        const sumPercentFull = containers_array.reduce((total, obj) => total + obj[condition], 0)
        const averagePercentFull = sumPercentFull / containers_array.length
        // console.log(averagePercentFull);

        if (averagePercentFull) return averagePercentFull.toFixed(2)
        else return 0
    } else {
        const containers_array = data.filter((item) => item[condition] >= parameter)
        return containers_array.length
    }
}

export function averageContainerData(data, property) {
    const propertyArray = data.filter((item) => item[property] !== null)

    const totalProperty = propertyArray.reduce((acc, item) => acc + item[property], 0)

    if (totalProperty / propertyArray.length) {
        return (totalProperty / propertyArray.length).toFixed(2)
    } else {
        return 0
    }
}
export const formatDashboardHeaderKey = (value) =>
    value.replace(/_/g, " ").replace(/\b[a-z]/g, (char) => char.toUpperCase())
