import { createAction } from "@reduxjs/toolkit"
import TYPES from "../types"

export const setModule = createAction(TYPES.SET_TEMPLATE_DATA_MODULE)
export const setLoadingState = createAction(TYPES.SET_TEMPLATE_LOADING_STATE)

export const fetchContainersByFilter = (apiBody) => {
    const requestBody = apiBody
    console.log("REQUEST BODY fetchContainersByFilter: ", requestBody)
    return {
        type: TYPES.FETCH_CONTAINERS_BY_FILTER,
        payload: requestBody,
    }
}

export const fetchData = (apiBody, options = { reUpdateSourceData: true }) => {
    const requestBody = apiBody
    requestBody.page_name = apiBody.module
    delete requestBody.module

    return {
        type: TYPES.FETCH_MODULE_TEMPLATE_DATA,
        payload: requestBody,
        options,
    }
}

export const saveRoute = (apiBody, promise) => {
    const requestBody = apiBody

    return {
        type: TYPES.SAVE_ROUTE,
        payload: requestBody,
        promise,
    }
}

export const updateVehiclePosition = (apiBody, options = { reUpdateSourceData: true }) => {
    const requestBody = apiBody
    requestBody.page_name = apiBody.module
    delete requestBody.module

    return {
        type: TYPES.FETCH_MODULE_TEMPLATE_UPDATE_POSITION_DATA,
        payload: requestBody,
        options,
    }
}

export const fetchDashboardStatistics = (apiBody) => {
    const requestBody = apiBody
    requestBody.page_name = apiBody.module
    delete requestBody.module

    return {
        type: TYPES.FETCH_MODULE_TEMPLATE_STATISTICS_DATA,
        payload: requestBody,
    }
}

export const addPage = createAction(TYPES.ADD_PAGE)
export const setModuleData = createAction(TYPES.SET_MODULE_TEMPLATE_DATA)

export const setFilteredData = createAction(TYPES.SET_MODULE_TEMPLATE_FILTERED_DATA)

export const setFilteredDataFetchApi = createAction(
    TYPES.SET_MODULE_TEMPLATE_FILTERED_DATA_FETCH_API,
)
export const forcedModifyState = createAction(TYPES.MODIFY_MODULE_TEMPLATE_DATA)

export const setFilters = createAction(TYPES.SET_MODULE_TEMPLATE_FILTERES)
