import http from "../../http"
import { enqueueSnackbar } from "notistack"

const app = sessionStorage.getItem("app")

export const deviceUnlock = async (apiBody) => {
    // let response;
    // let data;

    // let fd = new FormData();
    // for (const iterator of Object.keys(apiBody)) {
    //   fd.append(iterator, apiBody[iterator]);
    // }

    switch (app) {
        default:
            await http("POST", "api/v1/device/unlock", {
                apiData: apiBody,
            })
                .then((result) => {
                    console.log("Unlocking result:", result)
                    enqueueSnackbar("Unlocking", {
                        variant: "success",
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "bottom",
                        },
                    })
                })
                .catch((er) => {
                    console.log("Unlocking er: ", JSON.stringify(er), er.message)
                    enqueueSnackbar(er.message, {
                        variant: "error",
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "bottom",
                        },
                    })
                })
            break
    }
}
