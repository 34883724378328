import GeneralManageGeofencing from "./GeneralManageGeofencing"
import ManageGeofencing from "./ManageGeofencing"

function Routing() {
    const app = sessionStorage.getItem("app")

    const renderComponent = () => {
        switch (app) {
            default:
                return <ManageGeofencing />
            // default:
            //   return <GeneralManageGeofencing />;
        }
    }

    return renderComponent()
}

export default Routing
