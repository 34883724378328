import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react"
import {
    GoogleMap,
    MarkerF,
    useJsApiLoader,
    MarkerClusterer,
    InfoWindow,
} from "@react-google-maps/api"
import { Button, Modal } from "react-bootstrap"
import { Assets } from "../../assets"
import { useDispatch, useSelector } from "react-redux"
import { selectGeneralApiData, selectWebsiteData } from "../../redux/general/selectors"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
const app_key = sessionStorage.getItem("app_key")
import { useModalCtx } from "../../providers/ModalProvider"
import SummaryPopUp from "./SummaryPopUp"
import WavSmartDashboard from "../Dashboard/WavSmartDashboard"
import { setDashboardModal } from "../../redux/filterBars/reducers"
import { generalApiCall } from "../../redux/general/actions"
import { Card, Col, ProgressBar, Row } from "react-bootstrap"
import { formatDashboardHeaderKey } from "../../Data/WebsiteData/Statistics/interpreter.ts"
import {
    selectDatatable,
    selectFilteredData,
    selectModuleSpecificData,
    selectModuleSpecificRawData,
} from "../../redux/templateData/selectors"
import DashboardTilesOnMap from "../Dashboard/DashboardTilesOnMap"
import DashboardFiltersOnMap from "../Dashboard/DashboardFiltersOnMap.jsx"
import { result } from "lodash"
import MapExtraInformation from "./MapExtraInformation"
import StationSummaryPopUp from "./StationSummaryPopUp.jsx"
import { forcedModifyState } from "../../redux/templateData/actions.js"
import VehicleSummaryPopup from "./VehicleSummaryPopup.jsx"
import MapViewIcons from "../../assets/images/images.js"
import { app_key_arr } from "../../redux/globalConstants/globalConstants.js"

const containerStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
}

const mapInformationPopup = {
    backgroundColor: "white",
    color: "black",
    padding: "12px",
}

const MapsWithMultipleRoute = forwardRef(
    (
        {
            filteredData,
            module,
            data,
            id,
            stations,
            styles = {},
            renderSaveButton = (data) => {},
            closeRoute = () => {},
            totalWaste = null,
        },
        ref,
    ) => {
        const websiteData = useSelector(selectWebsiteData)
        const { toggleFullScreen } = useModalCtx()
        const [routeDetails, setRouteData] = useState([])
        const [allRoutes, setAllGeneratedRoutes] = useState([])
        const [fullScreenStatus, setFullScreenStatus] = useState(false)
        const [popupInfo, setPopupInfo] = useState("")
        const dispatch = useDispatch()
        const DashboardDisplay = useSelector((state) => state.filterBar.dashboard)
        const [showModal, setShowModal] = useState(false)
        const [isShowExtraInfo, setIsShowExtraInfo] = useState(false)
        const markersList = []
        const [dataToMapState, setDataToMapState] = useState([])
        const updateData = useCallback((data) => {
            setPopupInfo({ ...data })
        }, [])
        const pageModule = window.location.pathname.split("/")[1]

        console.log("sasewa", websiteData?.app_key)
        // const app_key_arr = [
        //     "master",
        //     "keego",
        //     "gramobility",
        //     "moby",
        //     "citkar",
        //     "diageo",
        //     "abc_solar",
        //     "bombay_softwares",
        //     "biketek",
        //     "volti",
        //     "move",
        //     "ewaka",
        // ]

        useEffect(() => {
            setShowModal(DashboardDisplay)
        }, [DashboardDisplay])

        const isShowInfoLoc = localStorage.getItem("showMoreInfo")

        useEffect(() => {
            if (isShowInfoLoc == "false") {
                setIsShowExtraInfo(false)
            }
        }, [isShowInfoLoc])

        useEffect(() => {
            const assignIcon = (data) => {
                if (data && data.type == "container") {
                    if (data.last_contact == "None") {
                        return data.is_ghost ? Assets.GrayDustbinGhost : Assets.GrayDustbin
                    }
                    if (0 <= data.percent_full && data.percent_full <= 20) {
                        return data.is_ghost ? Assets.GreenDustbinGhost : Assets.GreenDustbin
                    } else if (20 < data.percent_full && data.percent_full <= 50)
                        return data.is_ghost ? Assets.YellowDustbinGhost : Assets.YellowDustbin
                    else if (50 < data.percent_full && data.percent_full <= 80)
                        return data.is_ghost ? Assets.OrangeDustbinGhost : Assets.OrangeDustbin
                    else if (80 < data.percent_full && data.percent_full <= 100)
                        return data.is_ghost ? Assets.RedDustbinGhost : Assets.RedDustbin
                } else if (data && data.type == "Scooter") {
                    if (data.motion_status == "moving" && data.ignition == "on")
                        return Assets.scooterGreen
                    else if (data.trip_status === "inprogress") {
                        return Assets.scooterBlue
                    } else if (
                        data.status === "Locked" &&
                        data.current_status === "Available for Service"
                    ) {
                        return Assets.scooterGreen
                    } else return Assets.scooterRed
                } else if (data && data.type == "Bike") {
                    if (data.motion_status == "moving" && data.ignition == "on")
                        return Assets.motorcycleGreen
                    else if (data.trip_status === "inprogress") {
                        return Assets.motorcycleBlue
                    } else if (
                        data.status === "Locked" &&
                        data.current_status === "Available for Service"
                    ) {
                        return Assets.motorcycleGreen
                    } else return Assets.motorcycleRed
                } else if (data && data.type == "van") {
                    if (data.motion_status == "moving" && data.ignition == "on")
                        return Assets.motorcycleGreen
                    else if (data.trip_status === "inprogress") {
                        return Assets.motorcycleBlue
                    } else if (
                        data.status === "Locked" &&
                        data.current_status === "Available for Service"
                    ) {
                        return Assets.motorcycleGreen
                    } else return Assets.motorcycleRed
                } else if (data && data.type == "Delivery - KG3") {
                    if (data.motion_status == "moving" && data.ignition == "on")
                        return Assets.kg3Green
                    else if (data.trip_status === "inprogress") {
                        icon = MapViewIcons.kg3Blue
                    } else if (
                        data.status === "Locked" &&
                        data.current_status === "Available for Service"
                    ) {
                        icon = MapViewIcons.kg3Green
                    } else return Assets.kg3Red
                } else if (data && data.type == "Delivery - KG4") {
                    if (data.motion_status == "moving" && data.ignition == "on")
                        return Assets.kg4Green
                    else if (data.trip_status === "inprogress") {
                        icon = MapViewIcons.kg4Blue
                    } else if (
                        data.status === "Locked" &&
                        data.current_status === "Available for Service"
                    ) {
                        icon = MapViewIcons.kg4Green
                    } else return Assets.kg4Red
                } else if (data && data.type === "pickup") {
                    if (app_key_arr.includes(websiteData?.app_key)) {
                        return Assets.ParkingStation
                    } else {
                        return Assets.PickPoint
                    }
                } else if (data && data.type === "dropoff") {
                    if (app_key_arr.includes(websiteData?.app_key)) {
                        //this icon is for drop oof
                        return Assets.ParkingStation
                    } else {
                        return Assets.drop_point
                    }
                } else if (
                    data &&
                    data.type === "both"
                    // || data.type === "pickup_dropoff_both"
                )
                    return Assets.pick_drop_point
            }

            setDataToMapState(
                data.map((item) => {
                    return { ...item, icon: assignIcon(item) }
                }),
            )
        }, [data])

        // const dataToMap = useMemo(() => {

        // }, [data])

        const [centerPosition, setCurrentPosition] = useState({
            lat: parseFloat(websiteData?.home_location_lat),
            lng: parseFloat(websiteData?.home_location_long),
        })

        const [markerObjects, setMarkerObjects] = useState([])
        const [zoom, setZoom] = useState(12)

        useImperativeHandle(ref, () => ({
            coordinates: {},
            routeDetails,
            createRoute,
            routesReset,
            setCenter: (coordinates) => {
                // console.log('coordinates calls', coordinates);
                updateCoordinatesToStorage(coordinates)
            },
            setZoom: (level) => {
                updateZoomLevelToStorage(level)
            },
        }))

        const [map, setMap] = useState(null)
        const [displayDirectionObj] = useState([])
        const infoRef = useRef()

        const { isLoaded } = useJsApiLoader({
            id: "drawing-manager-example",
            googleMapsApiKey: "AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac",
            libraries: ["drawing"],
        })

        const [markers, setMarkers] = useState([])

        const routesReset = useCallback(() => {
            displayDirectionObj.map((displayDirection) => {
                displayDirection.setMap(null)
            })
        }, [displayDirectionObj])

        function createCenterControl(map) {
            const controlBdutton = document.createElement("button")

            // Set CSS for the control.
            controlButton.style.backgroundColor = "#fff"
            controlButton.style.border = "2px solid #fff"
            controlButton.style.borderRadius = "3px"
            controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)"
            controlButton.style.color = "rgb(25,25,25)"
            controlButton.style.cursor = "pointer"
            controlButton.style.fontFamily = "Roboto,Arial,sans-serif"
            controlButton.style.fontSize = "16px"
            controlButton.style.lineHeight = "38px"
            controlButton.style.margin = "8px 0 22px"
            controlButton.style.padding = "0 5px"
            controlButton.style.textAlign = "center"
            controlButton.textContent = "Center Map"
            controlButton.title = "Click to recenter the map"
            controlButton.type = "button"
            // Setup the click event listeners: simply set the map to Chicago.
            controlButton.addEventListener("click", () => {
                map.setCenter({ lat: 41.85, lng: -87.65 })
            })
            return controlButton
        }

        let icon
        const onLoad = useCallback(
            (map) => {
                // console.log("google.maps.controls: ", map)
                // google.maps.Map(map, {
                //     disableDefaultUI: true,
                // })
                if (map) {
                    google.maps.event.addListener(map, "click", () => {
                        setPopupInfo("")
                    })
                }
                google.maps.event.addListener(map, "dragend", () => {
                    const newCenter = map.getCenter()
                    localStorage.setItem(
                        `map_data_${localStorage.getItem("app")}`,
                        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() }),
                    )
                    localStorage.setItem(
                        `${localStorage.getItem("app_name")}map_data`,
                        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() }),
                    )
                })

                google.maps.event.addListener(map, "zoom_changed", () => {
                    const newZoomLevel = map.getZoom()
                    localStorage.setItem(
                        `zoom_data_${localStorage.getItem("app")}`,
                        JSON.stringify(newZoomLevel),
                    )
                    localStorage.setItem(
                        `${localStorage.getItem("app_name")}zoom_data`,
                        JSON.stringify(newZoomLevel),
                    )

                    const newCenter = map.getCenter()
                    localStorage.setItem(
                        `map_data_${localStorage.getItem("app")}`,
                        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() }),
                    )
                    localStorage.setItem(
                        `${localStorage.getItem("app_name")}map_data`,
                        JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() }),
                    )
                })

                // const centerControlDiv = document.createElement("div")
                // const centerControl = createCenterControl(map)

                // centerControlDiv.appendChild(centerControl)
                // map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(centerControlDiv)

                setMap(map)
            },
            [markers, websiteData],
        )

        const onUnmount = useCallback(
            (map) => {
                //console.log("MAP UNMOUNTED: ", markers)
                setMap(null)
            },
            [markers],
        )

        const onMouseHover = useCallback((data) => setPopupInfo(data), [])

        const renderKey = (data, index) => {
            if (data.hasOwnProperty("station_id")) {
                return `${data.station_id}_${index}`
            } else if (data.hasOwnProperty("status") && data.hasOwnProperty("asset_id")) {
                return `${data.status}_${data.asset_id}_${data.is_ghost}`
            } else return `index${data.id}${data.is_ghost}`
        }

        const renderMarker = ({ data, index, cluster }) => {
            const url = data.icon

            if (url && data) {
                return (
                    <MarkerF
                        key={renderKey(data, index)}
                        icon={{
                            url,
                            // anchor: new google.maps.Point(25, 5),
                        }}
                        // label={{text: ' ', color:'black', fontSize: '18px'}}
                        position={{
                            lat: parseFloat(data.latitude),
                            lng: parseFloat(data.longitude),
                        }}
                        // onMouseOver={() => onMouseHover(data)}
                        onClick={() => onMouseHover(data)}
                        clusterer={cluster}
                        onUnmount={() => {
                            //console.log("UNMOUNT MARKER")
                            // marker.setMap(null)
                        }}
                    />
                )
            }
        }
        const drawRoute = useCallback(() => {
            var start = new google.maps.LatLng(53.999366, -6.396571)
            var end = new google.maps.LatLng(53.997551144596116, -6.406638667506018)

            var display = new google.maps.DirectionsRenderer()
            var services = new google.maps.DirectionsService()

            var request = {
                origin: start,
                destination: end,
                travelMode: "DRIVING",
            }

            services.route(request, (result, status) => {
                if (status == "OK") {
                    display.setDirections(result)
                }
            })

            display.setMap(map)
        }, [map])

        const createRoute = useCallback(() => {
            setAllGeneratedRoutes([])
            const allRoutesInfo = []
            const routes = ref.current.coordinates

            let groupedRoutes2 = _.groupBy(routes, ({ zone }) => zone.toLowerCase())
            console.log("result: Map Route here routes:", routes, groupedRoutes2)

            if (routes.length > 0) {
                // updateCoordinatesToStorage({
                //     lat: parseFloat(routes[0].origin[0]),
                //     lng: parseFloat(routes[0].origin[1]),
                // })

                const directionsService = new google.maps.DirectionsService()

                let routesHere = []

                Object.keys(groupedRoutes2).map((key) => {
                    let promises = []

                    groupedRoutes2[key].map((route, index) => {
                        const routeData = { ...route }
                        let routeResultantData = {}
                        const start = new google.maps.LatLng(route.origin[0], route.origin[1])
                        let waypoints = []
                        const end = new google.maps.LatLng(route.destination[0], [
                            route.destination[1],
                        ])

                        if (route.waypoints)
                            waypoints = route.waypoints.map((waypoint) => ({
                                location: new google.maps.LatLng(
                                    waypoint.latitude,
                                    waypoint.longitude,
                                ),
                                stopover: false,
                            }))
                        else waypoints = []

                        var request = {
                            origin: start,
                            destination: end,
                            travelMode: google.maps.TravelMode.DRIVING,
                            waypoints,
                            optimizeWaypoints: true,
                        }

                        console.log("drawRoute2 request:", request)

                        let promise = new Promise((resolve, reject) => {
                            directionsService.route(request, (result, status) => {
                                resolve(result)
                            })
                        })

                        allRoutesInfo.push(route)

                        promises.push(promise)
                    })

                    Promise.all(promises).then((results) => {
                        let computedParameter = {
                            duration: 0,
                            distance: 0,
                        }

                        console.log("REUSLT:", results)

                        let computedRouteParameters = results.reduce(
                            (computedParameter, result) => {
                                if (result.routes.length > 0) {
                                    computedParameter = {
                                        duration:
                                            computedParameter.duration +
                                            result.routes[0].legs[0].duration.value,
                                        distance:
                                            computedParameter.distance +
                                            result.routes[0].legs[0].distance.value,
                                    }
                                }
                                return computedParameter
                            },
                            computedParameter,
                        )

                        results.map((result, index2) => {
                            var rendererOptions = {
                                preserveViewport: true,
                                suppressMarkers: true,
                                routeIndex: index2,
                            }

                            const directionsDisplay = new google.maps.DirectionsRenderer(
                                rendererOptions,
                            )

                            directionsDisplay.setOptions({
                                polylineOptions: {
                                    strokeColor: "#90B1FB",
                                    strokeWeight: 5,
                                    icons: [
                                        {
                                            icon: {
                                                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                scale: 3,
                                                strokeOpacity: 1,
                                                strokeColor: "#0000FF",
                                            },
                                            offset: "0%",
                                            repeat: "150px",
                                        },
                                    ],
                                },
                            })

                            directionsDisplay.setDirections(result)
                            displayDirectionObj.push(directionsDisplay)
                            directionsDisplay.setMap(map)
                        })

                        let routeInformation = groupedRoutes2[key]
                        let assets = []
                        let waypoints = []

                        console.log("routeInformation:", routeInformation)
                        routesHere.push({
                            ...computedRouteParameters,
                            totalContainers: routeInformation.reduce((assets, info) => {
                                assets = [...assets, ...info.assetsData]
                                return assets
                            }, assets).length,
                            route: {
                                assetsData: routeInformation.reduce((assets, info) => {
                                    assets = [...assets, ...info.assetsData]
                                    return assets
                                }, assets),
                                origin: routeInformation.reduce((assets, info) => {
                                    assets = info.origin
                                    return assets
                                }, assets),
                                destination: routeInformation.reduce((assets, info) => {
                                    assets = info.destination
                                    return assets
                                }, assets),
                                waypoints: routeInformation.reduce((assets, info) => {
                                    waypoints = [...waypoints, ...info.waypoints]
                                    return waypoints
                                }, waypoints),
                                zone: routeInformation.reduce((assets, info) => {
                                    assets = info.zone
                                    return assets
                                }, assets),
                            },
                        })

                        console.log("routesHereL", routesHere)
                        ref.coordinates = routes
                        setRouteData([...routesHere])
                        setAllGeneratedRoutes([...routesHere])
                    })
                })

                setIsShowExtraInfo(true)
                localStorage.setItem("showMoreInfo", "true")
            } else {
                setIsShowExtraInfo(false)
                closeRoute()
                localStorage.setItem("showMoreInfo", "false")
            }
        }, [map, routeDetails, allRoutes])

        const drawRoute2a = useCallback(() => {
            const routes = ref.current.coordinates

            if (routes.length > 0) {
                // Define a symbol to represent the arrow
                const arrowSymbol = {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 4,
                    strokeColor: "#0000FF", // Arrow color
                }

                const directionsService = new google.maps.DirectionsService()

                routes.forEach((route, index) => {
                    const start = new google.maps.LatLng(route.origin[0], route.origin[1])
                    const end = new google.maps.LatLng(route.destination[0], route.destination[1])
                    const waypoints = (route.waypoints || []).map((waypoint) => ({
                        location: new google.maps.LatLng(waypoint.latitude, waypoint.longitude),
                        stopover: false,
                    }))

                    const request = {
                        origin: start,
                        destination: end,
                        travelMode: google.maps.TravelMode.DRIVING,
                        waypoints,
                    }

                    directionsService.route(request, (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            const directionsRenderer = new google.maps.DirectionsRenderer({
                                map,
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: "#FF0000", // Route color
                                },
                            })

                            // Apply the arrow symbol to the route polyline
                            directionsRenderer.setOptions({
                                polylineOptions: {
                                    icons: [
                                        {
                                            icon: {
                                                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                scale: 2.5,
                                                strokeOpacity: 1,
                                                strokeColor: "#0000FF",
                                            },
                                            offset: "0%", // Show arrows along the entire route
                                            repeat: "100px", // Repeat the arrow every 100 pixels
                                        },
                                    ],
                                },
                            })
                            // directionsRenderer.setOptions({})

                            directionsRenderer.setDirections(result)
                        }
                    })
                })
            }
        }, [map, ref])

        // const handleClickEvent = (e) => {
        //     if (
        //         infoRef.current?.contains(e.target) === false ||
        //         markerRef.current?.contains(e.target) === false
        //     ) {
        //         setPopupInfo("")
        //     }
        // }

        const updateCoordinatesToStorage = (coordinates) => {
            if (websiteData?.id != undefined) {
                localStorage.setItem(`map_data_${websiteData?.id}`, JSON.stringify(coordinates))
                localStorage.setItem(`${websiteData?.name}map_data`, JSON.stringify(coordinates))
            }

            setCurrentPosition({ ...coordinates })
        }

        const updateZoomLevelToStorage = (zoomLevel) => {
            if (websiteData?.id != undefined) {
                localStorage.setItem(`zoom_data_${websiteData?.id}`, JSON.stringify(zoomLevel))
                localStorage.setItem(`${websiteData?.name}zoom_data`, JSON.stringify(zoomLevel))
            }

            setZoom(zoomLevel)
        }

        useEffect(() => {
            const coordinates = localStorage.getItem(`map_data_${localStorage.getItem("app")}`)
                ? JSON.parse(localStorage.getItem(`map_data_${localStorage.getItem("app")}`))
                : centerPosition

            const zoomLevel = localStorage.getItem(`zoom_data_${localStorage.getItem("app")}`)
                ? JSON.parse(localStorage.getItem(`zoom_data_${localStorage.getItem("app")}`))
                : zoom

            updateCoordinatesToStorage(coordinates)
            updateZoomLevelToStorage(zoomLevel)
        }, [])

        useEffect(() => {
            Array.from(document.getElementsByClassName("view_mode")).map((ele) =>
                ele.classList.remove("view_mode_hide"),
            )
            // window.addEventListener("click", handleClickEvent, { passive: true })
            if (data.length >= 0) {
                setMarkers([...dataToMapState.map((dataObj, index) => ({ ...dataObj, index }))])
            }
        }, [dataToMapState])

        function getValueByKeys(key, arrayOfArrays) {
            for (let i = 0; i < arrayOfArrays.length; i++) {
                if (arrayOfArrays[i][0] === key) {
                    return arrayOfArrays[i][1]
                }
            }
            return null
        }

        function adjustCoordinates(array) {
            const coordinatesMap = new Map()

            array.forEach((obj) => {
                const key = `${obj.latitude},${obj.longitude}`
                if (coordinatesMap.has(key)) {
                    const R = 6378137.0

                    const DistanceNorth = Math.floor(Math.random() * (11 - 7 + 1)) + 3
                    const DistanceEast = Math.floor(Math.random() * (11 - 7 + 1)) + 3

                    const dLat = DistanceNorth / R
                    const dLon = DistanceEast / (R * Math.cos((Math.PI * obj.latitude) / 180))

                    const NewLat = obj.latitude + (dLat * 180) / Math.PI
                    const NewLng = obj.longitude + (dLon * 180) / Math.PI

                    obj.latitude = NewLat
                    obj.longitude = NewLng
                }

                coordinatesMap.set(key, true)
            })

            return array
        }

        const appendItem = (valueToModify, dispatch) =>
            new Promise((resolve, reject) => {
                dispatch(
                    forcedModifyState({
                        module: "containers.datatable.filteredData",
                        data: valueToModify,
                    }),
                )

                resolve()
            })

        const routeModify = (data) => {
            data.is_ghost = !data.is_ghost
            let valueToModify = []

            if (
                filteredData.findIndex((obj) => {
                    return obj.asset_id == data.asset_id
                }) >= 0
            ) {
                valueToModify = filteredData.filter((obj) => obj.asset_id !== data.asset_id)
            } else {
                valueToModify = [...filteredData, data]
            }
            setAllGeneratedRoutes([])
            dispatch(
                forcedModifyState({
                    module: `${pageModule}.datatable.filteredData`,
                    data: valueToModify,
                }),
            )

            // dispatch(
            //     forcedModifyState({
            //         module: "vehicles.datatable.filteredData",
            //         data: valueToModify,
            //     }),
            // )

            setPopupInfo("")
        }

        return (
            <div>
                {isShowExtraInfo && (
                    <MapExtraInformation
                        filteredData={filteredData}
                        routeData={[{ ...routeDetails }]}
                        allRoutes={allRoutes}
                        setClose={setIsShowExtraInfo}
                        renderSaveButton={() => renderSaveButton(routeDetails)}
                        closeRoute={closeRoute}
                        totalWaste={totalWaste}
                        updateCoordinatesToStorage={updateCoordinatesToStorage}
                    />
                )}

                {isLoaded ? (
                    <GoogleMap
                        style={{ position: "relative" }}
                        id={id}
                        mapContainerStyle={{ ...containerStyle, ...styles }}
                        zoom={zoom}
                        center={centerPosition}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                            fullscreenControl: false,
                            streetViewControl: false,
                            clickableIcons: false,
                            mapTypeControl: false,
                            styles: [
                                {
                                    featureType: "poi",
                                    stylers: [
                                        {
                                            visibility: "off",
                                        },
                                    ],
                                },
                            ],
                        }}
                    >
                        {showModal && (
                            <Row
                                style={{
                                    marginLeft: "60px",
                                    marginTop: "190px",
                                    position: "absolute",
                                }}
                            >
                                <DashboardTilesOnMap module={module} />
                            </Row>
                        )}
                        <div style={{ position: "absolute", top: 20, right: 90 }}>
                            {/* <Button
                            variant="secondary"
                            onClick={() => {
                                setMarkers([
                                    {
                                        id: 53,
                                        device_id: "0E2F2633439BEC67",
                                        percent_full: 50,
                                        api_trigger_level_percent: 75,
                                        substance: "Waste",
                                        last_contact: "2024-01-21 17:59:08.086000",
                                        is_active: false,
                                        latitude: 49.2130539720288,
                                        longitude: -122.9798881645289,
                                        estimated_runout_days: null,
                                        amount_to_fill_litres: null,
                                        remaining_fuel_litres: null,
                                        ullage: null,
                                        raw_distance: 40,
                                        temperature: 6,
                                        battery_level: 98,
                                        pick_up_event: false,
                                        fill_level: 50,
                                        fill_error: false,
                                        snr: 4,
                                        rssi: -88,
                                        is_extended_uplink: 0,
                                        type: "container",
                                        created_at: "2024-02-08 04:31:21.104639",
                                        updated_at: "2024-03-06 11:15:09.674347",
                                        is_ghost: true,
                                        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABTZJREFUeNrsWVtsFFUYPjuz252h222pShU03abE4pVVhIgJdEuJSsDYYmIkEmV9MPrUVn2gsWJtjH0yyIuvFC8BNeIajVDDprt9ECNKF1xKqlTXywYovSyUtrvtzqznn85MZ2dnl7nSNvZPptvt9Fy+83//f873H4SWbMmWrJDZrOi0s7PT+/ADUz63m6kscTFe6buRUXt4MOaMXL5iD7W2tiYWHBA8+bLH1k8031Ex8+LKihmP05kp+P9Dw3Y0dpUM/XaBOrTz2fauBQEkfKKtvdqTaqq4LV2mp/2ffxfFfv/D2bL96Y7AvAA5+nm79567kwdXV6Wy6GMjVyGb/X78rMbfKO47WIYZxD+mUCZ9AT9RlGFHxTaplA1hygW+C7r9eihnMwJi88brPaVuRvQCTJxwPoEnXq2qDwDDproxwLj4t/glR+Tw0eWNGEzMciCffNTh27blWo+7hOF7oRFJ7+K8oMfY6V7EJudYdfUameg96arDsRNR2wepxxP1m8aPYU9QAo1I2s/TKNuYyQ8RO3WYW3XhAWrZ7GuyV5Os5BYhk4Z5pxHlzFAuF/toxaodnwWDwaTpHoHM9PwzY304M3kET9hdbdynEAPAf5E6M6eQzbE+h05SzxHwnijn28cRM/G++K5/gAqs3dDZaDqQk71v7n9k7WSz6M7i18VAFjwgBaLGiKLNiKAa8tLsm+5Sv5r0TGrwhudx3/gRYX/gJlC0IXtV8MqCF7QYWdyUQzMuu/EZzV3Celes3HHoRhQj1A6IQbwtDW7ITlaZtG+gcU11quGGbdR2joNb7IwAjvNxochX7Bly2UtZExPiQv5OsT1O31LK4r2qyRQgmFa+yjun5/YLGaVyey3PCujZ/aVW8V1eMJIkgZnghURjGAg+Q/nkK2b5aVbikRW3puHDawq1lAawFkj2YuHTtHEgt5Sna+dGoOdFb1AUa5xaoCEWurAi0CKxH04VhwwDAUUnHjEkJ1VLDZ/JpEd8bAnDQECWgqITB5DoCMtwwO7O26UrjgQ+1kcMAwGhMzxiDygNYhmQmV/F3ycmiJBpMYLp9bX0YGc1rVh8SlYa2zAQOIGCthbixEqvcAvFx8hf/xYl1Jx+NWWt6Hn6HXGwZEB38BZGMZrl8fhFxwHT06/cK6D+lAM1PqsGhbmlwuLkuHcFFoFJHhFB4ySTwGn3A0v2ESjb8OkQsVh7KOoP4LgECFcxEQQXvMsTY7AwUmGGY+OA2oqKruJD9PTeHqwRfDkqr8AklUenubbQjuFARKUpP/HxF+VVaoHY9QAB+Xn77pE+oRQEk4fgJ6jnNAsuaMd5QlbjOnOO1lTf0l3X6v52X/OWTeP7czrEWgMkcEHNwRXporPUVND4Z/vprnUb3/PftEqjnGK5oOSVRny8yYwVPObwBbo6rdVG0giQuzzbw/fVJPdAHSpfKkXs0GyZiCsoDGEg43n7g8Lcjz8XN778ylsxrXMxBCQYDCbq67emqiqnnzS6CXJx0U+/uu2pjuO6hJgZO/GZn1q/urcm2WCkDz1xYboegQo6pEu97SEuvg+VtMy7sILAhHQpbJRaDOKCr74bur0y9epNXlJVExeB42V1u1/YFzI6tul3iOcje/vkFz/5QPxydpm/duu7XWaMa7pmDxwrrVMTLwODVJdZICzxCJjSbZbUtFwXzGsVBW6azg1QLUrBDxkKspzZY1riEcHCJ9r2rHtw8qBwFXHxsiP26ZfLHzLzfv2mGYC5PvRaZvifN8aAcmgxG4BZ9CCW7P9q/wkwAFONdIWNrq9aAAAAAElFTkSuQmCC",
                                    },
                                ])
                            }}
                        >
                            Add MArker
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                console.log("markersList:", markersList)
                                markersList.map((marker) => {
                                    marker.setMap(null)
                                })
                            }}
                        >
                            Reset MArker
                        </Button> */}
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    const coordinates = {
                                        lat: parseFloat(websiteData?.home_location_lat),
                                        lng: parseFloat(websiteData?.home_location_long),
                                    }

                                    localStorage.setItem(
                                        `map_data_${websiteData?.id}`,
                                        JSON.stringify(coordinates),
                                    )

                                    localStorage.setItem(
                                        `${websiteData?.name}map_data`,
                                        JSON.stringify(coordinates),
                                    )

                                    setCurrentPosition({ ...coordinates })
                                    setZoom(12)
                                }}
                            >
                                Home Location
                            </Button>
                        </div>
                        <div style={{ position: "absolute", top: 20, right: 23 }}>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    const currentScreenStatus =
                                        (screen.availHeight || screen.height - 30) <=
                                        window.innerHeight
                                    toggleFullScreen(!currentScreenStatus)
                                    setFullScreenStatus(!currentScreenStatus)
                                }}
                            >
                                <FullscreenIcon />
                            </Button>
                        </div>
                        <MarkerClusterer minimumClusterSize={3}>
                            {(clusterer) =>
                                adjustCoordinates(markers)?.map((d, i) =>
                                    renderMarker({
                                        data: d,
                                        index: i,
                                        cluster: clusterer,
                                    }),
                                )
                            }
                        </MarkerClusterer>
                        {/* <MarkerClusterer minimumClusterSize={2}>
                        {(clusterer) =>
                            stations.map((d, i) =>
                                renderMarker({
                                    data: d,
                                    index: i,
                                    cluster: clusterer,
                                }),
                            )
                        }
                    </MarkerClusterer> */}
                        {/* {popupInfo !== "" && (
                        <div
                            ref={infoRef}
                            style={{ position: "absolute", bottom: "50px", right: 80 }}
                        >
                            <div style={mapInformationPopup}>
                                {Object.entries(popupInfo).map(([key, value], index) => {
                                    const transformedKey = key.replace(/_/g, " ") // Replace underscore with space
                                    return (
                                        <div key={index}>
                                            <strong style={{ textTransform: "capitalize" }}>
                                                {transformedKey}:
                                            </strong>{" "}
                                            {value}
                                            <br />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )} */}
                        {/* {popupInfo !== "" && (
                        <div
                            ref={infoRef}
                            style={{ position: "absolute", bottom: "50px", right: 80 }}
                        >
                            <div style={mapInformationPopup}>
                                {Object.entries(popupInfo).map(([key, value], index) => {
                                    const transformedKey = key.replace(/_/g, " ") // Replace underscore with space
                                    return (
                                        <div key={index}>
                                            <strong style={{ textTransform: "capitalize" }}>
                                                {transformedKey}:
                                            </strong>{" "}
                                            {value}
                                            <br />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )} */}
                        {popupInfo && (
                            <InfoWindow
                                ref={infoRef}
                                position={{
                                    lat: popupInfo?.lat || popupInfo?.latitude,
                                    lng: popupInfo?.long || popupInfo?.longitude,
                                }}
                                onCloseClick={() => setPopupInfo()}
                            >
                                <div ref={infoRef}>
                                    {console.log(
                                        "first",
                                        getValueByKeys("type", Object.entries(popupInfo)),
                                    )}
                                    {getValueByKeys("type", Object.entries(popupInfo)) ===
                                    "dropoff" ? (
                                        <>
                                            <StationSummaryPopUp data={Object.entries(popupInfo)} />
                                        </>
                                    ) : getValueByKeys("type", Object.entries(popupInfo)) ===
                                      "container" ? (
                                        <>
                                            <SummaryPopUp
                                                routeModify={routeModify}
                                                data={Object.entries(popupInfo)}
                                                objectForm={popupInfo}
                                                isShowExtraInfo={isShowExtraInfo}
                                            />
                                        </>
                                    ) : getValueByKeys("type", Object.entries(popupInfo)) ===
                                      "pickup" ? (
                                        <>
                                            <StationSummaryPopUp data={Object.entries(popupInfo)} />
                                        </>
                                    ) : (
                                        <>
                                            <VehicleSummaryPopup
                                                onClose={setPopupInfo}
                                                data={Object.entries(popupInfo)}
                                                objectForm={popupInfo}
                                                routeModify={routeModify}
                                                isShowExtraInfo={isShowExtraInfo}
                                            />
                                            {/* {Object.entries(popupInfo).map(
                                                ([key, value], index) => {
                                                    const transformedKey = key.replace(/_/g, " ") // Replace underscore with space
                                                    return (
                                                        <div key={index}>
                                                            <strong
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                }}
                                                            >
                                                                {transformedKey}:
                                                            </strong>{" "}
                                                            {value}
                                                            <br />
                                                        </div>
                                                    )
                                                },
                                            )} */}
                                        </>
                                    )}
                                </div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                ) : null}
                <br />
                {/* <button onClick={drawRoute}>Draw Route</button>
      <button onClick={drawRoute2}>Draw Route2</button> */}
            </div>
        )
    },
)

export default React.memo(MapsWithMultipleRoute)

MapsWithMultipleRoute.defaultProps = {
    filteredData: [],
}
