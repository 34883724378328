import { createAction, createReducer } from "@reduxjs/toolkit"

const initialState = {
    filters: [],
}

export const setMapFilters = createAction("SET_FILTERS_MAP")

const MapFiltersReducer = createReducer(initialState, (builder) => {
    builder.addCase(setMapFilters, (state, action) => {
        state.filters = action.payload
    })
})

export default MapFiltersReducer
