import React, { useState, useEffect } from "react"
import { Card, Col, ProgressBar, Row } from "react-bootstrap"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import styles from "./Dashboard.module.scss"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { selectWebsiteData } from "../../redux/general/selectors"
import { DashboardStatisticsWavSmart } from "../../Data/WebsiteData/moby"
import { useInfoCtx } from "../../providers/InfoCardProvider"
import Skeleton from "@mui/material/Skeleton"
import { selectLoadingState } from "../../redux/templateData/selectors"
import { selectGeneralApiData } from "../../redux/general/selectors"
import { useDispatch } from "react-redux"
import { generalApiCall } from "../../redux/general/actions"
import { formatDashboardHeaderKey } from "../../Data/WebsiteData/Statistics/interpreter.ts"
import { formattingValue } from "./DashboardTilesOnMap.jsx"
// Skeleton loader component
const SkeletonLoader = () => (
    <Card className="overflow-hidden">
        <Skeleton variant="rectangular" width={500} height={100} />
    </Card>
)

const WavSmartDashboard = () => {
    const dispatch = useDispatch()
    const websiteData = useSelector(selectWebsiteData)
    const { renderInfoCardData } = useInfoCtx()
    const [loading, setLoading] = useState(true)
    const loadingState = useSelector(selectLoadingState)

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(loadingState)
        }, 500)

        return () => clearTimeout(timer)
    }, [loadingState])

    const dashboardStatistics = useSelector(selectGeneralApiData({ key: "dashboard_statistics" }))

    useEffect(() => {
        if (websiteData.app_key) {
            dispatch(
                generalApiCall({
                    endpoint: `api/v1/get-all-stats`,
                    params: {},
                    method: "GET",
                    key: "dashboard_statistics",
                }),
            )
        }
    }, [websiteData.app_key])

    function renderDashboardStatistics() {
        if (dashboardStatistics[websiteData.app_key]) {
            return Object.keys(dashboardStatistics[websiteData.app_key]).map((key) => (
                <Col lg={6} md={6} sm={12} xxl={3} key={key}>
                    <Card className="overflow-hidden">
                        <Card.Body>
                            <div className="d-flex">
                                <div className="mt-2">
                                    <h6 className="">{formatDashboardHeaderKey(key)}</h6>
                                    <h2 className="mb-0 number-font">
                                        {formattingValue(
                                            dashboardStatistics[websiteData.app_key][key],
                                        )}
                                    </h2>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ))
        } else {
            return null
        }
    }

    return (
        <>
            {dashboardStatistics && (
                <div className={styles.Dashboard}>
                    <Helmet>
                        <title>{websiteData.name || ""} | Efleety</title>
                    </Helmet>
                    <PageHeader titles="Dashboard" active="Dashboard" items={["Home"]} />
                    {/* <Row>
                        <Col lg={12} md={12} sm={12} xl={12}>
                            <Row>
                                {DashboardStatisticsWavSmart.filter(
                                    (item) => websiteData.app_key === item.fleet_manager,
                                ).map((card, index) => (
                                    <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                                        {loading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Card className="overflow-hidden">
                                                <Card.Body>
                                                    <div className="d-flex">
                                                        <div className="mt-2">
                                                            <h6 className="">{card.heading}</h6>
                                                            <h2 className="mb-0 number-font">
                                                                {renderInfoCardData(card)}
                                                            </h2>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <div className="chart-wrapper mt-1"></div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row> */}
                    <Row>{renderDashboardStatistics()}</Row>
                </div>
            )}
        </>
    )
}

export default WavSmartDashboard
