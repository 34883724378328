import { Button, Form, Card, Col, Row } from "react-bootstrap"
import Slider from "@mui/material/Slider"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { VehicleDatatable, WorkOrderDatatable } from "../../Data/DummyData"
import AssetLocationMap from "../Maps/AssetLocationMap"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import Datatable, { computeColumns, renderFilter } from "../../Data/TablesData/Datatable"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { useDispatch, useSelector } from "react-redux"
import Modal from "../../components/Modal"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import UnfoldMoreIcon from "@mui/icons-material/SwapVert"
import axios from "axios"
import {
    selectDatatable,
    selectFilteredData,
    selectModuleSpecificData,
    selectModuleSpecificRawData,
} from "../../redux/templateData/selectors"
import {
    addPage,
    fetchData,
    saveRoute,
    setFilteredData,
    setModule,
} from "../../redux/templateData/actions"
import { initialState } from "../../redux/templateData/init"
import _, { ceil, debounce, filter, head } from "lodash"
import MapsWithMultipleRoute from "../Maps/MapWithMultipleRoute"
import { Grid, MenuItem, TextField } from "@mui/material"
import moment from "moment"
import { selectMapViewStatus, selectWebsiteData } from "../../redux/general/selectors"
import { setMapViewStatus } from "../../redux/actions"
import WavSmartDashboard from "../Dashboard/WavSmartDashboard"
import MapFilter from "../Maps/MapFilter"
import { setMapFilters } from "../../redux/filters/reducers"
import { useFormik } from "formik"
import { VehicleFormValidations } from "../../validations"
import { useModalCtx } from "../../providers/ModalProvider"
import { setDashboardModal, setFilterDisplay } from "../../redux/filterBars/reducers"

// import { compute } from '../../Data/WebsiteData/Statistics/interpreter';
import { addData, deleteData, editData } from "../../redux/actions"
import { unlockDevice } from "../../redux/iot/actions"
import DashboardFiltersOnMap from "../Dashboard/DashboardFiltersOnMap"

export const formatAmount = (amountToFillLiters) => {
    if (typeof amountToFillLiters === "number") {
        return amountToFillLiters.toLocaleString("en-US")
    } else {
        return 0
    }
}

let APIInterval

function MaintenanceStaff() {
    const vehicleData = useSelector(selectDatatable)
    const mapReference = useRef()
    const datatableRef = useRef()
    // const datatable = useSelector(selectDatatable)
    const [informationToDisplay, setInformationToView] = useState({})
    const [coordinatesOriginDestination, setCoordinatesOriginDestination] = useState([])
    const [rangeSliderValue, setValue] = useState([0, 100])
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const filteredData = useSelector(selectFilteredData)
    const dispatch = useDispatch()
    // const websiteData = useSelector(selectWebsiteData)
    const module = "service_staff"
    const stations = useSelector(selectModuleSpecificData("stations"))
    let [stationGroupBySustance, setStationGroupBySustance] = useState([])
    const [routing, setRouting] = useState(false)
    const toggleMapTable = useSelector(selectMapViewStatus)
    const [historyModalStatus, setHistoryModalStatus] = useState(false)
    let [containerGroupBySustance, setContainerGroupBySustance] = useState([])
    //we are setting the filterdData like
    const [showSlider, setShowSlider] = useState(false)
    const [filteredResult, setFilteredResult] = useState([])
    // const vehicleHisotory = useSelector(selectModuleSpecificData("containers_history")) | []
    const history = module === "containers" ? "containers_history" : "vehicle_history"
    const vehicleHisotory = useSelector(selectModuleSpecificData(history))

    const app = sessionStorage.getItem("app")

    const [containersData, setContainersData] = useState([])
    const navigate = useNavigate()
    const display = useSelector((state) => state.filterBar.display)

    const [lockDialogConfirmationStatus, setLockDialogConfirmationStatus] = useState(false)
    const modalContext = useModalCtx()
    const datatableId = "routing_datatable"
    const [pathname, setPathName] = useState()
    const [selectedDevice, setSelectedDevice] = useState({})
    const [dialogConfirmationStatus, setDialogConfirmationStatus] = useState(false)
    const [isEditMode, setEditMode] = useState(false)
    const datatable = useSelector(selectModuleSpecificData(module))
    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext
    const deviceData = useSelector(selectModuleSpecificData("iot"))

    const data = useMemo(() => datatable, [datatable])

    useEffect(() => {
        setPathName(window.location.pathname)
    }, [window.location.pathname])

    useEffect(() => {
        const fitered_result_id = filteredData.map((item) => item.asset_id)
        const result = datatable.map((item) => {
            return {
                ...item,
                is_ghost: !fitered_result_id.includes(item.asset_id),
            }
        })

        setContainersData([...result])
    }, [filteredData, datatable])

    const containerHistoryRawData =
        useSelector(selectModuleSpecificRawData("containers_history")) || {}

    const [filters, setFilters] = useState({})

    const columns2 = useMemo(
        () =>
            computeColumns({
                data: vehicleHisotory,
                meta: containerHistoryRawData?.datatable?.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [vehicleHisotory, containerHistoryRawData],
    )

    const columns3 = React.useMemo(
        () =>
            computeColumns({
                data: vehicleHisotory,
                meta: vehicleData.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [vehicleHisotory],
    )

    useEffect(() => {
        if (!initialState.hasOwnProperty("iot")) {
            dispatch(
                addPage({
                    key: "iot",
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(fetchData({ module: "iot" }))
    }, [])
    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    useEffect(() => {
        APIInterval = setInterval(() => {
            if (routing === false && toggleMapTable === "map_view") {
                dispatch(fetchData({ module }, { reUpdateSourceData: false }))
            } else if (toggleMapTable === "table_view")
                dispatch(fetchData({ module }, { reUpdateSourceData: false }))
        }, 5000)

        return () => {
            clearInterval(APIInterval)
        }
    }, [routing, toggleMapTable])

    useEffect(() => {
        // setStationGroupBySustance({})
        // setContainerGroupBySustance({})
        if (module === "containers") {
            setStationGroupBySustance(
                _.groupBy(stations, ({ storage_type }) => storage_type.toLowerCase()),
            )
            setContainerGroupBySustance(
                _.groupBy(filteredData, ({ substance }) =>
                    substance ? substance.toLowerCase() : "",
                ),
            )
        }

        if (routing && toggleMapTable == "map_view") {
            drawPaths()
        }
    }, [filteredData, toggleMapTable])

    useEffect(() => {
        const timeout = setTimeout(() => {
            const formatFilters = Object.entries(filters).map((item) => ({
                id: item[0],
                value: item[1],
            }))

            datatableRef.current.updateFilter(formatFilters)
            dispatch(fetchData({ module }))
        }, 500)

        return () => {
            clearTimeout(timeout)
        }
    }, [filters])

    const updateFilters = () => {
        const storedFilters =
            JSON.parse(
                localStorage.getItem(`${window.location.pathname + app + datatableId}_filter`),
            ) || []

        const updateFilter = storedFilters.reduce((acc, item) => {
            acc[item.id] = item.value
            return acc
        }, {})

        if (!updateFilter.hasOwnProperty("percent_full")) {
            setValue([0, 100])
        }

        setFilters({ ...updateFilter })
        dispatch(setMapFilters(filters))
    }

    const memonizedFilters = useMemo(() => {
        return Object.entries(filters).map((data) => ({ id: data[0], value: data[1] }))
    }, [filters])

    useEffect(() => {
        updateFilters()
    }, [toggleMapTable])

    const columns = useMemo(
        () =>
            computeColumns({
                data: datatable,
                meta: datatable.meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [datatable],
    )

    const filterRoutingData = () => {
        setFilteredData(filteredData)
        setFilteredResult(filteredData)
    }

    const estimatedRunoutDays = _.sumBy(filteredData, (object) => object.estimated_runout_days)

    const amountToFillLiters = _.sumBy(filteredData, (object) => object.amount_to_fill_litres)

    const remainingFuelLitres = _.sumBy(filteredData, (object) => object.remaining_fuel_litres)

    const { values, errors, touched, resetForm, handleBlur, handleChange, setValues, submitForm } =
        useFormik({
            initialValues: {
                vehicle_id: "",
                battery_id: "",
                number_of_rides: "",
                performance: "",
                current_location: "",
                current_status: "",
                distance_travelled: "",
                vehicle_active_time: "",
                vehicle_down_time: "",
                number_of_different_riders: "",
                distance_since_last_inspection: "",
                controller_firmware_version: "",
                controller_id: null,
                imei: null,
            },
            onSubmit: () => {
                const obj = { ...values }
                Object.keys(obj).forEach((key) => {
                    if (obj[key] === null || obj[key] === "") {
                        obj[key] = null
                    }
                })

                if (isEditMode) {
                    dispatch(editData(obj))
                } else {
                    dispatch(addData(obj))
                }
            },
            validationSchema: VehicleFormValidations,
        })

    function calculateDistance(lat1, lon1, lat2, lon2) {
        const radius = 6371 // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180)
        const dLon = (lon2 - lon1) * (Math.PI / 180)
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
                Math.cos(lat2 * (Math.PI / 180)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = radius * c

        return distance
    }

    const drawPaths = () => {
        const routes = []
        let nearestStations = null
        const promises = []

        console.log("FILTERS DATA HEREL", filteredData)
        let groupedContainers = _.groupBy(filteredData, ({ app_key }) => app_key.toLowerCase())
        let groupedStations = _.groupBy(stations, ({ app_key }) => app_key.toLowerCase())

        console.log("containerGroupBySustance HERE:", groupedContainers, groupedStations)

        Object.keys(groupedStations).map((stationKey, index) => {
            let apiRequestBody = {}
            let nearestStation = groupedStations[stationKey][0]

            // console.log(
            //     "containerGroupBySustance stationKey:",
            //     // nearestStation,
            //     groupedStations[stationKey],
            //     groupedContainers[stationKey],
            // )

            apiRequestBody["0"] = [nearestStation.latitude, nearestStation.longitude]

            if (groupedContainers[stationKey]) {
                groupedContainers[stationKey].map((container) => {
                    if (container.latitude && container.longitude) {
                        let distance = calculateDistance(
                            nearestStation.latitude,
                            nearestStation.longitude,
                            container.latitude,
                            container.longitude,
                        )

                        console.log("DISHANCE:", ceil(distance))
                        if (ceil(distance) < 50)
                            apiRequestBody[container.id] = [container.latitude, container.longitude]
                    }
                })
                console.log("groupedContainers[stationKey]:", apiRequestBody)

                const formData = new FormData()

                formData.append(
                    "data",
                    JSON.stringify({
                        project: "efleety",
                        format_type: "json",
                        model: "tspv1",
                        data: apiRequestBody,
                    }),
                )

                const promise = axios({
                    method: "POST",
                    url: "https://routing.wavsmart.efleety.com/api/v1/sv-infer-F",
                    data: formData,
                })

                promises.push(promise)
            }
        })

        Promise.all(promises).then((result) => {
            result.map((response, resultIndex) => {
                console.log("containerGroupBySustance Response: ", response)
                let waypoints = []
                let origin = ""
                let shortestRoute = []

                shortestRoute = response.data.message.route

                // if (response.data.message.route.length < 25) {
                waypoints = shortestRoute.reduce((waypoints, item, index) => {
                    waypoints.push({
                        container: filteredData.find((data) => {
                            if (data.id == item[0]) return data
                        }),
                        latitude: item[1],
                        longitude: item[2],
                    })

                    return waypoints
                }, [])

                const chunks = _.chunk(waypoints, 25)
                chunks.map((chunk, indexChunk) => {
                    let assetsData = chunk.map((data) => data.container).filter((item) => !!item)

                    if (indexChunk == 0) {
                        origin = [chunk[0].latitude, chunk[0].longitude]
                    } else {
                        if (indexChunk > 0) {
                            origin = [
                                chunks[indexChunk - 1].at(-1).latitude,
                                chunks[indexChunk - 1].at(-1).longitude,
                            ]
                        }
                    }

                    waypoints = chunk.slice(1, chunk.length - 1)

                    if (assetsData.length > 0)
                        routes.push({
                            assetsData,
                            zone: assetsData[0]?.app_key || "",
                            station: nearestStations,
                            waypoints,
                            origin,
                            destination: [
                                chunk[chunk.length - 1].latitude,
                                chunk[chunk.length - 1].longitude,
                            ],
                        })

                    console.log("ROUTES Here Fix:", routes)
                })

                // } else {
                //     console.log("response.data.message.route:", response.data.message.route)
                // }
            })

            console.log("containerGroupBySustance routes:", routes)

            mapReference.current.routesReset()
            mapReference.current.coordinates = routes
            setCoordinatesOriginDestination(routes)
            mapReference.current.createRoute()
            // mapReference.current.setZoom(15)
            dispatch(setMapViewStatus("map_view"))
        })
    }

    const drawPaths2 = () => {
        const routes = []
        let nearestStations = null
        let apiRequestBody = {}
        const promises = []
        Object.keys(containerGroupBySustance).map((substance) => {
            if (
                Object.keys(stationGroupBySustance).includes(substance) &&
                stationGroupBySustance[substance]
            ) {
                console.log(
                    "responseL Container Group By Substance:",
                    containerGroupBySustance[substance],
                    stationGroupBySustance[substance] ? "True" : "false",
                )
                containerGroupBySustance[substance].map((container, index) => {
                    nearestStations = stationGroupBySustance[container.substance.toLowerCase()][0] //selecting first station as we don't have any other stations or nearest stasion
                    if (nearestStations) {
                        apiRequestBody["0"] =
                            x[(nearestStations.latitude, nearestStations.longitude)]
                    }

                    if (container.latitude && container.longitude)
                        apiRequestBody[container.id] = [container.latitude, container.longitude]
                })

                const formData = new FormData()

                const is_subscribed = true
                if (is_subscribed && Object.keys(apiRequestBody).length > 25) {
                    console.log(
                        "inside if---->",
                        Object.keys(apiRequestBody).length,
                        apiRequestBody,
                    )
                    formData.append(
                        "data",
                        JSON.stringify({
                            project: "efleety",
                            format_type: "json",
                            model: "tspv1",
                            data: apiRequestBody,
                        }),
                    )
                } else {
                    console.log("USER IS NOT SUBSRIBED", filteredData)
                    const datahere = [...filteredData]
                    const topOverflowingContainers = datahere
                        .sort((a, b) => b.percent_full - a.percent_full)
                        .splice(0, 20)
                    console.log(
                        "USER IS NOT SUBSRIBED topOverflowingContainers",
                        topOverflowingContainers,
                    )

                    apiRequestBody = topOverflowingContainers.reduce(
                        (a, v) => ({ ...a, [v.id]: [v.latitude, v.longitude] }),
                        [],
                    )

                    apiRequestBody["0"] = [nearestStations.latitude, nearestStations.longitude]

                    console.log(
                        "USER IS NOT SUBSRIBED apiRequestBody",
                        apiRequestBody,
                        Object.keys(apiRequestBody).length,
                    )
                    formData.append(
                        "data",
                        JSON.stringify({
                            project: "efleety",
                            format_type: "json",
                            model: "tspv1",
                            data: apiRequestBody,
                        }),
                    )
                }

                const promise = axios({
                    method: "POST",
                    url: "https://routing.wavsmart.efleety.com/api/v1/sv-infer-F",
                    data: formData,
                })

                promises.push(promise)
            }
        })

        Promise.all(promises).then((result) => {
            result.map((response, resultIndex) => {
                let waypoints = []
                let origin = ""
                let shortestRoute = []

                shortestRoute = response.data.message.route

                waypoints = shortestRoute.reduce((waypoints, item, index) => {
                    waypoints.push({
                        latitude: item[1],
                        longitude: item[2],
                    })

                    return waypoints
                }, [])

                const chunks = _.chunk(waypoints, 25)
                chunks.map((chunk, indexChunk) => {
                    if (indexChunk == 0) {
                        origin = [chunk[0].latitude, chunk[0].longitude]
                    } else {
                        if (indexChunk > 0) {
                            origin = [
                                chunks[indexChunk - 1].at(-1).latitude,
                                chunks[indexChunk - 1].at(-1).longitude,
                            ]
                        }
                    }

                    waypoints = chunk.slice(1, chunk.length - 1)

                    routes.push({
                        station: nearestStations,
                        waypoints,
                        origin,
                        destination: [
                            chunk[chunk.length - 1].latitude,
                            chunk[chunk.length - 1].longitude,
                        ],
                    })
                })
            })

            mapReference.current.routesReset()
            mapReference.current.coordinates = routes
            setCoordinatesOriginDestination(routes)
            mapReference.current.createRoute()
            // mapReference.current.setZoom(15)
            dispatch(setMapViewStatus("map_view"))
        })
    }
    // (data) => {
    //     setStationGroupBySustance(
    //         _.groupBy(stations, ({ storage_type }) => storage_type.toLowerCase()),
    //     )
    //     setContainerGroupBySustance(
    //         _.groupBy(data, ({ substance }) => (substance ? substance.toLowerCase() : "")),
    //     )
    //     drawPaths()
    // }

    const changeFilterState = (event, column) => {
        filters[column.id] = event.target.value
        setFilters({ ...filters })
        dispatch(setMapFilters(filters))
    }

    const fetchFilterValue = useCallback(
        (column) => {
            return filters[column.id]
        },
        [filters],
    )

    const renderFilterMemonized = useCallback(renderFilter, [])

    const closeRoute = () => {
        datatableRef.current.clearFilter()
        setRouting(false)
        updateFilters()
        dispatch(setMapFilters([]))
        localStorage.setItem("showMoreInfo", "false")
    }

    const renderSaveButton = (data) => {
        return (
            <div className="p-2 pt-1 d-flex justify-content-around" style={{ gap: "10px" }}>
                <Button
                    id="saveButton"
                    onClick={() => {
                        let assetsIds = []
                        let assetIdsConsideredInRoutes = mapReference.coordinates.reduce(
                            (assetsIds, data) => {
                                assetsIds.push(data.assetsData.map((item) => item.asset_id))
                                return assetsIds
                            },
                            assetsIds,
                        )

                        let total_distance = 0

                        // console.log("SAVE ROUTE:", data, mapReference.coordinates, {
                        //     entity_ids: assetIdsConsideredInRoutes.flat(),
                        //     total_containers: assetIdsConsideredInRoutes.flat().length,
                        //     total_estimated_runout_days: estimatedRunoutDays,
                        //     total_amount_to_fill_litres: amountToFillLiters,
                        //     total_remaining_fuel_litres: remainingFuelLitres,
                        //     total_waste: filteredData.reduce((acc, data) => {
                        //         acc += data.fill_level
                        //         return acc
                        //     }, 0),
                        //     total_distance_km:
                        //         data.reduce((total_distance, route) => {
                        //             total_distance = total_distance + route.distance
                        //             return total_distance
                        //         }, 0) / 1000,
                        //     total_distance: data.reduce((total_distance, route) => {
                        //         total_distance = total_distance + route.distance
                        //         return total_distance
                        //     }, 0),
                        //     routes: mapReference.coordinates,
                        //     substance: [
                        //         ...new Set(filteredData.map((item) => item.substance)),
                        //     ].join(","), // This key (substance) was added for sending comma separated string values (unique), for example "LPG,Kerosene,RedDiesel"
                        // })
                        dispatch(
                            saveRoute({
                                entity_ids: assetIdsConsideredInRoutes.flat(),
                                total_containers: assetIdsConsideredInRoutes.flat().length,
                                total_estimated_runout_days: estimatedRunoutDays,
                                total_amount_to_fill_litres: amountToFillLiters,
                                total_remaining_fuel_litres: remainingFuelLitres,
                                total_waste: filteredData.reduce((acc, data) => {
                                    acc += data.fill_level
                                    return acc
                                }, 0),
                                total_distance_km:
                                    data.reduce((total_distance, route) => {
                                        total_distance = total_distance + route.distance
                                        return total_distance
                                    }, 0) / 1000,
                                total_distance: data.reduce((total_distance, route) => {
                                    total_distance = total_distance + route.distance
                                    return total_distance
                                }, 0),
                                routes: mapReference.coordinates,
                                substance: [
                                    ...new Set(filteredData.map((item) => item.substance)),
                                ].join(","), // This key (substance) was added for sending comma separated string values (unique), for example "LPG,Kerosene,RedDiesel"
                            }),
                        )
                        // dispatch(setMapViewStatus("map_view"))
                        // setTimeout(() => {
                        //     window.location.href = "/routing"
                        // }, 1000)

                        // navigate("/routing")
                    }}
                    style={{ width: "50%" }}
                    // className="clear-map-filters-save"
                    variant="secondary"
                >
                    Save
                </Button>
                <Button
                    onClick={() => {
                        datatableRef.current.clearFilter()
                        updateFilters()
                        setRouting(false)
                        dispatch(setMapFilters([]))
                        localStorage.setItem("showMoreInfo", "false")
                    }}
                    // className="clear-map-filters"
                    variant="secondary"
                    style={{ width: "50%" }}
                >
                    Cancel
                </Button>
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: "white", height: "100%" }}>
            <div className={`map-view ${toggleMapTable == "map_view" ? "show" : "hide"}`}>
                <div
                    className="map-filters"
                    style={{
                        display: `${display}`,
                        height: `${window.innerHeight - 64}px`,
                    }}
                >
                    {datatableRef.current &&
                        datatableRef.current.headerGroups.map((headerGroup, index) => (
                            <div key={index}>
                                {headerGroup.headers.map((column, index2) => {
                                    return (
                                        <div
                                            style={{ minWidth: "120px", paddingBottom: "10px" }}
                                            key={index2}
                                            {...(column.id === "percent_full" && {
                                                onMouseEnter: () => setShowSlider(true),
                                                onMouseLeave: () => setShowSlider(false),
                                            })}
                                        >
                                            <div
                                                style={{
                                                    textAlign: "start",
                                                    padding: "5px 0px",
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {_.capitalize(column.render("Header"))}
                                            </div>
                                            {column.canFilter ? (
                                                <div style={{ marginTop: "0px" }}>
                                                    {renderFilterMemonized({
                                                        showSlider,
                                                        setShowSlider,
                                                        filterType: column.filters.filterType,
                                                        data: column,
                                                        onChange: (e) =>
                                                            changeFilterState(e, column),
                                                        value: filters[column.id],
                                                        handleRangeChange: (e, newValue) => {
                                                            {
                                                                /* filters[column.id] = newValue */
                                                            }
                                                            setFilters({
                                                                ...filters,
                                                                [column.id]: newValue,
                                                            })
                                                            dispatch(setMapFilters(filters))
                                                            // column.setFilter(newValue)
                                                            // setValue(newValue)
                                                        },
                                                        handleRangeModal: () => {},
                                                        rangefilterValue: filters[column.id]
                                                            ? filters[column.id]
                                                            : rangeSliderValue,
                                                        // rangefilterValue: column.filterValue
                                                        //     ? column.filterValue
                                                        //     : rangeSliderValue,
                                                    })}
                                                </div>
                                            ) : null}
                                        </div>
                                    )
                                })}
                            </div>
                        ))}
                </div>
                {Object.keys(filters).length > 0 && (
                    <>
                        <Button
                            style={{
                                marginRight:
                                    module === "containers" || module === "vehicles"
                                        ? coordinatesOriginDestination.length > 0
                                            ? "170px"
                                            : "95px"
                                        : null,
                            }}
                            onClick={() => {
                                datatableRef.current.clearFilter()
                                updateFilters()
                                dispatch(setMapFilters([]))
                            }}
                            className="clear-map-filters"
                            variant="secondary"
                        >
                            Clear Filter
                        </Button>

                        <div
                            style={{
                                marginLeft: "60px",
                                marginTop: "20px",
                                position: "absolute",
                                zIndex: "12",
                                right:
                                    module === "containers" || module === "vehicles"
                                        ? coordinatesOriginDestination.length > 0
                                            ? "500px"
                                            : "420px"
                                        : "330px",
                                display: "flex",
                            }}
                        >
                            <DashboardFiltersOnMap filters={filters} module={module} />
                        </div>
                    </>
                )}
                {["/containers", "/vehicles"].includes(pathname) ? (
                    <>
                        <Button
                            onClick={() => {
                                drawPaths()
                                setRouting(true)
                            }}
                            className="clear-map-filters"
                            variant="secondary"
                        >
                            Routing
                        </Button>
                        {/* <div>
                            {coordinatesOriginDestination.length > 0 && (
                                <Button
                                    id="saveButton"
                                    onClick={() => {
                                        dispatch(
                                            saveRoute({
                                                container_ids: filteredData.map(
                                                    (item) => item.device_id,
                                                ),
                                                total_containers: filteredData.length,
                                                total_estimated_runout_days: estimatedRunoutDays,
                                                total_amount_to_fill_litres: amountToFillLiters,
                                                total_remaining_fuel_litres: remainingFuelLitres,
                                                total_distance: _.sumBy(
                                                    mapReference.coordinates,
                                                    (object) => object.distance,
                                                ),
                                                routes: mapReference.coordinates,
                                                substance: [
                                                    ...new Set(
                                                        filteredData.map((item) => item.substance),
                                                    ),
                                                ].join(","), // This key (substance) was added for sending comma separated string values (unique), for example "LPG,Kerosene,RedDiesel"
                                            }),
                                        )
                                    }}
                                    className="clear-map-filters-save"
                                    variant="secondary"
                                >
                                    Save
                                </Button>
                            )}
                        </div> */}
                    </>
                ) : null}

                <div id="map">
                    {stations && (
                        <MapsWithMultipleRoute
                            module={module}
                            generateRoute={drawPaths}
                            id="filter-map1"
                            ref={mapReference}
                            filteredData={[...filteredData]}
                            coordinates={coordinatesOriginDestination}
                            data={[...containersData, ...stations]}
                            stations={[]}
                            styles={display == "block" ? { left: "15%", width: "85%" } : {}}
                            renderSaveButton={renderSaveButton}
                            closeRoute={closeRoute}
                        />
                    )}
                </div>
            </div>
            <div className={`${toggleMapTable == "map_view" ? "hide" : "show"}`}>
                {["/containers"].includes(pathname) ? (
                    data.length > 0 && (
                        <Datatable
                            appliedFilters={memonizedFilters}
                            id="routing_datatable"
                            ref={datatableRef}
                            // buttons={[
                            //     {
                            //         index: 1,
                            //         text: "Map View",
                            //         icon: <AddIcon />,
                            //         action: () => {
                            //             setToggleMapTable(!toggleMapTable)
                            //         },
                            //     },
                            // ]}
                            filteredResult={filteredData}
                            addActionName="Routing"
                            showextraActionbtn={coordinatesOriginDestination.length > 0}
                            extraActionbtnName="Save"
                            extraBtnAction={() => {
                                dispatch(
                                    saveRoute({
                                        entity_ids: filteredData.map((item) => item.device_id),
                                        total_containers: filteredData.length,
                                        total_estimated_runout_days: estimatedRunoutDays,
                                        total_amount_to_fill_litres: amountToFillLiters,
                                        total_remaining_fuel_litres: remainingFuelLitres,
                                        total_distance: _.sumBy(
                                            mapReference.coordinates,
                                            (object) => object.distance,
                                        ),
                                        routes: mapReference.coordinates,
                                        substance: [
                                            ...new Set(filteredData.map((item) => item.substance)),
                                        ].join(","), // This key (substance) was added for sending comma separated string values (unique), for example "LPG,Kerosene,RedDiesel"
                                    }),
                                )
                            }}
                            addAction={() => {
                                drawPaths()
                                mapReference.current.createRoute()
                            }}
                            refreshAction={() => {
                                setCoordinatesOriginDestination([])
                                mapReference.current.routesReset()
                                dispatch(fetchData({ module }))
                            }}
                            datatableTitle="Containers"
                            sortable_key="vehicle_id"
                            modalStatus={status}
                            modalContent={ModalContentMemonized}
                            handleModalStatus={setModalStatus}
                            fetchFilterValue={fetchFilterValue}
                            changeFilterState={changeFilterState}
                            columns={columns}
                            data={data}
                            actions={[
                                {
                                    name: "location",
                                    type: "location",
                                    buttonType: "icon",
                                },
                                {
                                    name: "history",
                                    type: "history",
                                    buttonType: "icon",
                                },
                            ]}
                            onActionClick={(event, { type, data, trip_id }) => {
                                setInformationToView(data)
                                if (type == "location") {
                                    mapReference.current.routesReset()
                                    mapReference.current.setCenter({
                                        lat: data.values.latitude,
                                        lng: data.values.longitude,
                                    })
                                    mapReference.current.setZoom(15)
                                    dispatch(setMapViewStatus("map_view"))
                                    document.getElementById("map").scrollIntoView()
                                } else if (type == "history") {
                                    dispatch(
                                        addPage({
                                            key: "containers_history",
                                            data: initialState.vehicles,
                                        }),
                                    )
                                    dispatch(
                                        fetchData({
                                            module: "containers_history",
                                            device_id: data.values.device_id,
                                        }),
                                    )
                                    setHistoryModalStatus(true)
                                }
                            }}
                        />
                    )
                ) : (
                    <>
                        {data.length > 0 && (
                            <Datatable
                                module={module}
                                appliedFilters={memonizedFilters}
                                id="routing_datatable"
                                ref={datatableRef}
                                // buttons={[
                                //     {
                                //         index: 1,
                                //         text: "Map View",
                                //         icon: <AddIcon />,
                                //         action: () => {
                                //             setToggleMapTable(!toggleMapTable)
                                //         },
                                //     },
                                // ]}
                                filteredResult={filteredData}
                                addActionName="Add Vehicle"
                                showextraActionbtn={coordinatesOriginDestination.length > 0}
                                extraActionbtnName="Save"
                                extraBtnAction={() => {
                                    dispatch(
                                        saveRoute({
                                            entity_ids: filteredData.map((item) => item.device_id),
                                            total_containers: filteredData.length,
                                            total_estimated_runout_days: estimatedRunoutDays,
                                            total_amount_to_fill_litres: amountToFillLiters,
                                            total_remaining_fuel_litres: remainingFuelLitres,
                                            total_distance: _.sumBy(
                                                mapReference.coordinates,
                                                (object) => object.distance,
                                            ),
                                            routes: mapReference.coordinates,
                                            substance: [
                                                ...new Set(
                                                    filteredData.map((item) => item.substance),
                                                ),
                                            ].join(","), // This key (substance) was added for sending comma separated string values (unique), for example "LPG,Kerosene,RedDiesel"
                                        }),
                                    )
                                }}
                                addAction={() => {
                                    // drawPaths()
                                    handleFormModalStatus(true)
                                    // mapReference.current.createRoute()
                                }}
                                refreshAction={() => {
                                    setCoordinatesOriginDestination([])
                                    mapReference.current.routesReset()
                                    dispatch(fetchData({ module }))
                                }}
                                datatableTitle={_.capitalize(module)}
                                sortable_key="vehicle_id"
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                fetchFilterValue={fetchFilterValue}
                                changeFilterState={changeFilterState}
                                columns={columns}
                                data={data}
                                actions={[
                                    {
                                        name: "Send",
                                        type: "lock",
                                        buttonType: "icon",
                                    },
                                    // { name: "Delete", type: "end_ride", buttonType: "icon" },
                                    { name: "history", type: "history", buttonType: "icon" },
                                    { name: "Edit", type: "edit", buttonType: "icon" },
                                    { name: "Delete", type: "delete", buttonType: "icon" },
                                    { name: "location", type: "location", buttonType: "icon" },
                                ]}
                                onActionClick={(event, { type, data, trip_id }) => {
                                    setInformationToView(data)
                                    setSelectedDevice(data)
                                    console.log("here is the data", data)
                                    setValues(data.original)
                                    console.log(data)
                                    if (type == "edit") {
                                        setSelectedDevice(data)
                                        setEditMode(true)
                                        handleFormModalStatus(true)
                                    } else if (type == "location") {
                                        console.log("sfwa", data)
                                        // mapReference.current.routesReset()
                                        mapReference.current.setCenter({
                                            lat: data.values.lat,
                                            lng: data.values.long,
                                        })
                                        mapReference.current.setZoom(15)
                                        dispatch(setMapViewStatus("map_view"))
                                        document.getElementById("map").scrollIntoView()
                                    } else if (type == "history") {
                                        dispatch(
                                            addPage({
                                                key: "service_staff_history",
                                                data: initialState.vehicles,
                                            }),
                                        )
                                        dispatch(
                                            fetchData({
                                                module: "service_staff_history",
                                                service_staff_id: data.values.service_staff_id,
                                            }),
                                        )
                                        setHistoryModalStatus(true)
                                    } else if (type == "lock") {
                                        setLockDialogConfirmationStatus(true)
                                    } else if (type == "delete") {
                                        setDialogConfirmationStatus(true)
                                    }
                                }}
                            />
                        )}
                    </>
                )}
            </div>
            <Modal
                fullWidth
                maxWidth="sm"
                onClose={() => setDialogConfirmationStatus(false)}
                modalHeading="Confirm"
                status={dialogConfirmationStatus}
                onSave={() => {
                    dispatch(deleteData(values))
                    setDialogConfirmationStatus(false)
                }}
            >
                {" "}
                Are you sure want to delete this IOT?
            </Modal>
            {selectedDevice && (
                <Modal
                    modalHeading={isEditMode ? "Edit" : "Add"}
                    fullWidth
                    maxWidth="md"
                    status={formModalStatus}
                    onClose={() => {
                        resetForm()
                        handleFormModalStatus(false)
                    }}
                    onSave={submitForm}
                >
                    <br />
                    {isEditMode ? (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Vehicle ID*"
                                    name="vehicle_id"
                                    disabled={!!isEditMode}
                                    error={!!(errors.vehicle_id && touched.vehicle_id)}
                                    helperText={
                                        errors.vehicle_id && touched.vehicle_id
                                            ? errors.vehicle_id
                                            : false
                                    }
                                    value={values.vehicle_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle ID"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Device Id*"
                                    select={true}
                                    // disabled={isEditMode ? true : false}
                                    name="iot_id"
                                    value={values.iot_id}
                                    error={!!(errors.iot_id && touched.iot_id)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Device Id"
                                    fullWidth
                                >
                                    <MenuItem selected disabled value="select">
                                        Select Device Id
                                    </MenuItem>
                                    {deviceData?.map((device, index) => (
                                        <MenuItem key={index} value={device.id}>
                                            {`${device.name} (${device.imei})`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {/* <Grid xs={6} item>
              <TextField
                label="Battery*"
                placeholder="Select battery"
                select={true}
                name="battery_id"
                value={values.battery_id}
                error={errors.battery_id && touched.battery_id ? true : false}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              >
                <MenuItem selected disabled value="select">
                  Select battery
                </MenuItem>
                {batteryData?.map((battery, index) => (
                  <MenuItem key={index} value={battery.battery_id}>
                    {battery.battery_id}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText
                error={errors.battery_id && touched.battery_id ? true : false}
              >
                {errors.battery_id && touched.battery_id
                  ? errors.battery_id
                  : ''}
              </FormHelperText>
            </Grid> 
            <Grid xs={6} item>
              <TextField
                label="IMEI"
                name="imei"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                error={errors.imei && touched.imei ? true : false}
                helperText={errors.imei && touched.imei ? errors.imei : false}
                value={values.imei}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="IMEI Number"
              />
            </Grid>
            {/* <Grid xs={6} item>
              <TextField
                label="Controller ID"
                type="number"
                name="controller_id"
                InputProps={{ inputProps: { min: 0 } }}
                error={
                  errors.controller_id && touched.controller_id ? true : false
                }
                helperText={
                  errors.controller_id && touched.controller_id
                    ? errors.controller_id
                    : false
                }
                value={values.controller_id}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Controller ID"
              />
            </Grid> */}
                            {/* <Grid xs={6} item>
              <TextField
                label="Controller Firmware Version"
                name="controller_firmware_version"
                error={
                  errors.controller_firmware_version &&
                  touched.controller_firmware_version
                    ? true
                    : false
                }
                helperText={
                  errors.controller_firmware_version &&
                  touched.controller_firmware_version
                    ? errors.controller_firmware_version
                    : false
                }
                value={values.controller_firmware_version}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                placeholder="Controller Firmware Version"
              />
            </Grid> */}
                            <Grid xs={6} item>
                                <TextField
                                    label="Number of Rides"
                                    type="number"
                                    name="number_of_rides"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    error={!!(errors.number_of_rides && touched.number_of_rides)}
                                    helperText={
                                        errors.number_of_rides && touched.number_of_rides
                                            ? errors.number_of_rides
                                            : false
                                    }
                                    value={values.number_of_rides}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Number of Rides"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    label="Performance"
                                    name="performance"
                                    type="string"
                                    error={!!(errors.performance && touched.performance)}
                                    helperText={
                                        errors.performance && touched.performance
                                            ? errors.performance
                                            : false
                                    }
                                    value={values.performance}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Performance"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    label="Current Location"
                                    name="current_location"
                                    error={!!(errors.current_location && touched.current_location)}
                                    helperText={
                                        errors.current_location && touched.current_location
                                            ? errors.current_location
                                            : false
                                    }
                                    value={values.current_location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Current location"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    label="Current Status"
                                    name="current_status"
                                    error={!!(errors.current_status && touched.current_status)}
                                    helperText={
                                        errors.current_status && touched.current_status
                                            ? errors.current_status
                                            : false
                                    }
                                    value={values.current_status}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Current status"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    label="Distance travelled"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    name="distance_travelled"
                                    error={
                                        !!(errors.distance_travelled && touched.distance_travelled)
                                    }
                                    helperText={
                                        errors.distance_travelled && touched.distance_travelled
                                            ? errors.distance_travelled
                                            : false
                                    }
                                    value={values.distance_travelled}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Distance travelled"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Distance Since Last Inspection"
                                    name="distance_since_last_inspection"
                                    error={
                                        !!(
                                            errors.distance_since_last_inspection &&
                                            touched.distance_since_last_inspection
                                        )
                                    }
                                    helperText={
                                        errors.distance_since_last_inspection &&
                                        touched.distance_since_last_inspection
                                            ? errors.distance_since_last_inspection
                                            : false
                                    }
                                    value={values.distance_since_last_inspection}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="distance since travelled"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Vehicle active time"
                                    name="vehicle_active_time"
                                    error={
                                        !!(
                                            errors.vehicle_active_time &&
                                            touched.vehicle_active_time
                                        )
                                    }
                                    helperText={
                                        errors.vehicle_active_time && touched.vehicle_active_time
                                            ? errors.vehicle_active_time
                                            : false
                                    }
                                    value={values.vehicle_active_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle active time"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Vehicle down time"
                                    name="vehicle_down_time"
                                    error={
                                        !!(errors.vehicle_down_time && touched.vehicle_down_time)
                                    }
                                    helperText={
                                        errors.vehicle_down_time && touched.vehicle_down_time
                                            ? errors.vehicle_down_time
                                            : false
                                    }
                                    value={values.vehicle_down_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle down time"
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    label="Number of different rides"
                                    name="number_of_different_riders"
                                    error={
                                        !!(
                                            errors.number_of_different_riders &&
                                            touched.number_of_different_riders
                                        )
                                    }
                                    helperText={
                                        errors.number_of_different_riders &&
                                        touched.number_of_different_riders
                                            ? errors.number_of_different_riders
                                            : false
                                    }
                                    value={values.number_of_different_riders}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Number of rides"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Vehicle Type"
                                    name="vehicle_type"
                                    select={true}
                                    error={!!(errors.vehicle_type && touched.vehicle_type)}
                                    helperText={
                                        errors.vehicle_type && touched.vehicle_type
                                            ? errors.vehicle_type
                                            : false
                                    }
                                    value={values.vehicle_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle Type"
                                >
                                    <MenuItem value={"Bike"}>Bike</MenuItem>
                                    <MenuItem value={"Scooter"}>Scooter</MenuItem>
                                    <MenuItem value={"Delivery - KG3"}>Delivery - KG3</MenuItem>
                                    <MenuItem value={"Delivery - KG4"}>Delivery - KG4</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Vehicle ID*"
                                    name="vehicle_id"
                                    disabled={!!isEditMode}
                                    error={!!(errors.vehicle_id && touched.vehicle_id)}
                                    helperText={
                                        errors.vehicle_id && touched.vehicle_id
                                            ? errors.vehicle_id
                                            : false
                                    }
                                    value={values.vehicle_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle ID"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Device Id"
                                    select={true}
                                    // disabled={isEditMode ? true : false}
                                    name="iot_id"
                                    value={values.iot_id}
                                    error={!!(errors.iot_id && touched.iot_id)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Device Id"
                                    fullWidth
                                >
                                    <MenuItem selected disabled value="select">
                                        Select Device Id
                                    </MenuItem>
                                    {deviceData?.map((device, index) => (
                                        <MenuItem key={index} value={device.id}>
                                            {`${device.name} (${device.imei})`}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Vehicle Type"
                                    name="vehicle_type"
                                    select={true}
                                    error={!!(errors.vehicle_type && touched.vehicle_type)}
                                    helperText={
                                        errors.vehicle_type && touched.vehicle_type
                                            ? errors.vehicle_type
                                            : false
                                    }
                                    value={values.vehicle_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    placeholder="Vehicle Type"
                                >
                                    <MenuItem value={"Bike"}>Bike</MenuItem>
                                    <MenuItem value={"Scooter"}>Scooter</MenuItem>
                                    <MenuItem value={"Delivery - KG3"}>Delivery - KG3</MenuItem>
                                    <MenuItem value={"Delivery - KG4"}>Delivery - KG4</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    )}
                </Modal>
            )}
            {selectedDevice && (
                <Modal
                    fullWidth
                    maxWidth="sm"
                    onClose={() => setLockDialogConfirmationStatus(false)}
                    modalHeading="Confirm"
                    status={lockDialogConfirmationStatus}
                    onSave={() => {
                        dispatch(
                            unlockDevice({
                                imei: selectedDevice.values.imei,
                                // module:""
                            }),
                        )
                        dispatch(fetchData({ module: "iot" }))
                        setLockDialogConfirmationStatus(false)
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            Are you sure want to unlock this IOT?
                        </Grid>
                    </Grid>
                </Modal>
            )}
            {module === "containers" && (
                <Modal
                    disableSave={true}
                    fullWidth
                    maxWidth="md"
                    onClose={() => {
                        const datatableId = "container_history"
                        const app = sessionStorage.getItem("app")

                        localStorage.setItem(
                            `${window.location.pathname + app + datatableId}_filter`,
                            JSON.stringify([]),
                        )
                        localStorage.setItem(
                            `${window.location.pathname + app + datatableId}_sort`,
                            JSON.stringify([]),
                        )
                        localStorage.setItem(
                            `${window.location.pathname + app + datatableId}_pageIndex`,
                            0,
                        )
                        setHistoryModalStatus(false)
                    }}
                    modalHeading="Container History"
                    status={historyModalStatus}
                    onSave={() => {
                        setHistoryModalStatus(false)
                    }}
                >
                    <div style={{ padding: "0px 40px" }}>
                        <Grid container spacing={2} xs={12}>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">ID</div>
                                    <div>{vehicleHisotory[0]?.id}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Device ID</div>
                                    <div>{vehicleHisotory[0]?.device_id}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">
                                        API Trigger Level Percent
                                    </div>
                                    <div>{vehicleHisotory[0]?.api_trigger_level_percent}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Substance</div>
                                    <div>{vehicleHisotory[0]?.substance}</div>
                                </div>
                            </Grid>

                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">
                                        Amount To Fill Litres
                                    </div>
                                    <div>{vehicleHisotory[0]?.amount_to_fill_litres}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">
                                        Remaining Fuel Litres
                                    </div>
                                    <div>{vehicleHisotory[0]?.remaining_fuel_litress || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Ullage</div>
                                    <div>{vehicleHisotory[0]?.ullage || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Raw Distance</div>
                                    <div>{vehicleHisotory[0]?.raw_distance || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Temprature</div>
                                    <div>{vehicleHisotory[0]?.temprature || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Pick Up Event</div>
                                    <div>{vehicleHisotory[0]?.pick_up_event || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Fill Level</div>
                                    <div>{vehicleHisotory[0]?.fill_level || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Fill Error</div>
                                    <div>{vehicleHisotory[0]?.fill_error || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">SNR</div>
                                    <div>{vehicleHisotory[0]?.snr || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">RSSI</div>
                                    <div>{vehicleHisotory[0]?.rssi || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">
                                        Is Extended Uplink
                                    </div>
                                    <div>{vehicleHisotory[0]?.is_extended_uplink || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Type</div>
                                    <div>{vehicleHisotory[0]?.type || "-"}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Created At</div>
                                    <div>
                                        {moment(vehicleHisotory[0]?.created_at).format(
                                            "DD-MMM-YYYY HH:mm:ss",
                                        ) || "-"}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">Average Usage</div>
                                    <div>
                                        {formatAmount(vehicleHisotory[0]?.average_usage || "-")}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <div className="container-history-heading">
                                        Standard Deviation
                                    </div>
                                    <div>
                                        {formatAmount(vehicleHisotory[0]?.std_deviation || "-")}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid style={{ marginTop: "20px" }} container>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={12}>
                            {console.log("datatables-->", vehicleHisotory)}
                            <Datatable
                                filteredResult={filteredResult}
                                hasFilterAffectsMap={false}
                                datatableTitle="History"
                                id="container_history"
                                modalSize="xl"
                                refreshAction={() => {
                                    const { values } = informationToDisplay
                                    dispatch(
                                        addPage({
                                            key: "containers_history",
                                            data: initialState.vehicles,
                                        }),
                                    )
                                    dispatch(
                                        fetchData(
                                            {
                                                module: "containers_history",
                                                device_id: values.device_id,
                                            },
                                            {
                                                reUpdateSourceData: false,
                                            },
                                        ),
                                    )
                                }}
                                isMultiSelect={false}
                                columns={columns2}
                                data={vehicleHisotory}
                            />
                        </Grid>
                    </Grid>
                </Modal>
            )}
            {module === "vehicles" && (
                <Modal
                    disableSave={true}
                    fullWidth
                    maxWidth="md"
                    onClose={() => setHistoryModalStatus(false)}
                    modalHeading="Vehicle Ride History"
                    status={historyModalStatus}
                    onSave={() => {
                        setHistoryModalStatus(false)
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            {console.log("datadata", vehicleHisotory)}
                            <Datatable
                                modalSize="xl"
                                isMultiSelect={false}
                                columns={columns3}
                                data={vehicleHisotory}
                            />
                        </Grid>
                    </Grid>
                </Modal>
            )}
            {/* <!-- End Row --> */}
        </div>
    )
}

export default MaintenanceStaff
