import { createReducer } from "@reduxjs/toolkit"
import { logout, setAuthUser, setContainersData, setWebsites } from "../actions"

const initialState = {
    isAuthenticated: false,
    user: {},
    websites: [],
    containers: [],
}

const AuthReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setAuthUser, (state, action) => {
            localStorage.setItem("user", JSON.stringify(action.payload))
            state.user = action.payload
            state.isAuthenticated = true
        })
        .addCase(setContainersData, (state, action) => {
            state.containers = action.payload
        })
        .addCase(setWebsites, (state, action) => {
            localStorage.setItem("websites", JSON.stringify(action.payload))
            state.websites = action.payload
        })
        .addCase(logout, (state, action) => {
            state.isAuthenticated = false
            state.user = {}
        })
        .addDefaultCase((state, action) => state)
})

export default AuthReducer
