import { enqueueSnackbar } from "notistack"
import http from "../../http"

export const httpApiCall = async (payload) => {
    const { method, endpoint, key, params, headers } = payload
    const response = await http(method, endpoint, {
        apiData: params,
        headers,
    })
    console.log("response.data: ", response.data)
    return response.data
}

export const login = async (apiBody) => {
    const { payload } = apiBody
    const response = await http("POST", "api/v1/authenticate-user", {
        apiData: payload,
    })

    return response.data
}

export const fetchContainerByFilterApiCall = async (apiBody) => {
    const { payload } = apiBody
    const response = await http("POST", "api/v1/data", {
        apiData: payload,
    })

    console.log("response.data:", response.data)
    return response.data
    // return response.data.containers_data
}

export const saveRouteApiCall = async (apiBody) => {
    const { payload } = apiBody
    const response = await http("POST", "api/v1/routing/add", {
        apiData: payload,
    })

    enqueueSnackbar({
        message: "Route Saved Successfully",
        variant: "success",
    })

    return response.data
}
