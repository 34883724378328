import axios from "axios"
import {
    RidersData,
    SolarPanelDatatable,
    VehicleDatatable,
    batteryDatatable,
} from "../../Data/DummyData"
import TYPES from "../types"
import { setFilteredData, setModuleData } from "./actions"
import { takeEvery, put, call } from "redux-saga/effects"
import { fetchVehicle, fetchBatteries, fetchMaintainencePerson } from "./apis"

function* fetchVehicleData() {
    const data = yield call(fetchVehicle)
    yield put(setFilteredData(data))
    yield put(setModuleData(data))
}

function* fetchServiceData() {
    const data = yield call(fetchMaintainencePerson)
    yield put(setFilteredData(data))
    yield put(setModuleData(data))
}

function* fetchDeliveryVans() {
    const data = VehicleDatatable.data
    yield put(setFilteredData(data))
    yield put(setModuleData(data))
}

function* fetchDriversData() {
    const data = RidersData.data
    yield put(setFilteredData(data))
    yield put(setModuleData(data))
}

function* fetchBatteryData() {
    const data = yield call(fetchBatteries)
    // let data = batteryDatatable.data;
    yield put(setFilteredData(data))
    yield put(setModuleData(data))
}

function* fetchSolarPanelData() {
    const data = SolarPanelDatatable.data
    yield put(setFilteredData(data))
    yield put(setModuleData(data))
}

export function* AssetSaga() {
    yield takeEvery(TYPES.FETCH_SERVICE_DATA, fetchServiceData)
    yield takeEvery(TYPES.FETCH_DELIVERY_VANS, fetchDeliveryVans)
    yield takeEvery(TYPES.FETCH_DRIVERS_DATA, fetchDriversData),
        yield takeEvery(TYPES.FETCH_VEHICLE_DATA, fetchVehicleData)
    yield takeEvery(TYPES.FETCH_BATTERY_DATA, fetchBatteryData)
    yield takeEvery(TYPES.FETCH_SOLAR_PANEL_DATA, fetchSolarPanelData)
}
