import React, { useState } from "react"
import { GoogleMap, MarkerClusterer, MarkerF, useJsApiLoader } from "@react-google-maps/api"
import { selectWebsiteData } from "../../redux/general/selectors"
import { useSelector } from "react-redux"
import { Button } from "react-bootstrap"

const containerStyle = {
    width: "100%",
    height: "600px",
}

let mapref = null

const LocationSelectorMap = ({ onLocationSelect, defaultLocation }) => {
    const { isLoaded } = useJsApiLoader({
        id: "drawing-manager-example",
        googleMapsApiKey: "AIzaSyAmIDRYThXx6fd-ABjgTDUfQq8lf4ZSvac",
        libraries: ["drawing"],
    })

    const websiteData = useSelector(selectWebsiteData)
    const [centerPosition, setCurrentPosition] = useState(
        localStorage.getItem(`${websiteData.name}map_data`)
            ? JSON.parse(localStorage.getItem(`${websiteData.name}map_data`))
            : {
                  lat: parseFloat(websiteData.home_location_lat),
                  lng: parseFloat(websiteData.home_location_long),
              },
    )
    const [zoomLevel, setZoomLevel] = useState(
        localStorage.getItem(`${websiteData.name}zoom_data`)
            ? JSON.parse(localStorage.getItem(`${websiteData.name}zoom_data`))
            : 12,
    )

    const [markerPosition, setMarkerPosition] = useState(defaultLocation)

    const onMapLoad = (map) => {
        mapref = map
        // google.maps.event.addListener(map, 'dragend', function () {
        //   const newCenter = mapref.getCenter();
        //   localStorage.setItem(
        //     websiteData.name+'map_data',
        //     JSON.stringify({ lat: newCenter.lat(), lng: newCenter.lng() })
        //   );
        // });

        // google.maps.event.addListener(map, 'zoom_changed', function () {
        //   const newCenter = mapref.getZoom();
        //   localStorage.setItem(websiteData.name+'zoom_data', JSON.stringify(newCenter));
        // });
    }

    const handleMapClick = (event) => {
        const { latLng } = event
        setMarkerPosition(latLng.toJSON())
        onLocationSelect(latLng.toJSON())
    }

    return (
        <>
            {isLoaded ? (
                <GoogleMap
                    id="drawing-manager-example"
                    mapContainerStyle={containerStyle}
                    zoom={zoomLevel}
                    onLoad={(map) => onMapLoad(map)}
                    center={centerPosition}
                    onClick={handleMapClick}
                >
                    <div style={{ position: "absolute", top: 12, right: 60 }}>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setCurrentPosition({
                                    lat: parseFloat(websiteData.home_location_lat),
                                    lng: parseFloat(websiteData.home_location_long),
                                })
                                localStorage.setItem(
                                    `${websiteData.name}map_data`,
                                    JSON.stringify({
                                        lat: parseFloat(websiteData.home_location_lat),
                                        lng: parseFloat(websiteData.home_location_long),
                                    }),
                                )
                            }}
                        >
                            Home Location
                        </Button>
                    </div>

                    {markerPosition && <MarkerF position={markerPosition} />}
                </GoogleMap>
            ) : (
                ""
            )}
        </>
    )
}

export default LocationSelectorMap
