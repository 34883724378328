import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { RidersData } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { ModalContent, ModalContentAsset } from "../../Data/TablesData/Modals/ModalContent"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import AssetLocationMap from "../Maps/AssetLocationMap"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { useDispatch, useSelector } from "react-redux"
import { setModule } from "../../redux/assets/initialState"
import { fetchData } from "../../redux/assets/actions"
import { selectFilteredData } from "../../redux/assets/selectors"
import { DashboardStatistics } from "../../Data/DummyData"
import { useInfoCtx } from "../../providers/InfoCardProvider"

function Riders() {
    const dispatch = useDispatch()
    const { renderInfoCardData } = useInfoCtx()
    const filteredResult = useSelector(selectFilteredData)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = React.useMemo(() =>
        computeColumns({
            data: RidersData.data,
            meta: RidersData.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const data = React.useMemo(
        () => RidersData.data.filter((staff) => ["all", "rider"].includes(staff.employee_type)),
        [],
    )

    useEffect(() => {
        dispatch(setModule("drivers"))
        dispatch(fetchData({ module: "drivers" }))
    }, [])

    return (
        <div>
            <PageHeader titles="Riders" active="Riders" items={["Tables"]} />

            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        {DashboardStatistics.filter((item) => item.module === "drivers").map(
                            (card, index) => (
                                <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                                    <Card className="overflow-hidden">
                                        <Card.Body>
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">{card.heading}</h6>
                                                    <h2 className="mb-0 number-font">
                                                        {renderInfoCardData(card)}
                                                    </h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1"></div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ),
                        )}
                        {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Riders</h6>
                      <h2 className="mb-0 number-font">
                        {RidersData.data.length}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">On Duty</h6>
                      <h2 className="mb-0 number-font">
                        {
                          RidersData.data.filter((rider) => rider.on_duty)
                            .length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Off Duty</h6>
                      <h2 className="mb-0 number-font">
                        {
                          RidersData.data.filter((rider) => !rider.on_duty)
                            .length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
                    </Row>
                </Col>
            </Row>
            <Row className="row-sm">
                <Col lg={12}>
                    <div>
                        <AssetLocationMap
                            ids={RidersData.data.map((d) => d.name)}
                            type={"name"}
                            filteredResult={filteredResult}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Datatable</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Datatable
                                modalSize="xl"
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                            {/* <Savetable
                table_type="rider-datatable"
                hasAddFeature={false}
                columns={RidersData.COLUMNS}
                data={RidersData.DATATABLE}
              /> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Riders
