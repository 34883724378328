import { createAction } from "@reduxjs/toolkit"
import TYPES from "../types"

export const initialState = {
    module: "vehicles",
    vehicles: {
        datatable: {
            data: [],
            filteredData: [],
            meta: {},
        },
    },
    batteries: {
        datatable: {
            data: [],
            filteredData: [],
            meta: {},
        },
    },
    service_staff: {
        datatable: {
            data: [],
            filteredData: [],
            meta: {},
        },
    },
    delivery_vans: {
        datatable: {
            data: [],
            filteredData: [],
        },
    },
    drivers: {
        datatable: {
            data: [],
            filteredData: [],
        },
    },
    solar_panel: {
        datatable: {
            data: [],
            filteredData: [],
        },
    },
    dustbins: {
        datatable: {
            data: [],
            filteredData: [],
        },
    },
}

export const setModule = createAction(TYPES.SET_CURRENT_MODULE)
