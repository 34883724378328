const TYPES = {
    SET_REDUX_MODULE: "current/SET_REDUX_MODULE",
    SET_CURRENT_MENUITEM: "current/SET_CURRENT_MENUITEM",

    SET_AUTH_USER: "user/SET_AUTH_USER",
    SET_WEBSITES: "user/SET_WEBSITES",
    AUTHENTICATE_USER: "user/AUTHENTICATE_USER",
    LOGOUT_USER: "user/LOGOUT_USER",
    RESET_AUTH_STATE: "user/RESET_AUTH_STATE",

    SET_CURRENT_MODULE: "portal/SET_CURRENT_MODULE",
    PAGE_LOADING_STATUS: "portal/PAGE_LOADING_STATUS",
    WEBSITE: "portal/WEBSITE",
    SET_ACTIVE_WEBSITE: "portal/SET_ACTIVE_WEBSITE",
    TABLE_STATUS: "table/TABLE_STATUS",

    MAP_WAYPOINTS_API_CALL: "general/MAP_WAYPOINTS_API_CALL",
    SET_MAP_WAYPOINTS_API_RESPONSE: "general/SET_MAP_WAYPOINTS_API_RESPONSE",
    GENERAL_API_CALL: "general/GENERAL_API_CALL",
    SET_GENERAL_API_RESPONSE: "general/SET_GENERAL_API_RESPONSE",

    FETCH_VEHICLE_DATA: "asset/FETCH_VEHICLE_DATA",
    SET_VEHICLE_ASSET_DATA: "asset/vehicle/SET_VEHICLE_ASSET_DATA",
    SET_VEHICLE_ASSET_FILTERED_DATA: "asset/vehicle/SET_VEHICLE_ASSET_FILTERED_DATA",

    FETCH_BATTERY_DATA: "asset/FETCH_BATTERY_DATA",
    SET_BATTERY_ASSET_DATA: "asset/battery/SET_BATTERY_ASSET_DATA",

    FETCH_SOLAR_PANEL_DATA: "asset/FETCH_SOLAR_PANEL_DATA",
    FETCH_DRIVERS_DATA: "asset/FETCH_DRIVERS_DATA",
    FETCH_DELIVERY_VANS: "asset/FETCH_DELIVERY_VANS",
    FETCH_SERVICE_DATA: "asset/FETCH_SERVICE_DATA",
    FETCH_DUSTBINS_DATA: "asset/FETCH_DUSTBINS_DATA",
    SET_MODULE_DATA: "asset/module/SET_MODULE_DATA",
    SET_MODULE_FILTERED_DATA: "asset/module/TYPES.SET_MODULE_FILTERED_DATA",
    SET_BATTERY_ASSET_FILTERED_DATA: "asset/battery/SET_BATTERY_ASSET_FILTERED_DATA",

    FETCH_INSPECTION_DATA: "module/inspection/FETCH_DATA",
    SET_INSPECTION_DATA: "module/inspection/SET_INSPECTION_DATA",
    SET_INSPECTION_META_DATA: "module/inspection/SET_INSPECTION_META_DATA",

    FETCH_IOT_DATA: "iot/FETCH_IOT_DATA",
    SET_IOT_DATA: "iot/SET_IOT_DATA",

    SET_TEMPLATE_DATA_MODULE: "template/SET_TEMPLATE_DATA_MODULE",
    FETCH_MODULE_TEMPLATE_UPDATE_POSITION_DATA:
        "template/FETCH_MODULE_TEMPLATE_UPDATE_POSITION_DATA",
    FETCH_MODULE_TEMPLATE_DATA: "template/FETCH_MODULE_TEMPLATE_DATA",
    SET_MODULE_TEMPLATE_DATA: "template/SET_MODULE_TEMPLATE_DATA",
    SET_MODULE_TEMPLATE_FILTERED_DATA: "template/SET_MODULE_TEMPLATE_FILTERED_DATA",
    SET_MODULE_TEMPLATE_FILTERED_DATA_FETCH_API:
        "template/SET_MODULE_TEMPLATE_FILTERED_DATA_FETCH_API",
    MODIFY_MODULE_TEMPLATE_DATA: "template/MODIFY_MODULE_TEMPLATE_DATA",
    SET_MODULE_TEMPLATE_FILTERES: "template/SET_MODULE_TEMPLATE_FILTERES",
    SET_TEMPLATE_LOADING_STATE: "template/SET_TEMPLATE_LOADING_STATE",
    UPDATE_MODULE_DATA: "template/UPDATE_MODULE_DATA",
    ADD_PAGE: "template/ADD_PAGE",
    SAVE_ROUTE: "routing/SAVE_ROUTE",
    ADD_DATA: "form/add_entry",
    EDIT_DATA: "form/edit_entry",

    SET_MODAL_STATUS: "modal/SET_MODAL_STATUS",
    SET_FULL_SCREEN_STATUS: "modal/SET_FULL_SCREEN_STATUS",
    SET_MAP_VIEW_STATUS: "modal/SET_MAP_VIEW_STATUS",
    DELETE_DATA: "modal/DELETE_DATA",

    DEVICE_UNLOCK: "iot/device_unlock",
    FETCH_CONTAINERS_BY_FILTER: "containers/FETCH_CONTAINERS_BY_FILTER",
    SET_CONTAINERS_DATA: "auth/container/SET_CONTAINERS_DATA",
}

export default TYPES
