import * as yup from "yup"

export const EndTripValidation = yup.object().shape({
    trip_id: yup.string().required().nullable("Trip Id is required"),
    description: yup.string().required().nullable("description is required"),
})

export const IOTValidation = yup.object().shape(
    {
        name: yup.string().required().nullable("name is required"),
        model_type: yup
            .string()
            .required("model type is required")
            .nullable("model type is required"),
        device_id: yup
            .string()
            // .when(['mac_address', 'imei'], {
            //   is: (mac_address, imei) => !mac_address && !imei,
            //   then: yup
            //     .string()
            //     .required('Please enter one of the three fields')
            //     .typeError('Please enter one of the three fields'),
            // })
            .required("device id is required"),
        mac_address: yup.string().nullable().required(),
        // .when(['device_id', 'imei'], {
        //   is: (device_id, imei) => !device_id && !imei,
        //   then: yup
        //     .string()
        //     .required('Please enter one of the three fields')
        //     .typeError('Please enter one of the three fields'),
        // }),
        imei: yup.string().nullable().required("imei number is required"),
        // .when(['device_id', 'mac_address'], {
        //   is: (device_id, mac_address) => !device_id && !mac_address,
        //   then: yup
        //     .number()
        //     .required('Please enter one of the three fields')
        //     .typeError('Please enter one of the three fields'),
        // }),
    },
    [
        ["device_id", "mac_address"],
        ["device_id", "imei"],
        ["mac_address", "imei"],
    ],
)

export const VehicleFormValidations = yup.object().shape({
    vehicle_id: yup.string("Vehicle Id must be alphanumeric").required("Vehicle Id is required"),
    // device_id: yup
    //   .string('Device Id must be alphanumeric')
    //   .required('Device Id is required'),
    // controller_id:yup.number('Controller Id must be Integer value').min(0,'Value must be greater then 0').nullable(),
    // imei:yup.number('IMEI Number must be Integer value').min(0,'Value must be greater then 0').nullable(),
})

export const BillingFormValidations = yup.object().shape({
    base_rate: yup.string("Base Rate must be numeric").required("Base Rate is required"),
})

export const BatteryFormValidations = yup.object().shape({
    battery_id: yup.string("Battery Id must be alphanumeric").required("Battery Id is required"),
    battery_firmware_version: yup
        .string("Battery Firmware Version must be alphanumeric")
        .required("Battery Firmware Version is required"),
    battery_cell_voltage: yup
        .number("Battery Cell Voltage must be Integer Value")
        .min(1, "Must be greater then zero")
        .required("Battery Cell Voltage is required")
        .typeError("Battery Cell Voltage must be Integer Value"),
    average_battery_distance: yup
        .number("Must be greater then zero")
        .min(1, "Must be greater then zero")
        .typeError("Must be greater then zero"),
    battery_level: yup
        .number("Must be greater then or equal to zero")
        .min(0, "Must be greater then or equal to zero")
        .typeError("Must be greater then or equal to zero"),
    number_of_recharges: yup
        .number("Must be greater then zero")
        .min(1, "Must be greater then zero")
        .typeError("Must be greater then zero"),
    distance_since_last_recharge: yup
        .number("Must be greater then zero")
        .min(1, "Must be greater then zero")
        .typeError("Must be greater then zero"),
})

export const ServiceStaffFormValidations = yup.object().shape({
    name: yup.string("Name must be alphanumeric").required("Name is required"),
    email: yup.string("Email must be alphanumeric").required("Email is required"),
    contact_number: yup
        .number("Contact Number must be Integer Value")
        .required("Contact Number is required"),
})

export const StationsValidations = yup.object().shape({
    location: yup.string("Location must be alphanumeric").required("Location is required"),
    latitude: yup.string("Latitude must be alphanumeric").required("Latitude is required"),
    longitude: yup
        .string("Longitude Number must be Integer Value")
        .required("Location is required"),
})
