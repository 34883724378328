import { Card, Col, Row } from "react-bootstrap"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ServiceWithMetaData } from "../../Data/DummyData"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { DashboardStatistics, renderInfoCardDataFunction } from "../../Data/WebsiteData/keego"
import { selectFilteredData } from "../../redux/templateData/selectors"

function ServiceReminder() {
    const filteredResult = useSelector(selectFilteredData)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: ServiceWithMetaData.data,
            meta: ServiceWithMetaData.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const data = useMemo(() => ServiceWithMetaData.data, [])

    return (
        <div>
            <PageHeader titles="Service Reminder" active="Service Reminder" items={["Tables"]} />
            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        {DashboardStatistics.filter(
                            (item) => item.module === "service_reminder",
                        ).map((card, index) => (
                            <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                                <Card className="overflow-hidden">
                                    <Card.Body>
                                        <div className="d-flex">
                                            <div className="mt-2">
                                                <h6 className="">{card.heading}</h6>
                                                <h2 className="mb-0 number-font">
                                                    {renderInfoCardDataFunction(card)}
                                                </h2>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="chart-wrapper mt-1"></div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                        {/* <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Total Reminders</h6>
                      <h2 className="mb-0 number-font">
                        {ServiceWithMetaData.data.length}
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Vehicle Under Service</h6>
                      <h2 className="mb-0 number-font">
                        {
                          ServiceWithMetaData.data.filter(
                            (service) => service.status == 'Sent'
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <Card className="overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Pending Services</h6>
                      <h2 className="mb-0 number-font">
                        {
                          ServiceWithMetaData.data.filter(
                            (service) => service.status == 'Pending'
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} xxl={3}>
              <div className="card overflow-hidden">
                <Card.Body>
                  <div className="d-flex">
                    <div className="mt-2">
                      <h6 className="">Completed</h6>
                      <h2 className="mb-0 number-font">
                        {
                          ServiceWithMetaData.data.filter(
                            (service) => service.status == 'Completed'
                          ).length
                        }
                      </h2>
                    </div>
                    <div className="ms-auto">
                      <div className="chart-wrapper mt-1"></div>
                    </div>
                  </div>
                </Card.Body>
              </div>
            </Col> */}
                    </Row>
                </Col>
            </Row>
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                filteredResult={filteredResult}
                                datatableTitle="Service Reminder"
                                sortable_key="service_id"
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </div>
    )
}

export default ServiceReminder
