import React from "react"
import { PropsWithChildren, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

function TableRow({ loading, user }) {
    const status = user.active ? "Active" : "Inactive"

    return (
        <tr>
            <td>{loading ? <Skeleton height="50px" /> : user.id}</td>
            <td>{loading ? <Skeleton height="50px" /> : user.name}</td>
            <td>{loading ? <Skeleton height="50px" /> : user.role}</td>
            <td>{loading ? <Skeleton height="50px" /> : status}</td>
        </tr>
    )
}

export default function App() {
    const [loading, setLoading] = useState(true)

    function renderLoadingControl(i) {
        return (
            <div className="form-check mb-4">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={`loadingCheckbox${i}`}
                    checked={loading}
                    onChange={() => setLoading((b) => !b)}
                />
                <label className="form-check-label" htmlFor={`loadingCheckbox${i}`}>
                    Loading
                </label>
            </div>
        )
    }

    return (
        <SkeletonTheme
            //   baseColor="#5294e0"
            //   highlightColor="#96c7ff"
            //   baseColor="#adadad"
            //   highlightColor="#86868b"
            //   borderRadius="0.5rem"
            duration={4}
        >
            <div>
                <Card style={{ padding: "20px" }}>
                    <Row>
                        {[{}, {}, {}, {}].map((_, index) => (
                            <Col key={`ROW${index}`} lg={6} md={6} sm={12} xxl={3}>
                                <Skeleton count={1} inline width="100%" height="150px" />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xxl={12}>
                            <div style={{ padding: "30px 0px" }}>
                                <Skeleton count={1} inline width="100%" height="300px" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <table className="table">
                                <tbody>
                                    {[{}, {}, {}, {}].map((u) => (
                                        <TableRow key={u.id} user={u} loading={loading} />
                                    ))}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card>
            </div>
        </SkeletonTheme>
    )
}
