import { Card, Col, Row } from "react-bootstrap"
import { batteryDatatable, DeliveryVehicleDatatable, VehicleDatatable } from "../Data/DummyData"
import Datatable, { computeColumns } from "../Data/TablesData/Datatable"
import PageHeader from "../Layouts/PageHeader/PageHeader"
import AssetLocationMap from "./Maps/AssetLocationMap"
import { ModalContentMemonized } from "./WorkOrder/WorkOrder"
import { useDatatableCtx } from "../components/Datatable/DatatableContext"
import { useEffect, useMemo } from "react"
import { fetchData, setFilteredData } from "../redux/assets/actions"
import { setModule } from "../redux/assets/initialState"
import { useDispatch, useSelector } from "react-redux"
import { selectFilteredData } from "../redux/assets/selectors"

function Deliveries() {
    const filteredResult = useSelector(selectFilteredData)
    const dispatch = useDispatch()
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: VehicleDatatable.data,
            meta: VehicleDatatable.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    useEffect(() => {
        dispatch(setModule("delivery_vans"))
        dispatch(fetchData({ module: "delivery_vans" }))
    }, [])

    const data = useMemo(() => VehicleDatatable.data, [])

    return (
        <div>
            <PageHeader titles="Delivery Vans" active="Delivery Vans" items={["Tables"]} />

            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Total Battries</h6>
                                            <h2 className="mb-0 number-font">
                                                {batteryDatatable.data.length}
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <div className="card overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">In Vehicle</h6>
                                            <h2 className="mb-0 number-font">
                                                {
                                                    batteryDatatable.data.filter(
                                                        (battery) => battery.vehicle_id !== "",
                                                    ).length
                                                }
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">In Charging Stations</h6>
                                            <h2 className="mb-0 number-font">
                                                {
                                                    batteryDatatable.data.filter(
                                                        (battery) => battery.vehicle_id == "",
                                                    ).length
                                                }
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <div className="card overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Overheating</h6>
                                            <h2 className="mb-0 number-font">
                                                {
                                                    batteryDatatable.data.filter(
                                                        (battery) => battery.isOverhitting,
                                                    ).length
                                                }
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div>
                <AssetLocationMap
                    ids={VehicleDatatable.data.map((d) => d.vehicle_id)}
                    type={"vehicle_id"}
                    filteredResult={filteredResult.filter((data) => data.type === "van")}
                />
            </div>
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                filteredResult={(result) => {
                                    dispatch(setFilteredData(result))
                                }}
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default Deliveries
