import axios from "axios"
import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { logout, setWebsites, setAuthUser, setPageStatus, setContainersData } from "../actions"
// import { persistor } from '../store';
import TYPES from "../types"
import { fetchContainerByFilterApiCall, login, saveRouteApiCall } from "../general/api"
import { fetchData } from "../templateData/actions"

function* loginUser(action) {
    yield put(setPageStatus(true))
    const data = yield call(login, action)
    yield put(setPageStatus(false))
    localStorage.setItem("token", data.auth_token)
    yield put(setAuthUser(data.user_details))
    yield put(setWebsites(data.fleet_manager_data))
}

function* logoutUser() {
    // persistor.pause();

    // persistor.flush().then(() => {
    //   return persistor.purge();
    // });

    yield put(logout())
    localStorage.clear()
    sessionStorage.clear()
    localStorage.removeItem("app")
    localStorage.removeItem("token")
    window.location.href = "/"
}

function* saveRoute(action) {
    const data = yield call(saveRouteApiCall, action)
    if (action.promise) {
        action.promise.resolve(data)
    }
}

// function* generalApiCall() {
//   yield call(deleteFormData, {
//     apiBody: { ...payload, module },
//     id: payload.vehicle_id,
//   });

//   yield put(fetchTemplateData({ module }));
// }

function* fetchContainerByFilter(action) {
    const data = yield call(fetchContainerByFilterApiCall, action)
    console.log("data: ", data)
    yield put(setContainersData(data))
}

function* UserSaga() {
    yield takeEvery(TYPES.AUTHENTICATE_USER, loginUser)
    yield takeEvery(TYPES.SAVE_ROUTE, saveRoute)
    yield takeEvery(TYPES.LOGOUT_USER, logoutUser)
    yield takeEvery(TYPES.FETCH_CONTAINERS_BY_FILTER, fetchContainerByFilter)
    // yield takeLatest(TYPES.GENERAL_API_CALL, generalApiCall);
}

export default UserSaga
