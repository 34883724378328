import { Card, Col, Row } from "react-bootstrap"
import { batteryDatatable, DustbinDatatable, VehicleDatatable } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import AssetLocationMap from "../Maps/AssetLocationMap"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectFilteredData } from "../../redux/assets/selectors"
import { initialState } from "../../redux/templateData/init"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import { selectDatatable } from "../../redux/templateData/selectors"
import { DashboardStatistics } from "../../Data/DummyData"
import { useInfoCtx } from "../../providers/InfoCardProvider"

function Dustbins() {
    const datatable = useSelector(selectDatatable)
    const filteredResult = useSelector(selectFilteredData)
    const dispatch = useDispatch()
    const { renderInfoCardData } = useInfoCtx()
    const module = "dustbins"

    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: datatable.data,
            meta: DustbinDatatable.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const data = useMemo(() => datatable.data, [datatable.data])

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    return (
        <div>
            <PageHeader titles="Dustbins" active="Dustbins" items={["Tables"]} />
            {/* <!-- Row --> */}

            <Row>
                {DashboardStatistics.filter((item) => item.module === "dustbins").map(
                    (card, index) => (
                        <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">{card.heading}</h6>
                                            <h2 className="mb-0 number-font">
                                                {renderInfoCardData(card)}
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ),
                )}
                {/* <Col lg={6} md={6} sm={12} xxl={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Total Battries</h6>
                  <h2 className="mb-0 number-font">
                    {batteryDatatable.data.length}
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={12} xxl={3}>
          <div className="card overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">In Vehicle</h6>
                  <h2 className="mb-0 number-font">
                    {
                      batteryDatatable.data.filter(
                        (battery) => battery.vehicle_id !== ''
                      ).length
                    }
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} xxl={3}>
          <Card className="overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">In Charging Stations</h6>
                  <h2 className="mb-0 number-font">
                    {
                      batteryDatatable.data.filter(
                        (battery) => battery.vehicle_id == ''
                      ).length
                    }
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={12} xxl={3}>
          <div className="card overflow-hidden">
            <Card.Body>
              <div className="d-flex">
                <div className="mt-2">
                  <h6 className="">Overheating</h6>
                  <h2 className="mb-0 number-font">
                    {
                      batteryDatatable.data.filter(
                        (battery) => battery.isOverhitting
                      ).length
                    }
                  </h2>
                </div>
                <div className="ms-auto">
                  <div className="chart-wrapper mt-1"></div>
                </div>
              </div>
            </Card.Body>
          </div>
        </Col> */}
            </Row>

            <div>
                <AssetLocationMap
                    ids={DustbinDatatable.data.map((d) => d.dustbin_id)}
                    type={"dustbin_id"}
                    filteredResult={filteredResult}
                />
            </div>
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default Dustbins
