import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { formattingValue } from "./DashboardTilesOnMap"
import { selectFilteredData } from "../../redux/templateData/selectors.js"
import { averageContainerData } from "../../Data/WebsiteData/Statistics/interpreter.ts"
import styles from "./DashboardFiltersOnMap.module.scss"

function DashboardFiltersOnMap({ filters }) {
    const [saveButtonVisible, setSaveButtonVisible] = useState()

    useEffect(() => {
        const saveButtonElement = document.getElementById("saveButton")
        if (saveButtonElement) {
            setSaveButtonVisible((prevState) => true)
        } else {
            setSaveButtonVisible((prevState) => false)
        }
    }, [document.getElementById("saveButton")])

    const MapFilters = useSelector((state) => state.MapFilters.filters)

    const filteredKeys = Object.keys(MapFilters).filter((key) => MapFilters[key])

    return (
        <div className="d-flex flex-row-reverse flex-wrap">
            {/* {filteredKeys.map((key, index) => {
                const filterValue = MapFilters[key]
                return filterValue ? (
                    <div key={index} className="mt-2 chip-container">
                        <div className={`btn-secondary  ${styles.custom_chip_style}`}>
                            <h6 style={{ margin: 0, textTransform: "capitalize" }}>
                                {key.replace(/_/g, " ")}{" "}
                            </h6>
                            <div>: </div>
                            <span className="number-font" style={{ fontSize: "12px" }}>
                                {Array.isArray(filterValue)
                                    ? `${filterValue[0]} - ${filterValue[1]}`
                                    : formattingValue(filterValue)}
                            </span>
                        </div>
                    </div>
                ) : null
            })} */}
            {Object.keys(filters).map((key, index) => (
                <div key={index} className="mt-2 chip-container">
                    <div className={`btn-secondary  ${styles.custom_chip_style}`}>
                        <h6 style={{ margin: 0, textTransform: "capitalize" }}>
                            {key.replace(/_/g, " ")}{" "}
                        </h6>
                        <div>: </div>
                        <span className="number-font" style={{ fontSize: "12px" }}>
                            {Array.isArray(filters[key])
                                ? `${filters[key][0]} - ${filters[key][1]}`
                                : formattingValue(filters[key])}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default DashboardFiltersOnMap
