import { Card, Col, Row } from "react-bootstrap"
import { WebsiteMeta, Websites } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { useEffect, useMemo } from "react"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { useDispatch, useSelector } from "react-redux"
import { selectDatatable } from "../../redux/templateData/selectors"
import { initialState } from "../../redux/templateData/init"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"

function FleetManagers() {
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const data = useSelector(selectDatatable)
    const dispatch = useDispatch()
    const module = "operators_list"

    const columns = useMemo(() =>
        computeColumns({
            data: data.data,
            meta: data.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    return (
        <div>
            <PageHeader titles="Fleet Managers" active="Fleet Managers" items={["Tables"]} />

            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Total Operator</h6>
                                            <h2 className="mb-0 number-font">{data.data.length}</h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Datatable</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Datatable
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data.data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default FleetManagers
