import { createReducer } from "@reduxjs/toolkit"
import { setFilteredData, setModuleData } from "./actions"
import { initialState, setModule } from "./initialState"

const AssetReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setModule, (state, action) => {
            state.module = action.payload
        })
        .addCase(setModuleData, (state, action) => {
            state[state.module].datatable.data = action.payload
        })
        .addCase(setFilteredData, (state, action) => {
            state[state.module].datatable.filteredData = action.payload
        })
        .addDefaultCase((state, action) => state)
})

export default AssetReducer
