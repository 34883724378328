import React, { useEffect } from "react"
import { Card, Col, Row } from "react-bootstrap"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import styles from "./Dashboard.module.scss"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { selectWebsiteData } from "../../redux/general/selectors"
import Skeleton from "@mui/material/Skeleton"
import { selectGeneralApiData } from "../../redux/general/selectors"
import { useDispatch } from "react-redux"
import { generalApiCall } from "../../redux/general/actions"
import { formatDashboardHeaderKey } from "../../Data/WebsiteData/Statistics/interpreter.ts"

const SkeletonLoader = () => (
    <Card className="overflow-hidden">
        <Skeleton variant="rectangular" width={500} height={100} />
    </Card>
)

const SummaryDashboard = () => {
    const websiteData = useSelector(selectWebsiteData)
    const dispatch = useDispatch()
    const filtersToShow = [
        "total_containers",
        "overflow_containers",
        "full_containers",
        "average_percent_fill",
    ]
    const summaryStatistics = useSelector(selectGeneralApiData({ key: "summary_statistics" }))

    useEffect(() => {
        if (websiteData.app_key) {
            dispatch(
                generalApiCall({
                    endpoint: `api/v1/get-all-stats?company_name=${websiteData.app_key}`,
                    params: {},
                    method: "GET",
                    key: "summary_statistics",
                }),
            )
        }
    }, [websiteData.app_key])

    const renderSummaryStatistics = () => {
        if (summaryStatistics) {
            return Object.keys(summaryStatistics[websiteData.app_key])
                .filter((key) => filtersToShow.includes(key))
                .map((key) => (
                    <Col lg={6} md={6} sm={12} xxl={3} key={key}>
                        <Card className="overflow-hidden">
                            <Card.Body>
                                <div className="d-flex">
                                    <div className="mt-2">
                                        <h6 className="">{formatDashboardHeaderKey(key)}</h6>
                                        <h2 className="mb-0 number-font">
                                            {summaryStatistics[websiteData.app_key][key]}
                                        </h2>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))
        } else {
            return null
        }
    }

    return (
        <div className={styles.Dashboard}>
            <PageHeader tites="Dashboard" active="Dashboard" items={["Home"]} />
            <Row>{renderSummaryStatistics()}</Row>
        </div>
    )
}

export default SummaryDashboard
