import React, { createContext, useContext, useEffect, useState } from "react"

const TableContext = createContext({
    status: false,
    setModalStatus: () => {},
    meta: {
        heading: "Details",
        row: {},
        setRowMeta: () => {},
        setHeading: () => {},
    },
})

function DatatableContext({ children }) {
    const [status, setStatus] = useState(false)
    const [row, setRowData] = useState({})
    const [columnMeta, setColumnMetaData] = useState({})
    const [heading, setHeading] = useState("Details")

    const setModalStatus = (status) => {
        setStatus(status)
    }

    useEffect(
        () => () => {
            setModalStatus(false)
        },
        [],
    )

    return (
        <TableContext.Provider
            value={{
                status,
                row,
                heading,
                columnMeta,
                setModalStatus,
                setRowData,
                setHeading,
                setColumnMetaData,
            }}
        >
            {children}
        </TableContext.Provider>
    )
}

export const useDatatableCtx = () => {
    const ctx = useContext(TableContext)
    if (!ctx) {
        console.error("context can't be access outside <DatatableContext> Provider")
    }

    return ctx
}

export default DatatableContext
