import { createAction, createReducer } from "@reduxjs/toolkit"

const initialState = {
    displaySummary: "none",
}

export const setRoutingSummary = createAction("SET_ROUTING_SUMMARY")

const RoutingSummaryReducer = createReducer(initialState, (builder) => {
    builder.addCase(setRoutingSummary, (state, action) => {
        state.displaySummary = action.payload
    })
})

export default RoutingSummaryReducer
