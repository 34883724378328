import { takeEvery, put, call } from "redux-saga/effects"
import TYPES from "../types"
import { setInspectionMetaData, setInspectionsData } from "./action"
import { fetchInspectionDataApi } from "../apis"

function* fetchInspectionData() {
    const { data, meta } = yield call(fetchInspectionDataApi)
    // console.log("DATA: ",data,meta)
    yield put(setInspectionMetaData(meta))
    yield put(setInspectionsData(data))
}

export function* InspectionSaga() {
    yield takeEvery(TYPES.FETCH_INSPECTION_DATA, fetchInspectionData)
}
