import React, { useEffect, useMemo } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { InspectionWithMetaData } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { setRDXModule } from "../../redux/current/actions"
import { fetchInspections } from "../../redux/inspection/action"
import { selectModuleData, selectModuleMeta } from "../../redux/inspection/selector"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { DashboardStatistics, renderInfoCardDataFunction } from "../../Data/WebsiteData/keego"
import { selectFilteredData } from "../../redux/templateData/selectors"
function Inspection() {
    const filteredResult = useSelector(selectFilteredData)
    const dispatch = useDispatch()
    const data = useSelector(selectModuleData)
    const meta = useSelector(selectModuleMeta)

    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(
        () =>
            computeColumns({
                data,
                meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [data],
    )

    useEffect(() => {
        dispatch(setRDXModule("inspection"))
        dispatch(fetchInspections())
    }, [])

    // console.log('data, value', data, columns);
    return (
        <div>
            <PageHeader titles="Inspection" active="Inspection" items={["Tables"]} />
            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        {DashboardStatistics.filter((item) => item.module === "inspection").map(
                            (card, index) => (
                                <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                                    <Card className="overflow-hidden">
                                        <Card.Body>
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <h6 className="">{card.heading}</h6>
                                                    <h2 className="mb-0 number-font">
                                                        {renderInfoCardDataFunction(card)}
                                                    </h2>
                                                </div>
                                                <div className="ms-auto">
                                                    <div className="chart-wrapper mt-1"></div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ),
                        )}
                    </Row>
                </Col>
            </Row>
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                filteredResult={filteredResult}
                                sortable_key="service_id"
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </div>
    )
}

export default Inspection
