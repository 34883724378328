import { Box } from "@mui/material"
import React from "react"
import { Button } from "react-bootstrap"

class RootErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyItems="center"
                        alignItems="center"
                    >
                        <h1>Something went wrong.</h1>
                        <input
                            onClick={() => {
                                window.location.href = "/"
                            }}
                            type="button"
                            style={{ backgroundColor: "red", color: "white" }}
                            value="Home"
                        />
                    </Box>
                </>
            )
        } else return this.props.children
    }
}

export default RootErrorBoundary
