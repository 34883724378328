import { createReducer } from "@reduxjs/toolkit"
import { initialState } from "./init"
import {
    addPage,
    forcedModifyState,
    setFilteredData,
    setFilteredDataFetchApi,
    setFilters,
    setLoadingState,
    setModule,
    setModuleData,
} from "./actions"

function getProperty(obj, key) {
    // Split the key into its parts
    const keys = key.split(".")

    // Traverse the object based on the parts of the key
    for (const k of keys) {
        if (obj && typeof obj === "object" && obj.hasOwnProperty(k)) {
            obj = obj[k]
        } else {
            // Property not found, return undefined
            return undefined
        }
    }

    // Return the final property value
    return obj
}

function setProperty(obj, key, value) {
    // Split the key into its parts
    const keys = key.split(".")

    // Traverse the object based on the parts of the key
    for (let i = 0; i < keys.length - 1; i++) {
        const k = keys[i]
        if (obj && typeof obj === "object" && obj.hasOwnProperty(k)) {
            obj = obj[k]
        } else {
            // If any part of the key doesn't exist, create it as an empty object
            obj[k] = {}
            obj = obj[k]
        }
    }

    // Set the final property value
    const lastKey = keys[keys.length - 1]
    obj[lastKey] = value
}

const TemplateDataReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setModule, (state, action) => {
            state.module = action.payload
        })
        .addCase(setModuleData, (state, action) => {
            state[action.payload.module].datatable.data = action.payload.data
            state[action.payload.module].datatable.meta = action.payload.meta
            state[action.payload.module].page_info = action.payload.page_info
            // state[action.payload.module].statistics = action.payload.statistics;

            return state
        })
        .addCase(setLoadingState, (state, action) => {
            state.loading = action.payload
        })
        .addCase(setFilters, (state, action) => {
            state[state.module].datatable.filters = action.payload
            return state
        })
        .addCase(setFilteredData, (state, action) => {
            state[state.module].datatable.filteredData = action.payload

            return state
        })
        .addCase(setFilteredDataFetchApi, (state, action) => {
            const { data, page_name } = action.payload
            state[page_name].datatable.filteredData = data

            return state
        })
        .addCase(addPage, (state, action) => {
            state = { ...state, [action.payload.key]: action.payload.data }
            return state
        })
        .addCase(forcedModifyState, (state, action) => {
            const {
                payload: { module, data },
            } = action

            setProperty(state, module, data)
            // console.log("HERE MODLE MODIFICAION:", state)
            return state
        })
        .addDefaultCase((state, action) => state)
})

export default TemplateDataReducer
