import React, { useState } from "react"
import { Form, Card, Col, Row, Offcanvas, Button } from "react-bootstrap"
import { useSelector } from "react-redux"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { GENERAL_MENUITEMS } from "../../Layouts/Sidebar/Sidemenu"
import { selectPermissions, selectWebsiteSubmodulePermissions } from "../../redux/general/selectors"
import { ACCESS_TYPES, PERMISSIONS } from "../../redux/redux_constants"
import className from "./operator.module.css"
import { Websites } from "../../Data/DummyData"

function OffCanvasExample({ permissions, onChange, module, status, handleClose, name, ...props }) {
    return (
        <>
            {status && (
                <Offcanvas show={status} onHide={handleClose} {...props}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Submodule Permissions</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form.Select onChange={onChange} aria-label="Default select example">
                            {Websites.map((website, key) => (
                                <option key={key} value={website.id}>
                                    {website.name}
                                </option>
                            ))}
                        </Form.Select>
                        <div style={{ padding: "20px" }}>
                            {module.children.map((childModule, index) => (
                                <React.Fragment key={index}>
                                    <div style={{ display: "flex" }}>
                                        <input
                                            className={
                                                className[("form-control", "checkbox-resize-20")]
                                            }
                                            checked={permissions.includes(childModule.module)}
                                            type="checkbox"
                                            id={`default-${childModule.module}`}
                                            label={childModule.title}
                                        />
                                        <label htmlFor={`default-${childModule.module}`}>
                                            &nbsp;&nbsp;&nbsp;
                                            {childModule.title}
                                        </label>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            )}
        </>
    )
}

function OperatorModule() {
    const moduleList = GENERAL_MENUITEMS
    const permissions = useSelector(selectPermissions)
    const [websiteSubmodulePermissions, setWebsiteSubmodulePermission] = useState(
        Websites.find((fleetManager) => fleetManager.id == "master").permissions,
    )
    const [module, selectedModule] = useState({})
    const [access_type, setAccessType] = useState("")
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handlePermission = (e, { module, access_type }) => {
        setAccessType(access_type)
        selectedModule(module)
        if (module.children) {
            handleShow()
        }
    }

    return (
        <div>
            <PageHeader titles="Operator Module" active="Operator Module" items={["Home"]} />
            <OffCanvasExample
                onChange={(event) =>
                    setWebsiteSubmodulePermission(
                        Websites.find((fleetManager) => fleetManager.id == event.target.value)
                            .permissions,
                    )
                }
                permissions={websiteSubmodulePermissions}
                module={module}
                status={show}
                handleClose={handleClose}
                placement="end"
                name="end"
            />
            <Row className="row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Datatable</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <table className="table table-bordered text-nowrap border-bottom">
                                <thead>
                                    <tr>
                                        <td>Module/Permissions</td>
                                        <td>Master</td>
                                        <td>Delivery Service</td>
                                        <td>Ride Share</td>
                                        <td>Ride Share Biketek</td>
                                        <td>Operator Service</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {moduleList.map((module, index) => (
                                        <tr key={index}>
                                            <td>{module.title}</td>
                                            <td>
                                                <input
                                                    className={className["checkbox-resize-20"]}
                                                    type="checkbox"
                                                    value="Master"
                                                    checked={permissions[
                                                        ACCESS_TYPES.MASTER
                                                    ].includes(module.module)}
                                                    onChange={(e) =>
                                                        handlePermission(e, {
                                                            module,
                                                            access_type: ACCESS_TYPES.MASTER,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className={className["checkbox-resize-20"]}
                                                    type="checkbox"
                                                    value="Master"
                                                    checked={permissions[
                                                        ACCESS_TYPES.DELIVERY_SERVICE
                                                    ].includes(module.module)}
                                                    onChange={(e) =>
                                                        handlePermission(e, {
                                                            module,
                                                            access_type:
                                                                ACCESS_TYPES.DELIVERY_SERVICE,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className={className["checkbox-resize-20"]}
                                                    type="checkbox"
                                                    value="Master"
                                                    checked={permissions[
                                                        ACCESS_TYPES.RIDESHARE
                                                    ].includes(module.module)}
                                                    onChange={(e) =>
                                                        handlePermission(e, {
                                                            module,
                                                            access_type: ACCESS_TYPES.RIDESHARE,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className={className["checkbox-resize-20"]}
                                                    type="checkbox"
                                                    value="Master"
                                                    checked={permissions[
                                                        ACCESS_TYPES.RIDESHARE_BIKETEK
                                                    ].includes(module.module)}
                                                    onChange={(e) =>
                                                        handlePermission(e, {
                                                            module,
                                                            access_type:
                                                                ACCESS_TYPES.RIDESHARE_BIKETEK,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className={className["checkbox-resize-20"]}
                                                    type="checkbox"
                                                    value="Master"
                                                    checked={permissions[
                                                        ACCESS_TYPES.OPERATOR_SERVICE
                                                    ].includes(module.module)}
                                                    onChange={(e) =>
                                                        handlePermission(e, {
                                                            module,
                                                            access_type:
                                                                ACCESS_TYPES.OPERATOR_SERVICE,
                                                        })
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default OperatorModule
