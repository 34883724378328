import GeneralIOT from "./GeneralIOT"
import IOT from "./IOT"
import BSIOT from "./BSIOT"
import DiageoIOT from "./DiageoIOT"
import WavSmartIOT from "./WAVSmart"

function Routing() {
    const app = sessionStorage.getItem("app_key")
    console.log("This is the appkey", app)

    const renderComponent = () => {
        switch (app) {
            // case "diageo":
            //     return <DiageoIOT />
            // case "superfy":
            // case "abc_solar":
            // case "citkar":
            //     return <IOT />
            // case "keego":
            // case "gramobility":
            // case "moby":
            // case "bombay_softwares":
            // case "biketek":
            // case "volti":
            // case "move_scooters":
            // case "ewaka":
            //     return <BSIOT />
            default:
                return <WavSmartIOT />
        }
    }

    return renderComponent()
}

export default Routing
