import Layout from "../../Layouts/Layout"
import MobyRouting from "./Routing"
import WavSmartRouting from "./WavSmartRouting"
import GeneralRouting from "./GeneralRouting"
import CommonRouting from "./Routing"
import FilteredContainerHistory from "../FilteredContainerHistory/FilteredContainerHistory"

function Routing() {
    const app = sessionStorage.getItem("app_key")

    const renderComponent = () => {
        let component
        switch (app) {
            case "wav_smart":
                component = <FilteredContainerHistory />
                break
            case "keego":
                component = <FilteredContainerHistory />
                break
            case "gramobility":
            case "moby":
            case "citkar":
            case "diageo":
            case "bombay_softwares":
            case "biketek":
            case "volti":
            case "move_scooters":
            case "ewaka":
                // component = <CommonRouting />
                component = <FilteredContainerHistory />
                break
            default:
                component = <FilteredContainerHistory />
                break
        }

        return component
    }

    return renderComponent()
}

export default Routing
