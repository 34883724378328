import { useMemo } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { SubscriptionDatatable } from "../../Data/WebsiteData/efleety"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"

function Subscription() {
    const { setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: SubscriptionDatatable.data,
            meta: SubscriptionDatatable.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const data = useMemo(() => SubscriptionDatatable.data, [SubscriptionDatatable.data])

    return (
        <div>
            <PageHeader titles="Subscriptions" active="Subscripitons" items={["Tables"]} />
            <Row className="row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Basic Datatable</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Datatable columns={columns} data={data} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Subscription
