import React from "react"
import MapDatatable from "../templates/MapDatatable"
import { useSelector } from "react-redux"
import { selectCurrentMenuItem } from "../../redux/current/selector"

function GeneralPageLayout() {
    const { template } = useSelector(selectCurrentMenuItem)

    const renderTemplate = () => {
        switch (template) {
            case "map-datatable":
                return <MapDatatable />
            default:
                return console.error("Reported Invalid: Template Passed")
        }
    }

    return renderTemplate()
}

export default GeneralPageLayout
