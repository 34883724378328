import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, Col, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { useDispatch, useSelector } from "react-redux"
import {
    selectDatatable,
    selectFilteredData,
    selectModuleSpecificData,
} from "../../redux/templateData/selectors"
import { initialState } from "../../redux/templateData/init"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import { useModalCtx } from "../../providers/ModalProvider"
import { useFormik } from "formik"
import { FormHelperText, Grid, MenuItem, TextField } from "@mui/material"
import Modal from "../../components/Modal"
import { addData, deleteData, editData } from "../../redux/actions"

function RulesOnGeofencing() {
    const history = useNavigate()
    const dispatch = useDispatch()
    const datatable = useSelector(selectDatatable)
    const filteredResult = useSelector(selectFilteredData)
    const module = "geofencing_rules"

    const modalContext = useModalCtx()
    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext

    const geofencing = useSelector(selectModuleSpecificData("geofencing"))
    const rules = useSelector(selectModuleSpecificData("rules"))

    const { setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()
    const [isEditMode, setEditMode] = useState(false)

    const { values, errors, touched, resetForm, handleBlur, handleChange, setValues, submitForm } =
        useFormik({
            initialValues: {
                geofence_id: "",
                rule_id: [],
            },
            onSubmit: () => {
                const obj = { ...values }
                Object.keys(obj).forEach((key) => {
                    if (obj[key] === null || obj[key] === "") {
                        obj[key] = null
                    }
                })

                // obj = { ...obj, rule_id: [obj.rule_id] };

                if (isEditMode) {
                    dispatch(editData(obj))
                } else {
                    dispatch(addData(obj))
                }
            },
        })

    const columns = useMemo(() =>
        computeColumns({
            data: datatable.data,
            meta: datatable.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(
            addPage({
                key: "geofencing",
                data: initialState.vehicles,
            }),
        )

        dispatch(
            addPage({
                key: "rules",
                data: initialState.vehicles,
            }),
        )

        dispatch(setModule(module))

        dispatch(fetchData({ module }))
        dispatch(fetchData({ module: "geofencing" }))
        dispatch(fetchData({ module: "rules" }))
    }, [module])

    const data = useMemo(() => datatable.data, [datatable.data])

    const handleRuleSelect = (event) => {
        const selectedRules = event.target.value
        values.rule_id = selectedRules
        handleChange(event)
    }

    return (
        <div style={{ backgroundColor: "white", height: "100%" }}>
            <Datatable
                filteredResult={filteredResult}
                addActionName="Assign Rules On Geofencing"
                addAction={() => {
                    setEditMode(false)
                    handleFormModalStatus(true)
                    resetForm()
                }}
                refreshAction={() => {
                    dispatch(fetchData({ module }))
                }}
                datatableTitle="Rules on Geofencing"
                sortable_key="geofenceing_id"
                columns={columns}
                data={data}
            />

            <Modal
                modalHeading={isEditMode ? "Edit" : "Add"}
                fullWidth
                maxWidth="md"
                status={formModalStatus}
                onClose={() => {
                    resetForm()
                    handleFormModalStatus(false)
                }}
                onSave={submitForm}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid xs={6} item>
                        <TextField
                            label="Geofencing*"
                            placeholder="Select Geofencing"
                            select={true}
                            name="geofence_id"
                            value={values.geofence_id}
                            error={!!(errors.geofence_id && touched.geofence_id)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                        >
                            <MenuItem selected disabled value="select">
                                Select Geofencing
                            </MenuItem>
                            {geofencing?.map((geofence, index) => (
                                <MenuItem key={index} value={geofence.id}>
                                    {geofence.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid xs={6} item>
                        <TextField
                            label="Rules*"
                            placeholder="Select Rules"
                            select
                            name="rule_id"
                            value={values.rule_id}
                            error={errors.rule_id && touched.rule_id}
                            onChange={handleRuleSelect} // Use the custom handleRuleSelect function
                            onBlur={handleBlur}
                            fullWidth
                            SelectProps={{
                                multiple: true,
                                renderValue: (selected) => {
                                    const selectedRules = rules.filter((rule) =>
                                        selected.includes(rule.id),
                                    )
                                    return selectedRules.map((rule) => rule.rule).join(", ")
                                },
                            }}
                        >
                            <MenuItem disabled value="select">
                                Select Rules
                            </MenuItem>
                            {rules?.map((rule) => (
                                <MenuItem key={rule.id} value={rule.id}>
                                    {rule.rule}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    )
}

export default RulesOnGeofencing
