import { createReducer } from "@reduxjs/toolkit"
import { setInspectionMetaData, setInspectionsData } from "./action"
import initialState from "./init"

const InspectionReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setInspectionsData, (state, action) => {
            state.data = action.payload
        })
        .addCase(setInspectionMetaData, (state, action) => {
            state.meta = action.payload
        })
        .addDefaultCase((state, action) => state)
})

export default InspectionReducer
