export const PERMISSIONS = {
    OPERATOR_DASHBOARD: "operator_dashboard",
    OPERATOR_LIST: "operator_list",
    OPERATOR_MODULES: "operator_modules",
    OPERATOR_LICENSES: "operator_licences",
    DELIVERY_DASHBOARD: "delivery_dashboard",
    RIDESHARE_DASHBOARD: "rideshare_dashboard",
    WAV_SMART_DASHBOARD: "wav_smart_dashboard",
    SERVICE_OPERATOR: "service_operator",
    ASSETS: "assets",
    SERVICES: "services",
    GEOFENCING: "geofencing",
    CRM: "crm",
    IOT: "iot",
    TRIPS: "trips",
    ADMIN_USER: "admin_users",
    REPORTING: "reporting",
    ROUTING: "routing",
    CHAT: "chat",
    CONTACT_US: "contact_us",
    BILLING: "billing",
    BUSINESS_INSIGHTS: "business_insights",
    DELIVERIES: "deliveries",
    ACTIVE_DELIVERIES: "active_deliveries",
    PENDING_DELIVERIES: "pending_deliveries",
    COMPLETED_DELIVERIES: "completed_deliveries",
    VEHICLE: "vehicles",
    CONTAINERS: "containers",
    DELIVERY_VANS: "delivery_vans",
    BATTERY: "batteries",
    STATIONS: "stations",
    DUSTBINS: "dustbins",
    SOLAR_PANELS: "solar_panels",
    SERVICE_STAFF: "service_staff",
    RIDERS: "riders",
    LIST_GEOFENCING: "list_fencing",
    ADD_GEOFENCING: "add_fencing",
    SERVICE_INSPECTION: "inspection",
    SERVICE_REMINDER: "reminder",
    SERVICE_OPERATOR_REPORT: "operator_reminder",
    SERVICE_ISSUES: "issues",
    WORK_ORDER: "service/work_order",
    CRM_CUSTOMERS: "customers",
    SUBSCRIPTIONS: "subscriptions",
    BILLING_MODEL: "billing_model",
    STATISTICS: "statistics",
    SETTINGS: "settings",
    TILE_CONFIGURATION: "tile_configurations",
    RULES: "rules",
    RULES_ON_GEOFENCING: "rules_on_geofencing",
    CRM_CUSTOMER_CARDS: "customer_cards",
    CUSTOMER_CARD_TRANSACTION: "customer_card_transaction",
    RULES_VIOLATION: "rules_violation",
}

export const ACCESS_TYPES = {
    MASTER: "master",
    DELIVERY_SERVICE: "delivery_service",
    RIDESHARE: "ride_share",
    OPERATOR_SERVICE: "operator_service",
    RIDESHARES: "ride_shares",
    RIDESHARE_BIKETEK: "ride_share_biketek",
}

export const RDX_MODULES = {
    INSPECTION: "inspection",
    ASSETS: "assets",
    VEHICLE: "vehicle",
    GENERAL: "general",
    IOT: "iot",
    TEMPLATE_DATA: "template_data",
}

export const GENERAL_API_CALLS = {
    VENDOR_WEBSITE_TYPE: "vendor_website_types",
    WAVSMART_STATISTICS: "wavsmart_statistics",
    EFLEETY_STATISTICS: "efleety_statistics",
    WEBSITE_DATA: "websites_data",
}
