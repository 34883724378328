import { createAction, createReducer } from "@reduxjs/toolkit"

const initialState = {
    display: "none",
}

export const setFilterDisplay = createAction("SET_FILTER_DISPLAY")
export const setDashboardModal = createAction("SET_DASHBOARD_MODAL")

const FilterBarReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setFilterDisplay, (state, action) => {
            state.display = action.payload
        })
        .addCase(setDashboardModal, (state, action) => {
            state.dashboard = action.payload
        })
})

export default FilterBarReducer
