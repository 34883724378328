import React, { useEffect, useState } from "react"
import { Card, Col, Offcanvas, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { IOT_INSTRUCTIONS, VehicleIOTDatatable } from "../../Data/DummyData"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import { selectIOTAssetData } from "../../redux/iot/selector"
import { addPage, fetchData, setModule } from "../../redux/templateData/actions"
import { initialState } from "../../redux/templateData/init"
import { selectDatatable, selectFilteredData } from "../../redux/templateData/selectors"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { DashboardStatistics } from "../../Data/WebsiteData/moby"
import { useInfoCtx } from "../../providers/InfoCardProvider"
import { useModalCtx } from "../../providers/ModalProvider"
import Modal from "../../components/Modal"
import { Grid, TextField } from "@mui/material"
import { EndTripValidation } from "../../validations"
import { useFormik } from "formik"
import { addData } from "../../redux/actions"
import queryString from "query-string"
// import styles from './DataTable.module.css';

function Trips() {
    const module = "trips"
    const modalContext = useModalCtx()
    const search = queryString.parse(location.search)
    const { modalStatus: formModalStatus, modalStatusHandler: handleFormModalStatus } = modalContext
    const assetData = useSelector(selectIOTAssetData)
    const dispatch = useDispatch()
    const datatable = useSelector(selectDatatable)
    const filteredResult = useSelector(selectFilteredData)
    const [openInstructionPanelStatus, setInstructionPanelStatus] = useState(false)
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = React.useMemo(
        () =>
            computeColumns({
                data: datatable.data,
                meta: {
                    id: {
                        hide: false,
                        showDefault: true,
                        type: "button",
                        action: "openTripModal()",
                    },
                    customer_name: {
                        hide: false,
                        showDefault: true,
                        type: "button",
                        action: "openCustomerDetailsModal()",
                    },
                    customer_id: {
                        hide: true,
                    },
                    created_at: {
                        type: "date",
                    },
                    started_at: {
                        type: "date",
                    },
                    ended_at: {
                        type: "date",
                    },
                },
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [datatable.data],
    )

    const {
        values,
        errors,
        touched,
        resetForm,
        handleBlur,
        handleChange,
        setFieldValue,
        setValues,
        submitForm,
    } = useFormik({
        initialValues: {
            trip_id: parseInt(search.id),
            description: "",
        },
        onSubmit: () => {
            const obj = { ...values }
            Object.keys(obj).forEach((key) => {
                if (obj[key] === null || obj[key] === "") {
                    obj[key] = null
                }
            })
            dispatch(addData({ ...obj, trip_id: parseInt(search.id), module: "end_ride" }))
        },
        validationSchema: EndTripValidation,
    })

    useEffect(() => {
        if (!initialState.hasOwnProperty(module)) {
            dispatch(
                addPage({
                    key: module,
                    data: initialState.vehicles,
                }),
            )
        }

        dispatch(setModule(module))
        dispatch(fetchData({ module }))
    }, [module])

    const data = React.useMemo(() => datatable.data, [datatable.data])
    const { renderInfoCardData } = useInfoCtx()

    return (
        <div style={{ backgroundColor: "white", height: "100%" }}>
            <Offcanvas
                placement="end"
                name="end"
                show={openInstructionPanelStatus}
                onHide={() => setInstructionPanelStatus(false)}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Instructions</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {IOT_INSTRUCTIONS.map((instruction, index) => (
                        <div
                            key={`IOT${index}`}
                            style={{
                                marginBottom: "10px",
                                justifyContent: "space-between",
                            }}
                            className="d-flex align-items-center"
                        >
                            <div>{instruction.name}</div>
                            <div>
                                <button className="btn btn-secondary">Send</button>
                            </div>
                        </div>
                    ))}
                </Offcanvas.Body>
            </Offcanvas>

            {/* {DashboardStatistics.filter((item) => item.module === module).map((card, index) => (
                    <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                        <Card className="overflow-hidden">
                            <Card.Body>
                                <div className="d-flex">
                                    <div className="mt-2">
                                        <h6 className="">{card.heading}</h6>
                                        <h2 className="mb-0 number-font">
                                            {renderInfoCardData(card)}
                                        </h2>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="chart-wrapper mt-1"></div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))} */}
            {data.length > 0 && (
                <Datatable
                    filteredResult={filteredResult}
                    refreshAction={() => {
                        dispatch(fetchData({ module }))
                    }}
                    datatableTitle="Trips"
                    sortable_key="id"
                    isMultiSelect={false}
                    rowSelected={(selection) => {
                        // console.log(selection);
                    }}
                    actions={[{ name: "Delete", type: "end_ride", buttonType: "icon" }]}
                    // modalHeading="Device History"
                    modalStatus={status}
                    modalContent={ModalContentMemonized}
                    handleModalStatus={setModalStatus}
                    columns={columns}
                    data={data}
                    onActionClick={(
                        event,
                        {
                            type,
                            data: {
                                values: { id },
                            },
                        },
                    ) => {
                        if (type == "end_ride") {
                            window.history.replaceState("", "", `?id=${id}`)
                            handleFormModalStatus(true)
                        }
                    }}
                />
            )}
            <Modal
                modalHeading="Update Reason"
                fullWidth
                maxWidth="sm"
                status={formModalStatus}
                onClose={() => {
                    resetForm()
                    handleFormModalStatus(false)
                }}
                onSave={submitForm}
            >
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            label="Reason"
                            name="description"
                            inputProps={{
                                style: {
                                    fontSize: 14,
                                    height: 100,
                                    width: 272,
                                },
                            }}
                            error={!!(errors.description && touched.description)}
                            helperText={
                                errors.description && touched.description
                                    ? errors.description
                                    : false
                            }
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            placeholder="Enter Reason"
                        />
                    </Grid>
                </Grid>
            </Modal>
            {/* <!-- End Row --> */}
        </div>
    )
}

export default Trips
