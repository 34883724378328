import React, { useEffect, useRef, useState } from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import { Button } from "react-bootstrap"
import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import _ from "lodash"

function MapFilter({ title, id, key, children }) {
    const [show, setShow] = useState(false)
    const ref = useRef(null)

    const handleClick = (event) => {
        setShow(!show)
    }

    useEffect(() => {
        document.addEventListener("click", (e) => {
            if (document.getElementById(id) && document.getElementById(`tooltip-${id}`)) {
                if (document.getElementById(id).contains(e.target)) {
                    setShow(true)
                } else if (document.getElementById(`tooltip-${id}`).contains(e.target)) {
                    setShow(true)
                } else setShow(false)
            }
        })
    }, [show])

    const placement = "bottom"

    return (
        <>
            <div id={id}>
                <OverlayTrigger
                    show={show}
                    trigger="click"
                    key={key}
                    ref={ref}
                    placement={placement}
                    overlay={
                        <Popover style={{ minWidth: "150px" }} ref={ref} id={`tooltip-${id}`}>
                            <Popover.Header>
                                <div style={{ textAlign: "right" }}>
                                    <IconButton size="small" onClick={() => setShow(!show)}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </Popover.Header>
                            <Popover.Body>{children}</Popover.Body>
                        </Popover>
                    }
                >
                    <Button
                        onClick={() => {
                            setShow(!show)
                        }}
                        variant="secondary"
                    >
                        {_.capitalize(title)}
                    </Button>
                </OverlayTrigger>
            </div>
        </>
    )
}

export default MapFilter
