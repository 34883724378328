import GeneralRulesOnGeofencing from "./GeneralRulesOnGeofencing"
import RulesOnGeofencing from "./RulesOnGeofencing"

function Routing() {
    const app = sessionStorage.getItem("app")

    const renderComponent = () => {
        switch (app) {
            case "bombay_softwares":
                return <RulesOnGeofencing />
            default:
                return <RulesOnGeofencing />
        }
    }

    return renderComponent()
}

export default Routing
