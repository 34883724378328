const MobyData = require("./WebsiteData/moby")
const KeegoData = require("./WebsiteData/keego")
const GramobilityData = require("./WebsiteData/gramobility")
const CitkarData = require("./WebsiteData/citkar")
const DiageoData = require("./WebsiteData/diageo")
const ABCSolarData = require("./WebsiteData/abc_solar")
const SuperfyData = require("./WebsiteData/superfy")
const EfleetyData = require("./WebsiteData/efleety")
const BSData = require("./WebsiteData/bombay_softwares")
const Biketek = require("./WebsiteData/biketek")

let exports = MobyData

switch (sessionStorage.getItem("app")) {
    case "master":
        exports = EfleetyData
        break
    case "keego":
        exports = KeegoData
        break
    case "gramobility":
        exports = GramobilityData
        break
    case "citkar":
        exports = CitkarData
        break
    case "diageo":
        exports = DiageoData
        break
    case "abc_solar":
        exports = ABCSolarData
        break
    case "superfy":
        exports = SuperfyData
        break
    case "bombay_softwares":
        exports = BSData
        break
    case "biketek":
        exports = Biketek
        break
    default:
        exports = MobyData
}

module.exports = exports
