import React from "react"
import MapDatatableDataLayer from "./DataLayers/MapDatatableDataLayer"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import { Card, Col, Row } from "react-bootstrap"
import { ModalContentMemonized } from "../WorkOrder/WorkOrder"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"
import TemplateDatatable, { computeColumns } from "../../Data/TablesData/TemplateDatatable"
import TemplateAssetLocationMap from "../Maps/TemplateAssetLocationMap"
import { renderInfoCardDataFunction } from "../../Data/WebsiteData/keego"

function MapDatatableExport({ data: { meta, data, filteredData }, page_info, statistics }) {
    const { map_data_filteration_key } = page_info
    const filteredResult = filteredData
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = React.useMemo(
        () =>
            computeColumns({
                data,
                meta,
                action: ({ row, meta }) => {
                    setRowData(row)
                    setColumnMetaData(meta)
                    setModalStatus(true)
                },
            }),
        [data],
    )

    return (
        <div>
            <PageHeader titles="Vehicles" active="Vehicles" items={["Tables"]} />
            <Row>
                {statistics.map((card, index) => (
                    <Col lg={6} key={index} md={6} sm={12} xxl={3}>
                        <Card className="overflow-hidden">
                            <Card.Body>
                                <div className="d-flex">
                                    <div className="mt-2">
                                        <h6 className="">{card.heading}</h6>
                                        <h2 className="mb-0 number-font">
                                            {renderInfoCardDataFunction(card)}
                                        </h2>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="chart-wrapper mt-1"></div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <div>
                {map_data_filteration_key && (
                    <TemplateAssetLocationMap
                        ids={data.map((d) => d[map_data_filteration_key])}
                        type={map_data_filteration_key}
                        filteredResult={filteredResult}
                    />
                )}
            </div>
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <TemplateDatatable
                                isMultiSelect={false}
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            {/* <!-- End Row --> */}
        </div>
    )
}

MapDatatableExport.defaultProps = {
    data: {
        meta: {},
        data: [],
        filteredData: [],
    },
    statistics: [],
    page_info: { map_data_filteration_key: "" },
    page_name: "",
}

const MapDatatable = MapDatatableDataLayer(MapDatatableExport)

export default MapDatatable
