import React from "react"
import { useSelector } from "react-redux"
import { averageContainerData } from "../../Data/WebsiteData/Statistics/interpreter.ts"
import { selectFilteredData } from "../../redux/templateData/selectors.js"

export function formattingValue(value) {
    // Check if the value is a float
    if (typeof value === "number" && value % 1 !== 0) {
        // If float, format with two decimal places and commas
        return value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } else {
        // If integer, format with commas
        return value.toLocaleString("en-US")
    }
}

function totalData(filteredData, parameter, substance = "") {
    if (substance) {
        const containeFluidsArray = substance
            ? filteredData.filter((obj) => obj.substance === substance)
            : filteredData
        const result = containeFluidsArray.reduce((acc, obj) => acc + obj[parameter], 0)
        return result
    } else {
        const result = filteredData.reduce((acc, obj) => acc + obj[parameter], 0)
        return result
    }
}

function totalDifferentType(filteredData) {
    const result = filteredData.filter((obj) => obj.vehicle_type).map((obj) => obj.vehicle_type)
    return new Set(result).size
}

function DashboardTilesOnMap({ module }) {
    const filteredData = useSelector(selectFilteredData)

    let dashboardValues = []
    if (module === "containers") {
        const offlineContainers = filteredData.filter((obj) => !obj.is_active)
        const fullContainers = filteredData.filter((obj) => obj.percent_full >= 75)
        const overflowContainers = filteredData.filter((obj) => obj.percent_full >= 95)
        dashboardValues = [
            { key: "Total Containers", value: filteredData.length },
            { key: "Offline Containers", value: offlineContainers.length },
            { key: "Avg Percent Fill", value: averageContainerData(filteredData, "fill_level") },
            { key: "Full Containers", value: fullContainers.length },
            { key: "Overflow Containers", value: overflowContainers.length },
            { key: "Avg Raw Distance", value: averageContainerData(filteredData, "raw_distance") },
            { key: "Avg Temperature", value: averageContainerData(filteredData, "temperature") },
            {
                key: "Avg Battery Percent",
                value: averageContainerData(filteredData, "battery_level"),
            },
        ]
    } else if (module === "vehicles") {
        dashboardValues = [
            { key: "Total Vehicles", value: filteredData.length },
            {
                key: "Total Active Time",
                value: averageContainerData(filteredData, "vehicle_active_time"),
            },
            {
                key: "Total Down Time",
                value: averageContainerData(filteredData, "vehicle_down_time"),
            },
            {
                key: "Total Trips",
                value: totalData(filteredData, "number_of_rides"),
            },
            {
                key: "Trips Today",
                value: 0,
            },
            {
                key: "Trips Last Week",
                value: 0,
            },
            {
                key: "Trips Last Month",
                value: 0,
            },
            {
                key: "Total Fare Today",
                value: 0,
            },
            {
                key: "Total Fare Last Week",
                value: 0,
            },
            {
                key: "Total Fare Last Month",
                value: 0,
            },
            {
                key: "Active Trips",
                value: 0,
            },
            {
                key: "Total Types",
                value: totalDifferentType(filteredData),
            },
        ]
    }

    if (sessionStorage.getItem("app_key") === "wav_smart") {
        const fluidTypes = ["GasOil", "Water", "LPG", "Kerosene", "RedDiesel"]
        fluidTypes.forEach((substance) => {
            dashboardValues.push(
                {
                    key: `${substance} Estimated Days`,
                    value: totalData(filteredData, "estimated_runout_days", substance),
                },
                {
                    key: `${substance} Amount to Fill`,
                    value: totalData(filteredData, "amount_to_fill_litres", substance),
                },
                {
                    key: `${substance} Remaining Fuel Litres`,
                    value: totalData(filteredData, "remaining_fuel_litres", substance),
                },
            )
        })
    }

    return (
        <>
            {dashboardValues.map((obj, index) => (
                <div
                    key={index}
                    className="mt-2"
                    style={{
                        borderRadius: "10px",
                        width: "190px",
                        backgroundColor: "white",
                        marginRight: "20px",
                        maxHeight: "100px",
                        padding: "15px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                    }}
                >
                    <h6 className="">{obj.key}</h6>
                    <h2 className="mb-0 number-font">
                        {obj.value.toLocaleString().length > 9
                            ? `${obj.value.toLocaleString().slice(0, 9)}...`
                            : obj.value.toLocaleString()}
                    </h2>
                </div>
            ))}
        </>
    )
}

export default DashboardTilesOnMap
