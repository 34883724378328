import { createReducer } from "@reduxjs/toolkit"
import { initialState } from "./initalState"
import { setIOTAssetData } from "./actions"

const IOTReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setIOTAssetData, (state, action) => {
            state.assetData = action.payload
        })
        .addDefaultCase((state, action) => state)
})

export default IOTReducer
