import { Card, Col, Row } from "react-bootstrap"
import PageHeader from "../../Layouts/PageHeader/PageHeader"
import Datatable, { computeColumns } from "../../Data/TablesData/Datatable"
import { useCallback, useMemo, useState } from "react"
import { WorkOrderDatatable } from "../../Data/DummyData"
import {
    BatteryDetails,
    CustomerDetails,
    DeviceDetails,
    ModalContent,
    ModalContentAsset,
    OpenRiderDetails,
    ServiceDetails,
    ServicePersonDetails,
    TripDetails,
} from "../../Data/TablesData/Modals/ModalContent"
import { useDatatableCtx } from "../../components/Datatable/DatatableContext"

export const ModalContentMemonized = (row, columnMeta) => {
    // console.log(row, columnMeta);

    switch (columnMeta.action) {
        case "openTripModal()":
            return <TripDetails data={row} meta={columnMeta} />
        case "openCustomerDetailsModal()":
            return <CustomerDetails data={row} meta={columnMeta} />
        case "getDeviceDetails()":
            return <DeviceDetails data={row} meta={columnMeta} />
        case "openServiceDetails()":
            return <ServiceDetails data={row} meta={columnMeta} />
        case "openServicePersonDetails('person')":
            return <ServicePersonDetails data={row} meta={columnMeta} />
        case "openRiderDetails()":
            return <OpenRiderDetails data={row} meta={columnMeta} />
        case "openModal()":
            return <ModalContent data={row} />
        case "getBatteryDetails()":
            return <BatteryDetails data={row} meta={columnMeta} />
        case "getRidersDetails()":
            return <ModalContentAsset data={row} meta={columnMeta} />
        default:
            return `Content Not Defined for: ${columnMeta.action}`
    }
}

function WorkOrder() {
    const { status, setModalStatus, setRowData, setColumnMetaData } = useDatatableCtx()

    const columns = useMemo(() =>
        computeColumns({
            data: WorkOrderDatatable.data,
            meta: WorkOrderDatatable.meta,
            action: ({ row, meta }) => {
                setRowData(row)
                setColumnMetaData(meta)
                setModalStatus(true)
            },
        }),
    )

    const data = useMemo(() => WorkOrderDatatable.data, [])

    return (
        <div>
            <PageHeader titles="Work Order" active="Work Order" items={["Tables"]} />
            <Row>
                <Col lg={12} md={12} sm={12} xl={12}>
                    <Row>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Total Work Order</h6>
                                            <h2 className="mb-0 number-font">
                                                {WorkOrderDatatable.data.length}
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <div className="card overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">In Progress</h6>
                                            <h2 className="mb-0 number-font">
                                                {
                                                    WorkOrderDatatable.data.filter(
                                                        (workOrder) =>
                                                            workOrder.status == "In Progress",
                                                    ).length
                                                }
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <Card className="overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Under Approval</h6>
                                            <h2 className="mb-0 number-font">
                                                {
                                                    WorkOrderDatatable.data.filter(
                                                        (workOrder) =>
                                                            workOrder.status == "Approval Pending",
                                                    ).length
                                                }
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} sm={12} xxl={3}>
                            <div className="card overflow-hidden">
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="mt-2">
                                            <h6 className="">Completed</h6>
                                            <h2 className="mb-0 number-font">
                                                {
                                                    WorkOrderDatatable.data.filter(
                                                        (workOrder) =>
                                                            workOrder.status == "Completed",
                                                    ).length
                                                }
                                            </h2>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="chart-wrapper mt-1"></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="row">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Datatable
                                modalStatus={status}
                                modalContent={ModalContentMemonized}
                                handleModalStatus={setModalStatus}
                                columns={columns}
                                data={data}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </div>
    )
}

export default WorkOrder
