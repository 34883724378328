import React from "react"
import SuperfyDashboard from "./SuperfyDashboard"
import Layout from "../../Layouts/Layout"
import KeegoDashboard from "./KeegoDashboard"
import WavSmartDashboard from "./WavSmartDashboard"

function Dashboard() {
    const app = sessionStorage.getItem("app_key")

    const renderComponent = () => {
        switch (app) {
            case "superfy":
                return <SuperfyDashboard />
            default:
                return <WavSmartDashboard />
            // default:
            //   return <KeegoDashboard />;
        }
    }

    return renderComponent()
}

export default Dashboard
